import { fontFamily, newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled, { createGlobalStyle } from "styled-components/macro"
import { modalOpenAttr } from "../../../utils/misc-utils"
import { breakpoints, fontWeight, layout, palette, pxToRem, rootFontSize, zIndexes } from "../../../utils/style-utils"

export const BgFixed = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none;
  z-index: -1;
  transform: translateZ(0);
`

const px12 = pxToRem(12)
const px14 = pxToRem(14)

export const ManagerModeTabContainer = styled.div`
  position: fixed;
  bottom: ${pxToRem(120)};
  right: 0;
  padding: 0 ${pxToRem(8)};
  max-width: ${pxToRem(260)};
  padding-bottom: ${px12};
  background-color: ${palette.logoOrange};
  color: #fff;
  flex-flow: row nowrap;
  z-index: ${zIndexes.ui5};
  & > * {
    justify-content: space-between !important;
  }
  & button {
    padding: ${pxToRem(16)};
    color: #fff;
    font-weight: ${fontWeight.semiBold};
  }
  & > a {
    font-size: ${px14};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-items: flex-start;
    padding: 0.5em 1.4em;
    color: white;
    & svg {
      height: 1em;
      width: 1em;
    }
  }
  & p {
    font-size: ${pxToRem(13)};
  }
  &.on-leaderboard--true > a {
    display: none;
  }
  &.on-leaderboard--false p {
    display: none;
  }
  [sh-modal-open] & {
    display: none;
  }
  @media (max-width: ${pxToRem(layout.fullscreenHideNavW)}) {
    bottom: ${pxToRem(120)};
    left: 0;
    right: unset;
  }
  @media (min-width: ${pxToRem(layout.useMobileNavW)}) {
    bottom: unset;
    top: ${pxToRem(46)};
    position: absolute;
  }
`

export const ManagerModeToastContainer = styled.div`
  position: absolute;
  bottom: ${pxToRem(-10)};
  left: 50%;
  display: flex;
  width: ${pxToRem(351)};
  background-color: ${newPalette.gray20};
  color: ${palette.white};
  height: ${pxToRem(52)};
  justify-content: space-between;
  align-items: center;
  z-index: ${zIndexes.ui3};
  padding: ${pxToRem(8)};
  font-size: ${px14};
  line-height: ${pxToRem(20)};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: ${pxToRem(4)};
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-weight: ${fontWeight.regular};
    letter-spacing: -0.1px;
  }

  button {
    background-color: ${palette.lightBlue3};
    width: ${pxToRem(83)};
    height: ${pxToRem(36)};
    font-weight: 500;
    letter-spacing: 0.25px;
    border-radius: ${pxToRem(2)};
  }
`

export const RootWrapper = styled.div`
  &.use-body-scroll--false {
    width: 100%;
    display: flex;
    flex-flow: column;
    flex: 1;
  }
`
export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  position: relative;
  flex-flow: column;
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    & {
      order: 2;
    }
  }
  &.has-sidebar--true {
    flex-flow: row nowrap;
  }
`

// html.is-ios-browser--true body[${modalOpenAttr}] #sh-scroll-container {
//   overflow: visible;
// }
export const WC2 = styled.div`
  flex: 1 1 100%;
  height: 100%;
  position: relative;
`
export const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`
export const PageContent = styled.div`
  will-change: scroll-position;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  & > * {
    width: 100%;
  }
`

export const GlobalCssVariableStyles = createGlobalStyle`
:root {
  --grid-columns: 16;
  --grid-gap: ${pxToRem(32)};
  --grid-margin: ${pxToRem(48)}; /* NOTE LL: This was breaking when set to 80 for pickem games w/ 3 cols  */
  --page-max-w: ${pxToRem(layout.pageContentMaxW)};
  @media (max-width: ${breakpoints.tv - 1}px) {
    --grid-columns: 12;
    --grid-gap: ${pxToRem(32)};
    --grid-margin: ${pxToRem(48)};
  }
  @media (max-width: ${breakpoints.xl - 1}px) {
    --grid-gap: ${pxToRem(24)};
  }
  @media (max-width: ${breakpoints.lg - 1}px) {
    --grid-margin: ${pxToRem(24)};
  }
  @media (max-width: ${breakpoints.md - 1}px) {
    --grid-gap: ${pxToRem(16)};
    --grid-margin: ${pxToRem(16)};
  }
  @media (max-width: ${breakpoints.sm - 1}px) {
    --grid-columns: 4;
    --grid-margin: 0;
  }
}
`

export const GlobalStyle = createGlobalStyle<{ fixedRootFontSize?: boolean }>`
html {
  scroll-behavior: smooth;
}
html, body {
  margin: 0;
  padding: 0;
  height: auto;
  font-size: ${rootFontSize}px;
  @media (max-width: ${layout.shrinkFontMaxW}px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 0.85}px`};
    }
  }
  @media (max-height: 600px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 0.85}px`};
    }
  }
  @media (orientation: landscape) and (max-height: 600px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 0.8}px`};
    }
  }
  @media (min-width: 2200px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 1.05}px`};
    }
  }
  @media (min-width: 2700px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 1.15}px`};
    }
  }
  @media (min-width: 3000px) {
    & {
      font-size: ${({ fixedRootFontSize }) => `${fixedRootFontSize ? rootFontSize : rootFontSize * 1.25}px`};
    }
  }
}
body {
  font-family: ${fontFamily.base};
  margin: 0 auto;
  line-height: 1;
  min-width: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  position: relative;
  box-sizing: border-box;
  background-color: ${palette.gray95};
}
body[${modalOpenAttr}] & {
  pointer-events: none;
  overflow: hidden;
}
#root *, #root *:after, #root *:before {
  box-sizing: border-box;
}
#root *:after, #root *:before {
  pointer-events: none;
}
* {
  user-select: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;

  font: inherit;
}
p, h1, h2, h3, h4, h5, pre, code, .u-selectable, dt, dl, dd {
  user-select: text;
  white-space: normal;
}
input,
textarea,
select,
label {
  user-select: auto;
}
input::selection,
textarea::selection {
  background-color: #A5CDFE;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 100px white inset !important;
}
label {
  cursor: pointer;
}
button, a {
  outline: none;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  font: inherit;
  outline: none;
  color: inherit;
}
button > *, a > * {
  pointer-events: none;
}
*[disabled] {
  pointer-events: none !important;
  cursor: default !important;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
  margin: 0;
  padding: 0;
}
/* https://thatemil.com/blog/2015/01/03/reset-your-fieldset/ */
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
legend {
  display: table;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  & + * {
    clear: both;
  }
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
svg {
  width: 100%;
  height: 100%;
}
[class*="icon-moon"] {
  font-family: "icon-moon";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  vertical-align: middle;
}
[class*="icon-moon"]:before {
  text-decoration: none;
}
img {
  height: auto;
  max-width: 100%;
  opacity: 1;
  transition: opacity .5s ease-in .5s;
  vertical-align: top;
}

.modal--matchup-analysis {
  overflow: auto !important;
  padding-top: 0 !important;
  will-change: scroll-position;
  -webkit-overflow-scrolling: touch !important;
  & > * {
    overflow: visible !important;
    box-shadow: none !important;
    -webkit-overflow-scrolling: auto !important;
  }
}

.skybox-loaded #fantNavContainer {
  transition: none;
}



/* Disable Body Scroll Styles start */
html.use-body-scroll--false {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}
html.use-body-scroll--false body {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  display: flex;
  flex-flow: column;
}
/* This is for ios devices since they suck with body overflow */
html.use-body-scroll--false.is-ios-browser--true {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
html.use-body-scroll--false #root {
  display: flex;
  flex-flow: column;
  flex: 1;
  width: 100%;
  overflow: hidden !important;
}
/* fix ad at bottom */
@media (max-width: ${pxToRem(breakpoints.handheld)}) {
  html.use-body-scroll--true .ad__banner--bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: ${zIndexes.ui3}
  }
  html.use-body-scroll--true #faux-cbs-site-nav {
    position: sticky;
    top: 0;
  }
}

@media (min-width: ${pxToRem(breakpoints.tablet - 200)}) {
  html.use-body-scroll--false.is-ios-browser--true body.disable-ios-tablet-overflowscroll #sh-scroll-container {
    -webkit-overflow-scrolling: auto;
  }
}
html.use-body-scroll--false.disable-momentum-scrolling--true #sh-scroll-container {
  -webkit-overflow-scrolling: auto;
}

#picks-count-container {
  position: sticky;
  bottom: -0.5rem;
  display: flex;
  padding: 0 var(--grid-gap);
  justify-content: center;
}
`
