import React from "react"
import { PoolPageNotificationBanner } from "../App/Layout/components/PoolPageArchivedBanner"
import { IPoolData } from "../../routes.d"
import { useRouteMatch } from "react-router"
import constants from "../../common/constants"

export type NotificationBannerProps = {
  poolData: IPoolData
}

export default function NotificationBanner(props: NotificationBannerProps) {
  const { detailedPeriod, gameInstanceForArea, areaHasMultipleEntriesPerUser } = props.poolData
  const lobbyMatch = useRouteMatch({
    path: constants.BRACKET_LOBBY_ROUTE,
    exact: true,
  })

  if (areaHasMultipleEntriesPerUser && !!lobbyMatch) {
    if (!gameInstanceForArea?.currentPeriod?.globalNotificationHtml) {
      return null
    }

    return (
      <PoolPageNotificationBanner
        html={gameInstanceForArea.currentPeriod.globalNotificationHtml}
        backgroundColor={gameInstanceForArea.currentPeriod.globalNotificationBackgroundColor}
        color={gameInstanceForArea.currentPeriod.globalNotificationColor}
      />
    )
  }

  const nodeWithNotificationHtml =
    (detailedPeriod?.notificationBannerHtml && detailedPeriod) ||
    (gameInstanceForArea?.currentPeriod?.notificationBannerHtml && gameInstanceForArea?.currentPeriod) ||
    null

  if (!nodeWithNotificationHtml?.notificationBannerHtml) {
    return null
  }

  return (
    <PoolPageNotificationBanner
      backgroundColor={nodeWithNotificationHtml.notificationBannerBackgroundColor}
      color={nodeWithNotificationHtml.notificationBannerColor}
      html={nodeWithNotificationHtml.notificationBannerHtml}
    />
  )
}
