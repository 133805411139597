import * as React from "react"
import styled from "styled-components/macro"
import { CentralGameInstancesQuery_allSegments_season } from "../../__generated__/CentralGameInstancesQuery"
import CustomCopy from "./CustomCopy"

export interface IProps {
  season?: CentralGameInstancesQuery_allSegments_season | null
  id?: string
  identifier?: string
  className?: string
}

const SeasonDiscraimerSC = styled.small`
  display: block;
`

const SeasonDisclaimer = React.memo((props: IProps) => {
  const { season, className, identifier, ...rest } = props
  const classNames = ["season-disclaimer", "u-selectable"]
  if (className) {
    classNames.push(className)
  }
  return (
    <SeasonDiscraimerSC className={classNames.join(" ")} {...rest}>
      {identifier}
      <CustomCopy season={season} code="disclaimerHtml" gameInstance={season?.gameInstance as any} />
    </SeasonDiscraimerSC>
  )
})

export default SeasonDisclaimer
