import React from "react"
import styled from "styled-components/macro"
import { extractValidationError, humanizeErrorMsg } from "../../common/apiErrors"
import { FlexCol, FlexRow } from "../components/FlexComponents"
import Spinner from "../components/Spinner"
import { fontWeight, palette, pxToRem } from "../utils/style-utils"

export const errorClassname = "has-errors"
export const errorClassFor = ({ submitCount, touched, errors }, key: string) =>
  (submitCount > 0 && touched[key] && errors[key] && errorClassname) || undefined
export { extractValidationError }

// const spinnerSize = 28;
export const FormButtonSpinnerBaseStyled = styled.button`
  position: relative;

  & span {
    transition: all 0.2s ease-in-out;
  }
  &.is-form-spinner > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1em 0 0 -1em;
    width: 2em;
    height: 2em;
    transition: all 0.2s ease-in-out;
    color: ${palette.white};
  }
  &.is-submitting--true {
    pointer-events: none;
    color: rgba(255, 255, 255, 0) !important;
    & span {
      color: inherit !important;
    }
  }
  &.is-form-spinner.is-submitting--false > .spinner {
    opacity: 0;
    transform: scale(0);
  }
  &.is-valid--false {
    color: ${palette.gray2};
    background-color: ${palette.gray3};
    border-color: ${palette.gray3};
  }
`
export const FormActionsContainer = styled(FlexRow)`
  padding: ${pxToRem(60)} 0;
`

export type IFormStatus = "enabled" | "success" | "submitting" | "inert" | ""
interface IFormButtonSpinnerBase {
  isValid: boolean
  isSubmitting: boolean
  status?: IFormStatus
  success?: any
  inert?: any
  disabled?: boolean
  className?: string
  children?: any | null
  as?: React.ReactNode | string
  type?: "button" | "submit" | "reset"
  onClick?: (e?: any | undefined) => void | undefined | Promise<void>
}

export const FormButtonSpinnerBase = ({
  isValid,
  isSubmitting,
  status,
  success = "Success!",
  inert = "Submit",
  disabled = false,
  className = "",
  children = null,
  ...rest
}: IFormButtonSpinnerBase) => (
  <FormButtonSpinnerBaseStyled
    className={`is-valid--${isValid} is-submitting--${isSubmitting} is-form-spinner ${className}`}
    disabled={disabled || isSubmitting || !isValid}
    {...(rest as unknown)}
  >
    <span>{status === FormSuccessStatus ? success : inert}</span>
    <Spinner />
    {children}
  </FormButtonSpinnerBaseStyled>
)

export const StyledFormError = styled.p<{ centered: boolean }>`
  width: 100%;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  color: ${palette.formError};
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(12)};
  padding: ${pxToRem(6)};
`
export const StyledFormErrors = styled(FlexCol)`
  max-width: ${pxToRem(450)};
  width: 100%;
  margin: 0 auto;
`

export const FormError = ({ attribute, messages, centered = true }) => {
  const errorMessage = typeof messages === "string" ? [messages] : messages
  if (Array.isArray(errorMessage) && errorMessage.length) {
    return <StyledFormError centered={centered}>{humanizeErrorMsg(attribute, errorMessage)}</StyledFormError>
  } else {
    window.SH_ERROR_NOTIFY(`Invalid error attribute: '${attribute}': '${JSON.stringify(messages)}'`)
    return null
  }
}
// ex:
// <FormErrors {...formikBag} />
export const FormErrors = ({ errors, submitCount, centered = true }) => {
  const errorKeys = Object.keys(errors || {}).filter((k) => !!errors[k])
  if (errorKeys.length && submitCount > 0) {
    // console.log('FormErrors')
    // console.dir(errors)
    return (
      <StyledFormErrors>
        {errorKeys.map((errorKey) => (
          <FormError key={errorKey} attribute={errorKey} messages={errors[errorKey]} centered={centered} />
        ))}
      </StyledFormErrors>
    )
  }
  return null // <StyledFormErrors />;
}

export const ApiFormErrors = ({ error }) => {
  if (!error) {
    return null
  }
  const { errors } = extractValidationError(error)
  return <FormErrors errors={errors} submitCount={1} />
}

export const FormSuccessStatus = "success"
export const FormSubmittingStatus = "submitting"
export const FormEnabledStatus = "enabled"

export const FormLabelBase = styled.div`
  font-weight: ${fontWeight.semiBold};
  letter-spacing: ${pxToRem(1.6)};
  font-size: ${pxToRem(16)};
  color: #ffffff;
  margin: ${pxToRem(16)} 0;
  text-align: left;
  width: 100%;
  text-transform: uppercase;
`
