import * as React from "react"
import { RouteComponentProps } from "react-router"
import constants from "../../common/constants"
import Analytics from "../utils/analytics"
import { getParam, setParam } from "../utils/url-utils"

const tournamentIdParam = `tournamentId`
const orderParam = `order`
export const paginationParam = `after`
const defaultPagination = constants.DEFAULT_PAGINATION

interface IHavePageSize {
  pageSize?: number
}

export interface IControlsQueryParams {
  first: number
  after?: string
}

export interface IControls extends IControlsQueryParams {
  goToPage: (cursor: string | null) => void
  updateTournamentId: (tournamentId: number | null) => void
  updateOrder: (order: number | null) => void
  currentPage: string | number | null
}

export function extractControlsQueryParams({ first, after }: IControlsQueryParams): IControlsQueryParams {
  return { first, after }
}

function useControls(props: RouteComponentProps<any> & IHavePageSize): IControls {
  const { location, history } = props
  const pageSize = props.pageSize ?? defaultPagination
  const currentPage = getParam(paginationParam, location.search)
  const currentPagination: IControlsQueryParams = currentPage ? { first: pageSize, after: currentPage.toString() } : { first: pageSize }
  const updateOrder = React.useCallback((order: number | null) => {
    Analytics.trackInteraction(`view other period`)
    return applyParams(orderParam, order)
  }, [])

  const updateTournamentId = React.useCallback((tournamentId: number | null) => {
    Analytics.trackInteraction(`view other tournament`)
    return applyParams(tournamentIdParam, tournamentId)
  }, [])

  const applyParams = React.useCallback(
    (key: string, value: string | number | null) => {
      const existingSearchParts = (location.search || "").replace("?", "")
      return history.push({ search: `${setParam(existingSearchParts, key, value)}` })
    },
    [location.search, history],
  )

  const goToPage = React.useCallback(
    (cursor: string | null) => {
      Analytics.trackInteraction(`paginate`)
      return applyParams(paginationParam, cursor)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )
  return {
    ...currentPagination,
    goToPage,
    updateTournamentId,
    updateOrder,
    currentPage,
  }
}

export default useControls
