import React, { useCallback, useState } from "react"
import { Mutation } from "@apollo/client/react/components"
import fetch from "cross-fetch"
import styled from "styled-components/macro"
import { pxToRem } from "../../../utils/style-utils"
import { UPLOAD_POOL_AVATAR_SIGNED_URL_MUTATION } from "../../queries"

import Modal, { ModalWrapper } from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { fontFamily, fontWeight, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Cropper from "react-easy-crop"
import { Slider } from "material-ui-slider"
import { MutationFunction } from "@apollo/client"
import { emptyObject } from "../../../../common/misc-utils"
import { Area, MediaSize } from "react-easy-crop/types"
import {
  UploadPoolAvatarSignedUrlMutation,
  UploadPoolAvatarSignedUrlMutationVariables,
} from "../../../../__generated__/UploadPoolAvatarSignedUrlMutation"
import { extractValidationError } from "../../../components/Form"
import { IValidationErrorBody } from "../../../../common/apiErrors"

const PoolAvatarModalWrap = styled.div`
  padding: 1.5rem 2.5rem;
  width: ${pxToRem(360)};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & > .title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: left;
    color: ${palette.gray20};
    text-transform: uppercase;
  }
  & > .recomendation {
    font-family: ${fontFamily.base};
    font-size: 14px;
    font-style: normal;
    font-weight: ${fontWeight.regular};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    text-align: left;
    margin-top: 1.5rem;
    color: ${palette.gray20};
  }
  & > .crop-container {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    width: ${pxToRem(280)};
    height: ${pxToRem(280)};
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;

    & .crop-over-container {
      border-radius: 50%;
    }
  }
  & > .crop-slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    width: 12.5rem;
    margin: 0.5rem auto 0;
  }
  & > .controls {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    box-sizing: border-box;

    & > :first-child {
      margin-right: 1rem;
    }
  }
`

function getCanvas(imageUrl: string, cropInfo: CropInfo, callback: (canvas: HTMLCanvasElement) => void) {
  const canvas = document.createElement("canvas") as HTMLCanvasElement
  if (canvas) {
    const context = canvas.getContext("2d")
    const width = 280
    const height = 280
    canvas.width = width
    canvas.height = height
    if (context) {
      context.imageSmoothingEnabled = true
      const { croppedAreaPixels } = cropInfo
      const { x, y, width: imgWidth, height: imgHeight } = croppedAreaPixels
      const img = new Image()
      img.crossOrigin = "anonymous"
      img.onload = function () {
        context.drawImage(img, x, y, imgWidth, imgHeight, 0, 0, width, height)
        callback(canvas)
      }
      img.src = imageUrl
      context.fill()
    }
  }
}

interface PoolAvatarModalProps {
  isOpen: boolean
  onClose: () => void
  mutation: MutationFunction<UploadPoolAvatarSignedUrlMutation, UploadPoolAvatarSignedUrlMutationVariables>
  poolId?: string
  gameInstanceUid?: string
  file: string
  onComplete: (url: string) => void
  onError: (erros: IValidationErrorBody) => void
}

const CROP_IMAGE_HEIGHT = 280
const CROP_IMAGE_WIDTH = 280

const cropClasses = {
  cropAreaClassName: "crop-over-container",
}
interface CropInfo {
  croppedArea: Area
  croppedAreaPixels: Area
}
const PoolAvatarModal = ({ onClose, poolId, gameInstanceUid, mutation, file, onComplete, onError }: PoolAvatarModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [loading, setLoading] = useState(false)
  const [cropInfo, setCropInfo] = useState<CropInfo | null>(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropInfo({
      croppedArea,
      croppedAreaPixels,
    })
  }, [])

  const onZoomComplete = useCallback((zoom: number) => {
    setZoom(zoom / 100)
  }, [])

  const onSave = useCallback(() => {
    const done = () => {
      setLoading(false)
    }
    const getImg = async (canvas: HTMLCanvasElement) => {
      const variables: UploadPoolAvatarSignedUrlMutationVariables = {
        poolId,
        fileName: `avatar.png`,
        fileType: "image/png",
        gameInstanceUid,
      }

      try {
        const signedUrlResp = await mutation({ variables })
        const { signedRequest, url } = signedUrlResp?.data?.uploadPoolAvatarSignedUrl || emptyObject
        if (signedRequest) {
          const headers = {
            "Content-Type": variables.fileType,
          }
          const base64String = canvas.toDataURL("image/png")
          const byteString = window.atob(base64String.split(",")[1])
          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length)
          // create a view into the buffer
          const ia = new Uint8Array(ab)
          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
          }
          const blobBody = new Blob([ia], { type: "image/png" })
          // const blobBody2 = await fetch(base64String).then(res => res.blob())
          const s3Response = await fetch(signedRequest, {
            method: "PUT",
            body: blobBody,
            headers,
          })
          const textResponse = await s3Response.text()
          if (!s3Response.ok) {
            throw new Error(textResponse)
          }
        }
        onComplete(url)
      } catch (err) {
        const apiErrors = extractValidationError(err)
        onError(apiErrors.errors)
        onComplete("")
      } finally {
        done()
      }
    }

    setLoading(true)
    getCanvas(file, cropInfo || emptyObject, getImg)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropInfo, file])

  const onMediaLoaded = (size: MediaSize) => {
    const { width, height, naturalHeight, naturalWidth } = size
    let newZoom = 1
    if (naturalHeight > naturalWidth) {
      if (CROP_IMAGE_WIDTH > width) {
        newZoom = CROP_IMAGE_WIDTH / width
      } else {
        newZoom = width / CROP_IMAGE_WIDTH
      }
    } else if (naturalHeight < naturalWidth) {
      if (CROP_IMAGE_HEIGHT > height) {
        newZoom = CROP_IMAGE_HEIGHT / height
      } else {
        newZoom = height / CROP_IMAGE_HEIGHT
      }
    } else {
      if (CROP_IMAGE_HEIGHT > height) {
        newZoom = CROP_IMAGE_HEIGHT / height
      } else {
        newZoom = height / CROP_IMAGE_HEIGHT
      }
    }
    setZoom(newZoom)
  }

  return (
    <PoolAvatarModalWrap>
      <span className="title">Pool Avatar</span>
      <span className="recomendation">Recommended dimensions: 200x200 pixels</span>
      <div className="crop-container">
        <Cropper
          image={file}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          onMediaLoaded={onMediaLoaded}
          showGrid={false}
          classes={cropClasses}
          cropSize={{
            width: CROP_IMAGE_WIDTH,
            height: CROP_IMAGE_HEIGHT,
          }}
        />
      </div>
      <div className="crop-slider">
        <Slider
          value={zoom * 100}
          min={100}
          max={300}
          aria-labelledby="Zoom"
          onChangeComplete={onZoomComplete}
          color={palette.lightBlue3}
          className="slider-class"
        />
      </div>
      <div className="controls">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button withLoading loading={loading} onClick={onSave}>
          {loading ? "Uploading" : "Apply"}
        </Button>
      </div>
    </PoolAvatarModalWrap>
  )
}

const PoolAvatarForm = (props: Omit<PoolAvatarModalProps, "mutation">) => {
  const { isOpen, onClose } = props
  return (
    <Mutation<UploadPoolAvatarSignedUrlMutation, UploadPoolAvatarSignedUrlMutationVariables> mutation={UPLOAD_POOL_AVATAR_SIGNED_URL_MUTATION}>
      {(mutation) => (
        <Modal modalType="modal" padded={false} isOpen={isOpen} afterClose={onClose} variant={"white"} onBackgroundClick={() => undefined}>
          <ModalWrapper modalType="modal" variant="white" padded={false}>
            <PoolAvatarModal {...props} mutation={mutation} />
          </ModalWrapper>
        </Modal>
      )}
    </Mutation>
  )
}
export default PoolAvatarForm
