import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { FlexCols, FlexRow } from "../../../components/FlexComponents"
import { palette, pxToRem, zIndexes } from "../../../utils/style-utils"

export const StyledNavSideItemContainer = styled.div`
  position: relative;
  overflow: visible;
  &:after {
    border-bottom: ${pxToRem(9)} solid ${palette.uiNavLinkHighlighted};
    content: "";
    height: 0;
    margin-left: ${pxToRem(-6)};
    position: absolute;
    width: 0;
    z-index: 999;
    border-left: ${pxToRem(6)} solid transparent;
    border-right: ${pxToRem(6)} solid transparent;
    border-left: ${pxToRem(10)} solid transparent;
    border-right: ${pxToRem(10)} solid transparent;
    bottom: ${pxToRem(1)};
    display: none;
    left: 50%;
    margin-left: ${pxToRem(-11)};
    margin-top: ${pxToRem(2)};
  }
  @media (hover: hover) {
    &:not(:hover):after {
      display: none;
    }
    &:not(:hover) > div:last-child {
      display: none;
    }
  }
  @media not all and (hover: hover) {
    display: none;
  }
`
export const StyledNavSideItem = styled(FlexRow)`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${fontFamily.condensed};
  font-size: ${pxToRem(14)};
  letter-spacing: ${pxToRem(2)};
  text-transform: uppercase;
  margin-bottom: ${pxToRem(5)};
  padding: ${pxToRem(19)} ${pxToRem(7)} ${pxToRem(16)};
  cursor: pointer;
  @media (min-width: ${pxToRem(1190)}) {
    padding: ${pxToRem(17)} ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(17)};
    letter-spacing: ${pxToRem(2)};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${palette.uiNavLinkHover};
    }
  }
  &:active {
    color: ${palette.uiNavLinkHover};
  }
`

export const Dropdown = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(-5)} rgba(0, 0, 0, 0.2);
  color: #232323;
  font-size: ${pxToRem(16)};
  top: 100%;
  z-index: ${zIndexes.siteNavDropdown};
`
export const NavSideDropdown = styled(Dropdown)`
  border-top: ${pxToRem(4)} solid ${palette.uiNavLinkHighlighted};
  min-width: ${pxToRem(310)};
  right: 0;
  margin-top: ${pxToRem(-4)};
`
export const DropdownInner = styled(FlexCols)`
  padding: ${pxToRem(25)} ${pxToRem(25)} 0;
  align-items: flex-start;
`
export const StyledNavDropdownItem = styled(FlexRow)`
  color: ${palette.gray5};
  font-size: ${pxToRem(14)};
  font-weight: 600;
  letter-spacing: ${pxToRem(1.5)};
  text-transform: uppercase;
  margin-bottom: ${pxToRem(15)};
  @media (min-width: ${pxToRem(1190)}) {
    font-size: ${pxToRem(16)};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${palette.blueActive};
    }
  }
  &:active {
    color: ${palette.blueActive};
  }
`

export const DropdownCta = styled(FlexRow)`
  text-transform: uppercase;
  transition: background-color 0.2s ease;
  letter-spacing: ${pxToRem(1)};
  background-color: #15b842;
  color: #fff;
  font-size: ${pxToRem(12)};
  font-weight: 600;
  line-height: 1;
  padding: ${pxToRem(12)} ${pxToRem(50)};
  margin: ${pxToRem(5)};
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: #0d7329;
    }
  }
  &:active {
    background-color: #0d7329;
  }
`
export const DropdownRow = styled(FlexRow)`
  margin-bottom: ${pxToRem(15)};
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: ${pxToRem(1)} solid #e6e7e9;
`

export const StyledGameItem = styled(FlexCols)`
  margin-bottom: ${pxToRem(20)};
  max-width: 48%;
  color: #94a1a9;
  & > * {
    width: 100%;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: #147cd1;
      & > div {
        background-color: #0d7329;
      }
    }
  }
  &:active {
    color: #147cd1;
    & > div {
      background-color: #0d7329;
    }
  }
`
export const StyledGameItemImg = styled.img`
  width: ${pxToRem(180)};
`
export const StyledGameItemBtn = styled(FlexRow)`
  background-color: #15b842;
  color: #fff;
  font-family: ${fontFamily.base};
  font-weight: 600;
  letter-spacing: ${pxToRem(1)};
  padding: ${pxToRem(12)};
  text-transform: uppercase;
  transition: background-color 0.2s ease;
`
export const StyledGameItemText = styled.div`
  margin-top: ${pxToRem(6)};
  font-size: ${pxToRem(12)};
  font-weight: 400;
  letter-spacing: ${pxToRem(1)};
  line-height: 1.2em;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
  background-color: transparent !important;
  transition: color 0.2s ease;
`
export const NavDropdownItemAlt = styled(FlexRow)`
  width: 100%;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  letter-spacing: ${pxToRem(1)};
  line-height: 1;
  text-transform: uppercase;
  color: #15b842;
  margin-bottom: ${pxToRem(20)};
  text-align: center;
  @media (hover: hover) {
    &:hover:not(:active) {
      color: #0d7329;
    }
  }
  &:active {
    color: #0d7329;
  }
`
