import React, { FC } from "react"
import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem, buildClassNames as cn } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import EntryLogo, { EntryType as EntryLogoEntryType } from "../../components/EntryLogo"

export interface EntryPoolType {
  id: string
  name: string
}

export interface EntryType extends EntryLogoEntryType {
  pool: EntryPoolType
}

const px14 = pxToRem(14)

const DualLineEntryRowRoot = styled.div`
  display: flex;
  align-items: center;
  --main-line-color: ${theme.colors.overLight.white20};
  --secondary-line-color: ${theme.colors.overLight.white40};
  --logo-background: ${theme.colors.overLight.white95};
  --logo-opacity: 1;

  &.row__line--disabled {
    --main-line-color: ${theme.colors.overLight.white75};
    --secondary-line-color: ${theme.colors.overLight.white75};
    --logo-background: ${theme.colors.overLight.white97};
    --logo-opacity: 0.4;
  }

  .row__line-entry-logo {
    &:empty {
      background: var(--logo-background);
    }

    img {
      transition: none;
      opacity: var(--logo-opacity);
    }
  }

  .row__line-wrapper {
    flex: 1;
    margin-left: 0.5rem;
    min-width: 0;
  }

  .row__line--main {
    font-weight: ${theme.fonts.weights.semireg};
    color: var(--main-line-color);
    font-size: ${px14};
    line-height: ${px14};
    letter-spacing: -0.1px;
    margin-bottom: 0.25rem;
  }

  .row__line--secondary {
    font-weight: ${theme.fonts.weights.regular};
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: var(--secondary-line-color);
  }

  .row__line--overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export interface IDualLineEntryRowProps {
  entry: EntryType
  className?: string
  disabled?: boolean
  showPoolInfo?: boolean
}

const DualLineEntryRow: FC<IDualLineEntryRowProps> = ({ entry, disabled, showPoolInfo = true, className = "" }) => {
  return (
    <DualLineEntryRowRoot className={cn({ [className]: !!className, "row__line--disabled": disabled })}>
      <EntryLogo showChampionLogo entry={entry} size="rg" className="row__line-entry-logo" />
      <div className="row__line-wrapper">
        <div className="row__line--main row__line--overflow">{entry.name}</div>
        {showPoolInfo && <div className="row__line--secondary row__line--overflow">{entry.pool.name}</div>}
      </div>
    </DualLineEntryRowRoot>
  )
}

export default DualLineEntryRow
