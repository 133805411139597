import React, { Fragment, ReactNode, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  EntryRowWrap,
  EntryRank,
  EntryStat,
  EntryChampion,
  EntryName,
  EntryStatus,
  Information,
  InformationRow,
  Title,
  PoolName,
  ChevronContainer,
  TooltipBody,
  DeleteButtonWrapper,
} from "./EntryRow.styles"
import { withoutDomain } from "../../../../utils/url-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { Checkbox } from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import EntryLogo from "../../components/EntryLogo"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import ChevRightSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevRight"
import { useDeviceType } from "../../../../Base/DeviceType"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import { buildClassNames as cx, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import ChampionLogo from "../MultipleEntriesArea/PoolHome/ChampionLogo"
import PickUtilsContext from "../../../../Contexts/PickUtilsContext"
import { getChampionStatus, getChampionLogo } from "../MultipleEntriesArea/PoolHome/BracketPoolHome"

export interface EntryRowProps {
  entry: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node
  isLocked: boolean
  canDeleteEntry?: boolean
  handleDeleteClick: any
  variant?: "compact" | "standard"
  onChange?: (entry: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node) => void
  isChecked?: boolean
  className?: string
}

const EntryRow = ({ entry, canDeleteEntry, handleDeleteClick, variant = "standard", onChange, isChecked, className, isLocked }: EntryRowProps) => {
  const { name: entryName, totalPicksCount, poolRank, fantasyPoints, maxPicksCount, pool, maxPoints } = entry || emptyObject
  const { name: poolName } = pool || emptyObject
  const poolData = useContext(PoolDataContext)
  const pickUtils = useContext(PickUtilsContext)
  const [hoverBgColor, setHoverBgColor] = useState(palette.lightestBlue)
  const { isInComingSoon } = poolData || emptyObject

  const deviceType = useDeviceType()
  const history = useHistory()
  if (!entry) {
    return null
  }

  const handleOnChange = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (typeof onChange === "function") {
      onChange(entry)
    }
  }

  const goToEntry = () => {
    if (isInComingSoon) {
      return
    }
    let url = ""
    if (deviceType !== "handheld") {
      url = withoutDomain(entry.url)
    } else {
      url = withoutDomain(`/games/roadblock?showCongrats=false&from=lobby&gameType=${poolData?.gameInstanceUid}`)
    }
    history.push(url)
  }

  const renderBody = (body: ReactNode) => <TooltipBody>{body}</TooltipBody>

  const arePicksComplete = totalPicksCount === maxPicksCount
  const needToMakePicks = entry.needsToMakePicks
  const isComplete = !needToMakePicks

  const message = isComplete ? "Complete" : !arePicksComplete ? `${totalPicksCount}/${maxPicksCount} Picks Made` : "Tiebreaker Missing"

  if (variant === "compact") {
    return (
      <EntryRowWrap
        className={cx({
          [`variant--${variant}`]: true,
          [className || ""]: !!className,
        })}
        onClick={handleOnChange}
      >
        <Checkbox label="EntryName" hideLabel name={entryName} onChange={handleOnChange} isChecked={isChecked} />
        <EntryChampion className={`variant--${variant}`}>
          <EntryLogo className="entry-logo" entry={entry} showChampionLogo size="sm" />
        </EntryChampion>
        <EntryName className={`variant--${variant}`}>{entryName}</EntryName>
        <PoolName>{poolName}</PoolName>
      </EntryRowWrap>
    )
  }

  const pickStatus = isLocked ? getChampionStatus(pickUtils, entry.championTeam) : "UNKNOWN"
  const logoUrl = getChampionLogo(pickUtils, entry.championTeam)
  return (
    <EntryRowWrap
      hoverBgColor={hoverBgColor}
      className={cx({
        [`variant--${variant}`]: true,
        [className || ""]: !!className,
        "is-coming-soon": isInComingSoon,
      })}
      onClick={goToEntry}
    >
      <EntryRank>{poolRank || "—"}</EntryRank>
      <EntryChampion className={`variant--${variant}`}>
        <ChampionLogo
          championTeam={entry.championTeam as any}
          showTiebreaker={false}
          tiebreakerAnswers={[]}
          pickStatus={pickStatus}
          logoUrl={logoUrl}
        />
      </EntryChampion>
      <Information>
        <EntryName className={`variant--${variant}`}>
          <span className="entry-name">{entryName}</span>
        </EntryName>
        <InformationRow>
          <Title>{isLocked ? "Rank" : "Status"}</Title>
          {!isLocked && (
            <EntryStatus
              className={cx({
                "is-complete": isComplete,
                "is-locked": !isLocked,
                "is-coming-soon": isInComingSoon,
              })}
            >
              {isInComingSoon ? "Coming Soon" : message}
            </EntryStatus>
          )}
          <EntryRank
            className={cx({
              "mobile-only": true,
              "display-flex": isLocked,
            })}
          >
            {isLocked ? poolRank || "—" : "—"}
          </EntryRank>
        </InformationRow>
      </Information>
      {!isLocked && canDeleteEntry && (
        <DeleteButtonWrapper>
          <ClassicTooltip content="Delete bracket" renderBody={renderBody} anchor={ANCHORS.LEFT_BOTTOM}>
            <Button
              className="delete-button"
              variant="secondary"
              withoutText
              onClick={(event) => handleDeleteClick(entry, event)}
              onMouseEnter={() => setHoverBgColor(palette.white)}
              onMouseLeave={() => setHoverBgColor(palette.lightestBlue)}
            >
              <ListItemIcon>
                <DeleteSvg />
              </ListItemIcon>
            </Button>
          </ClassicTooltip>
        </DeleteButtonWrapper>
      )}
      {isLocked && !canDeleteEntry && (
        <Fragment>
          <EntryStat>{fantasyPoints || "—"}</EntryStat>
          <EntryStat>{maxPoints || "—"}</EntryStat>
        </Fragment>
      )}
      {!isInComingSoon && (
        <ChevronContainer>
          <ChevRightSvg />
        </ChevronContainer>
      )}
    </EntryRowWrap>
  )
}

export default EntryRow
