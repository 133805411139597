import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { toggleableToggledClassname } from "../../../components/Dropdown"
import { FlexCols, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

interface IPropsStyledNavListItem {
  highlighted: boolean
}
export const StyledNavListItemContainer = styled.div<IPropsStyledNavListItem>`
  &:after {
    border-bottom: ${pxToRem(9)} solid ${palette.uiNavLinkHighlighted};
    content: "";
    height: 0;
    margin-left: ${pxToRem(-6)};
    position: absolute;
    width: 0;
    z-index: ${zIndexes.siteNavDropdown};
    border-left: ${pxToRem(6)} solid transparent;
    border-right: ${pxToRem(6)} solid transparent;
    border-left: ${pxToRem(10)} solid transparent;
    border-right: ${pxToRem(10)} solid transparent;
    bottom: ${pxToRem(1)};
    display: ${({ highlighted }) => (highlighted ? "block" : "none")};
    left: 50%;
    margin-left: ${pxToRem(-11)};
    margin-top: ${pxToRem(2)};
    display: none;
  }
  &.${toggleableToggledClassname}:after {
    display: block;
  }
`
export const StyledNavListItem = styled(FlexRow)<IPropsStyledNavListItem>`
  color: ${({ highlighted, theme }) => (highlighted || theme.isInDebugMode ? palette.white : palette.uiNavLinkHighlighted)};
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
  font-family: ${fontFamily.condensed};
  letter-spacing: ${pxToRem(2)};
  text-transform: uppercase;
  padding: ${pxToRem(19)} ${pxToRem(9)} ${pxToRem(16)};
  position: relative;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  @media (min-width: ${pxToRem(850)}) {
    font-size: ${pxToRem(15)};
    letter-spacing: ${pxToRem(1.5)};
  }
  @media (min-width: ${pxToRem(1190)}) {
    padding: ${pxToRem(18)} ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(16)};
    letter-spacing: ${pxToRem(2)};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${({ theme }) => (theme.isInDebugMode ? palette.black : palette.uiNavLinkHover)};
    }
  }
  &:active {
    color: ${({ theme }) => (theme.isInDebugMode ? palette.black : palette.uiNavLinkHover)};
  }
  &:before {
    border-top: ${pxToRem(3)} solid ${palette.uiNavLinkHighlighted};
    content: "\00a0";
    display: ${({ highlighted }) => (highlighted ? "block" : "none")};
    left: ${pxToRem(5)};
    margin-top: ${pxToRem(-4)};
    position: absolute;
    right: ${pxToRem(5)};
    top: 100%;
  }
`

export const DropdownDD = styled(FlexRow)`
  align-items: flex-start;
  position: absolute;
  top: 0;
  background-color: ${palette.white};
  box-shadow: 0px ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(-5)} rgba(0, 0, 0, 0.2);
  color: ${palette.neutral60};
  font-size: ${pxToRem(16)};
  padding: ${pxToRem(25)} ${pxToRem(25)} 0;
  position: absolute;
  top: 100%;
  z-index: ${zIndexes.siteNavDropdown};
  pointer-events: auto;
  text-transform: uppercase;
`

export const NavListDropdown = styled(DropdownDD)`
  border-top: ${pxToRem(4)} solid ${palette.uiNavLinkHighlighted};
  left: ${pxToRem(-15)};
  margin-top: ${pxToRem(-4)};
  min-width: ${pxToRem(180)};
  padding: ${pxToRem(16)} ${pxToRem(24)};
`
export const DropdownSection = styled(FlexCols)`
  border-right: ${pxToRem(1)} solid ${palette.gray6};
  margin-right: ${pxToRem(30)};
  padding-right: ${pxToRem(30)};
  align-items: flex-start;
  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
`
export const DropdownSectionItem = styled(FlexRow)`
  background-position: 0% 0%;
  background-size: auto 200%;
  color: ${palette.gray5};
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.semiBold};
  font-family: ${fontFamily.condensed};
  letter-spacing: ${pxToRem(1.5)};
  line-height: 2.2;
  text-transform: uppercase;
  transition: none;
  @media (min-width: ${pxToRem(1190)}) {
    font-size: ${pxToRem(16)};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      box-shadow: inset 0 -6px 0 0 ${palette.white}, inset 0 -7px 0 0 ${palette.blueActive};
      background-position: 0% 100%;
      color: ${palette.blueActive};
    }
  }
  &:active {
    box-shadow: inset 0 -6px 0 0 ${palette.white}, inset 0 -7px 0 0 ${palette.blueActive};
    background-position: 0% 100%;
    color: ${palette.blueActive};
  }
`

export const Container = styled(FlexRow)`
  flex: 1 1 100%;
  justify-content: flex-start;
  overflow: visible;
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    display: none;
  }
`
