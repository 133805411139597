import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { noop } from "./GenericEntryModal"
import WarningAlert from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WarningAlert"
import { palette, gridBreakPoints, pxToRem, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { queries } = gridBreakPoints

const DEFAULT_CTA_LABEL = "Ok"
const DEFAULT_CANCEL_LABEL = "Cancel"
const px14 = pxToRem(14)
const px18 = pxToRem(18)

const ModalContentWrapper = styled.div`
  width: ${pxToRem(320)};
  width: min(20rem, 100vw - 2rem);

  .modal__content {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .modal__icon {
      width: ${pxToRem(40)};
      margin-bottom: 1rem;
      margin-right: 0;
      display: flex;
      color: ${theme.colors.overLight.red};
    }

    .modal__description {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal__title {
      letter-spacing: -0.1px;
      margin-bottom: 0.25rem;
      font-size: ${px18};
      line-height: ${px18};
      font-weight: ${theme.fonts.weights.bold};
      color: ${theme.colors.overLight.white20};
    }

    .modal__message {
      font-size: ${px14};
      line-height: 1.25rem;
      font-weight: ${theme.fonts.weights.regular};
      letter-spacing: -0.1px;
      color: ${theme.colors.overLight.white40};
      text-align: center;
    }

    .modal__error {
      margin-top: 2rem;
      font-weight: ${theme.fonts.weights.regular};
      font-size: 0.75rem;
      line-height: 0.75rem;
      letter-spacing: -0.1px;
      color: ${theme.colors.overLight.red};
    }

    strong {
      font-weight: ${theme.fonts.weights.bold};
    }
  }

  .modal__actions {
    align-items: center;
    border-top: 1px solid ${palette.gray90};
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem;

    & > * {
      flex: 1;
    }
  }

  .modal__actions.with-actions {
    & > button:last-child::before {
      position: absolute;
      content: "";
      width: 1px;
      top: 0.5rem;
      left: 0;
      height: 1.25rem;
      background-color: ${palette.gray90};
    }
  }

  ${queries.sm} {
    .modal__content {
      align-items: flex-start;
      flex-direction: row;

      .modal__icon {
        margin-right: 1rem;
        margin-bottom: 0;
      }

      .modal__description {
        align-items: flex-start;
      }

      .modal__message {
        text-align: left;
      }
    }
    .modal__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: none;
      padding: 0 0.5rem 0.5rem;

      & > * {
        margin-left: 0.5rem;
        flex: inherit;

        &:first-of-type {
          margin-left: 0;
        }
      }
      & > button:last-child::before {
        display: none;
      }
    }
  }
`

type ActionType = () => void

export interface IGenericConfirmationModalProps {
  isOpen: boolean
  title?: string
  message?: ReactNode
  ctaButtonLabel?: string
  cancelButtonLabel?: string
  loading?: boolean
  onClose?: ActionType
  onConfirm?: ActionType
  additionalContent?: ReactNode
  modalIcon?: React.ReactNode
  showCancel?: boolean

  selectorLabel?: string
}

const GenericConfirmationModal: FC<IGenericConfirmationModalProps> = ({
  isOpen,
  title,
  message,
  ctaButtonLabel = DEFAULT_CTA_LABEL,
  cancelButtonLabel = DEFAULT_CANCEL_LABEL,
  loading,
  onClose,
  onConfirm,
  additionalContent,
  modalIcon = <WarningAlert />,
  showCancel = true,
  selectorLabel,
}) => {
  const onBeforeClose = () =>
    new Promise((res) => {
      onClose?.()
      res(undefined)
    })

  return (
    <Modal isOpen={isOpen} padded={false} onBackgroundClick={noop} onEscapeKeydown={noop} beforeClose={onBeforeClose} afterClose={onClose}>
      <ModalContentWrapper data-cy={selectorLabel}>
        <div className="modal__content">
          <div className="modal__icon">{modalIcon}</div>
          <div className="modal__description">
            <div className="modal__title">{title}</div>
            <div className="modal__message">{message}</div>
            {additionalContent}
          </div>
        </div>
        <div
          className={cx({
            modal__actions: true,
            "with-actions": showCancel,
          })}
        >
          {showCancel && (
            <Button variant="clear" onClick={onClose} disabled={!!loading}>
              {cancelButtonLabel}
            </Button>
          )}

          <Button variant="clear" onClick={onConfirm} withLoading loading={!!loading}>
            {ctaButtonLabel}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  )
}
export { noop }
export default GenericConfirmationModal
