import palette from "./palette"

export interface ITheme {
  primary: string
  secondary: string
  btnBgColor: string
  btnHighlightBgColor: string
  btnBorderColor: string
  btnHighlightBorderColor: string
  disabled: string
  altActive: string
  altHover: string
  // NOTE qac: primary == btn/action color
  isDarkPrimary: boolean
  // NOTE qac: secondary == background color
  isDarkSecondary: boolean
  isCircularLogo: boolean
  uiNav: string
  // new 2020:
  primaryShadow: string
  primaryHighlight: string
  darkBgColor: string
  lightBgColor: string
  isDarkBtnBg: boolean
  isDarkSplashBg: boolean
}

const themes = Object.freeze({
  default: {
    primary: palette.blue2,
    secondary: palette.gray4,
    btnBgColor: palette.blue2,
    btnHighlightBgColor: palette.blueActive,
    btnBorderColor: palette.blue2,
    btnHighlightBorderColor: palette.blueActive,
    disabled: `${palette.blue2}61`,
    altActive: `${palette.blue2}21`,
    altHover: `${palette.blue2}12`,
    isDarkPrimary: false,
    isDarkSecondary: false,
    isDarkBtnBg: false,
    isDarkSplashBg: false,
    isCircularLogo: true,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "college-basketball-ncaa-tournament-bracket": {
    primary: palette.ncaabTournamentPrimary,
    secondary: palette.ncaabTournamentSecondary,
    btnBgColor: palette.ncaabTournamentSecondary,
    btnHighlightBgColor: `${palette.ncaabTournamentSecondary}61`,
    btnBorderColor: palette.ncaabTournamentSecondary,
    btnHighlightBorderColor: `${palette.ncaabTournamentSecondary}61`,
    disabled: `${palette.ncaabConfPrimary}61`,
    altActive: `${palette.ncaabConfPrimary}61`,
    altHover: `${palette.ncaabConfPrimary}4f`,
    isDarkPrimary: true,
    isDarkSecondary: false,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: true,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "college-basketball-conference-tournament-bracket": {
    primary: palette.ncaabConfPrimary,
    secondary: palette.ncaabConfSecondary,
    btnBgColor: palette.ncaabConfPrimary,
    btnHighlightBgColor: "#8e7535",
    btnBorderColor: palette.ncaabConfPrimary,
    btnHighlightBorderColor: "#8e7535",
    disabled: `${palette.ncaabConfPrimary}61`,
    altActive: `${palette.ncaabConfPrimary}61`,
    altHover: `${palette.ncaabConfPrimary}4f`,
    isDarkPrimary: true,
    isDarkSecondary: false,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "football-parlay": {
    primary: palette.nflParlayPrimary,
    secondary: palette.nflParlaySecondary,
    btnBgColor: palette.nflParlayPrimary,
    btnHighlightBgColor: palette.nflParlayTersery,
    btnBorderColor: palette.nflParlayPrimary,
    btnHighlightBorderColor: palette.nflParlayTersery,
    disabled: `${palette.nflParlayPrimary}61`,
    altActive: `${palette.nflParlayPrimary}61`,
    altHover: `${palette.nflParlayPrimary}4f`,
    isDarkPrimary: false,
    isDarkSecondary: true,
    isDarkBtnBg: false,
    isDarkSplashBg: false,
    isCircularLogo: true,
    uiNav: palette.uiNavBlue1,
    primaryShadow: `${palette.nflParlayPrimary}4f`,
    primaryHighlight: `${palette.nflParlayPrimary}ee`,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "golf-props": {
    primary: "#51CE51",
    secondary: "#00B2E7",
    btnBgColor: "#51CE51",
    btnHighlightBgColor: "#62F462",
    btnBorderColor: "#51CE51",
    btnHighlightBorderColor: "#62F462",
    disabled: `#51CE5161`,
    altActive: `#51CE5161`,
    altHover: `#51CE514f`,
    isDarkPrimary: false,
    isDarkSecondary: true,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: "#6B9E39",
    primaryHighlight: "#62F462",
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "football-pickem": {
    primary: "#4170a3",
    secondary: "#d0021b",
    btnBgColor: "#4170a3",
    btnHighlightBgColor: "#4170a3ee",
    btnBorderColor: "#4170a3",
    btnHighlightBorderColor: "#4170a3ee",
    disabled: `#4170a361`,
    altActive: `#4170a361`,
    altHover: `#4170a34f`,
    isDarkPrimary: false,
    isDarkSecondary: true,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: "#4170a34f",
    primaryHighlight: "#4170a3ee",
    darkBgColor: "#4170a3",
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "college-football-pickem": {
    primary: palette.ncaafPrimary,
    secondary: palette.ncaafSecondary,
    btnBgColor: palette.ncaafSecondary,
    btnHighlightBgColor: palette.ncaafPrimary,
    btnBorderColor: palette.ncaafSecondary,
    btnHighlightBorderColor: palette.ncaafPrimary,
    disabled: `${palette.ncaafSecondary}61`,
    altActive: `${palette.ncaafSecondary}61`,
    altHover: `${palette.ncaafSecondary}4f`,
    isDarkPrimary: false,
    isDarkSecondary: false,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "college-football-pickem-post": {
    primary: palette.ncaafPostPrimary,
    secondary: palette.ncaafPostSecondary,
    btnBgColor: palette.ncaafPostSecondary,
    btnHighlightBgColor: palette.yellow,
    btnBorderColor: palette.ncaafPostSecondary,
    btnHighlightBorderColor: palette.yellow,
    disabled: `${palette.ncaafPostSecondary}61`,
    altActive: `${palette.ncaafPostSecondary}61`,
    altHover: `${palette.ncaafPostSecondary}4f`,
    isDarkPrimary: false,
    isDarkSecondary: true,
    isDarkBtnBg: false,
    isDarkSplashBg: true,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "football-pickem-post": {
    primary: palette.nflPostPrimary,
    secondary: palette.nflPostSecondary,
    btnBgColor: palette.nflPostPrimary,
    btnHighlightBgColor: "#8e7535",
    btnBorderColor: palette.nflPostPrimary,
    btnHighlightBorderColor: "#8e7535",
    disabled: `${palette.nflPostPrimary}61`,
    altActive: `${palette.nflPostPrimary}61`,
    altHover: `${palette.nflPostPrimary}4f`,
    isDarkPrimary: true,
    isDarkSecondary: false,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: false,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
})

export default themes
