import React, { FC, useState, useCallback, useContext } from "react"
import { MutationTuple, useMutation } from "@apollo/client"
import { Formik, Field, FieldProps, FormikHelpers, ErrorMessage, FormikValues } from "formik"
import Cropper from "react-easy-crop"
import { Slider } from "material-ui-slider"
import styled from "styled-components"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import { Area, MediaSize } from "react-easy-crop/types"
import { GET_ENTRY_AVATAR_SIGNED_URL_MUTATION } from "../../../queries"
import {
  GetEntryAvatarSignedUrlMutation,
  GetEntryAvatarSignedUrlMutationVariables,
} from "../../../../../__generated__/GetEntryAvatarSignedUrlMutation"
import PoolDataContext from "../../../../Contexts/PoolDataContext"

const ModalWrapper = styled.div`
  width: calc(${pxToRem(360)} - ${pxToRem(80)});
  display: flex;
  flex-direction: column;
  color: ${bracketTheme.colors.overLight.white20};
  margin-bottom: -${pxToRem(18)}; /*this is to compensate for the 18px added by the slider shadow*/

  .modal__text {
    font-weight: ${bracketTheme.fonts.weights.regular};
    font-size: ${pxToRem(14)};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
  }

  .crop-container {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    width: ${pxToRem(280)};
    height: ${pxToRem(280)};
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;

    & .crop-over-container {
      border-radius: 50%;
    }
  }

  .crop-slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    width: 12.5rem;
    margin: 0.5rem auto 0;
  }
`

const FormErrorMessage = styled.div`
  font-weight: ${bracketTheme.fonts.weights.regular};
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.1px;
  color: ${bracketTheme.colors.overLight.red};
`

const DEFAULT_TITLE = "Edit Avatar"
const MODAL_HELPER_TEXT = "Recommended dimensions: 200x200 pixels"
const AVATAR_AREA_WIDTH = 280
const AVATAR_AREA_HEIGHT = 280

const cropSize = {
  height: 280,
  width: 280,
}
const cropClasses = {
  cropAreaClassName: "crop-over-container",
}
export interface CropInfo {
  croppedArea: Area
  croppedAreaPixels: Area
}

interface IAvatarFieldProps extends FieldProps<string, IEditEntryAvatarFormValues> {
  file: string
  onCropInfoChange?: (cropInfo: CropInfo) => void
}

export const AvatarField: FC<IAvatarFieldProps> = ({ file, onCropInfoChange }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onCropInfoChange?.({ croppedArea, croppedAreaPixels })
    },
    [onCropInfoChange],
  )

  const onZoomComplete = useCallback((zoom: number) => {
    setZoom(zoom / 100)
  }, [])

  const onMediaLoaded = (size: MediaSize) => {
    const { width, height, naturalHeight, naturalWidth } = size
    let newZoom = 1
    if (naturalHeight > naturalWidth) {
      if (AVATAR_AREA_WIDTH > width) {
        newZoom = AVATAR_AREA_WIDTH / width
      } else {
        newZoom = width / AVATAR_AREA_WIDTH
      }
    } else if (naturalHeight < naturalWidth) {
      if (AVATAR_AREA_HEIGHT > height) {
        newZoom = AVATAR_AREA_HEIGHT / height
      } else {
        newZoom = height / AVATAR_AREA_HEIGHT
      }
    } else {
      if (AVATAR_AREA_HEIGHT > height) {
        newZoom = AVATAR_AREA_HEIGHT / height
      } else {
        newZoom = height / AVATAR_AREA_HEIGHT
      }
    }
    setZoom(newZoom)
  }

  return (
    <div>
      <div className="crop-container">
        <Cropper
          image={file}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          onMediaLoaded={onMediaLoaded}
          showGrid={false}
          classes={cropClasses}
          cropSize={cropSize}
        />
      </div>
      <div className="crop-slider">
        <Slider
          value={zoom * 100}
          min={100}
          max={300}
          aria-labelledby="Zoom"
          onChangeComplete={onZoomComplete}
          color={palette.lightBlue3}
          className="slider-class"
        />
      </div>
    </div>
  )
}

type UploadAvatarStep = "idle" | "uploading" | "updating"

export interface IEditEntryAvatarFormValues extends FormikValues {
  entryId: string
  avatarUrl: string
}

interface IEditEntryAvatarModalProps extends IGenericEntryModalProps {
  close: () => void
  file: string
}

interface IUploadOptions {
  entryId: string
  uploadAvatar: MutationTuple<GetEntryAvatarSignedUrlMutation, GetEntryAvatarSignedUrlMutationVariables>["0"]
  fileContent: string
  cropInfo?: CropInfo
  onStart?: () => void
  onUploading?: () => void
  onUploaded?: ({ url }: { url: string }) => Promise<void>
  onError?: (err: Error) => void
  onComplete?: () => void
}

export function upload(options: IUploadOptions) {
  options?.onStart?.()
  const canvas = document.createElement("canvas") as HTMLCanvasElement
  if (!canvas) {
    throw new Error("Could not access canvas element")
  }

  const context = canvas.getContext("2d")
  canvas.width = AVATAR_AREA_WIDTH
  canvas.height = AVATAR_AREA_HEIGHT

  if (!context) {
    throw new Error("Cound not access canvas context")
  }

  context.imageSmoothingEnabled = true
  const { croppedAreaPixels } = options.cropInfo ?? emptyObject
  const { x, y, width: imgWidth, height: imgHeight } = croppedAreaPixels
  const img = new Image()
  img.crossOrigin = "anonymous"
  img.onload = async function () {
    options?.onUploading?.()
    context.drawImage(img, x, y, imgWidth, imgHeight, 0, 0, AVATAR_AREA_WIDTH, AVATAR_AREA_HEIGHT)
    /**
     * First we need to upload the cropped image. Once we have the file's url, the Entry can be updated with the new avatar
     */
    const uploadAvatarVariables: GetEntryAvatarSignedUrlMutationVariables = {
      entryId: options.entryId ?? "",
      fileName: "avatar.png",
      fileType: "image/png",
    }
    try {
      const signedUrlResp = await options.uploadAvatar({ variables: uploadAvatarVariables })
      const { signedRequest, url } = signedUrlResp?.data?.getEntryAvatarSignedUrl ?? emptyObject

      if (!signedRequest) {
        throw new Error("Could not access upload url")
      }
      const headers = {
        "Content-Type": uploadAvatarVariables.fileType,
      }
      const base64String = canvas.toDataURL("image/png")
      const byteString = window.atob(base64String.split(",")[1])
      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      // create a view into the buffer
      const ia = new Uint8Array(ab)
      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blobBody = new Blob([ia], { type: "image/png" })
      const s3Response = await fetch(signedRequest, {
        method: "PUT",
        body: blobBody,
        headers,
      })
      const textResponse = await s3Response.text()
      if (!s3Response.ok) {
        throw new Error(textResponse)
      }
      await options.onUploaded?.({ url })
    } catch (err) {
      options.onError?.(err)
    } finally {
      options.onComplete?.()
    }
  }
  img.src = options.fileContent
  context.fill()
}

interface IEditEntryAvatarFormProps {
  file: string
  setCropInfo: (cropInfo: CropInfo) => void
}

export const EditEntryAvatarForm: FC<IEditEntryAvatarFormProps> = ({ file, setCropInfo }) => {
  return (
    <ModalWrapper>
      <div className="modal__text">{MODAL_HELPER_TEXT}</div>
      <Field name="updatedAvatarUrl" component={AvatarField} file={file} onCropInfoChange={setCropInfo} />
      <ErrorMessage name="updatedAvatarUrl" component={FormErrorMessage} />
    </ModalWrapper>
  )
}

const EditEntryAvatarModal: FC<IEditEntryAvatarModalProps> = ({ title = DEFAULT_TITLE, file, close, ...rest }) => {
  const poolData = useContext(PoolDataContext)
  const { detailedEntry: entry, upsertEntryMutation: mutation } = poolData || emptyObject

  const [uploadAvatar] = useMutation<GetEntryAvatarSignedUrlMutation, GetEntryAvatarSignedUrlMutationVariables>(GET_ENTRY_AVATAR_SIGNED_URL_MUTATION)
  const [cropInfo, setCropInfo] = useState<CropInfo | null>(null)
  const [uploadStep, setUploadStep] = useState<UploadAvatarStep>("idle")
  const inProgress = uploadStep !== "idle"
  const initialValues = { entryId: entry?.id ?? "", avatarUrl: entry?.avatarUrl ?? "" }
  const handleSubmit = useCallback(
    (values: IEditEntryAvatarFormValues, formik: FormikHelpers<IEditEntryAvatarFormValues>) => {
      async function uploadAndUpdate() {
        upload({
          entryId: entry?.id ?? "",
          fileContent: file,
          cropInfo: cropInfo ?? emptyObject,
          uploadAvatar: uploadAvatar,
          onStart: () => formik.setFieldTouched("updatedAvatarUrl", true),
          onUploading: () => setUploadStep("uploading"),
          onUploaded: async ({ url }) => {
            const variables: IEditEntryAvatarFormValues = {
              ...values,
              avatarUrl: url,
            }
            await mutation({ variables })
            close()
          },
          onError: () => {
            formik.setFieldError("updatedAvatarUrl", "Could not save entry avatar")
            formik.setSubmitting(false)
          },
          onComplete: () => setUploadStep("idle"),
        })
      }
      uploadAndUpdate()
    },
    [file, cropInfo, entry, uploadAvatar, mutation, close],
  )

  return (
    <Formik<IEditEntryAvatarFormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => {
        const onCancelClick = () => {
          formik.resetForm()
          close()
        }
        const actions = (
          <>
            <Button variant="secondary" onClick={onCancelClick} type="button" disabled={formik.isSubmitting}>
              Cancel
            </Button>
            <Button variant="primary" type="button" onClick={formik.submitForm} withLoading loading={inProgress}>
              {uploadStep === "uploading" ? "Uploading..." : uploadStep === "updating" ? "Saving..." : "Save"}
            </Button>
          </>
        )

        return (
          <GenericEntryModal title={title} onBackgroundClick={noop} onEscapeKeydown={noop} actions={actions} {...rest}>
            <EditEntryAvatarForm file={file} setCropInfo={setCropInfo} />
          </GenericEntryModal>
        )
      }}
    </Formik>
  )
}

export default EditEntryAvatarModal
