import * as React from "react"
import { Logo } from "../../../components/Logos"
import { IAuthGateViewLogo } from "../AuthGateTypings.d"
import { LogoStyled } from "../styles/SplashViews.styles"

export const AuthGateViewLogo = ({ as, className, ref, ...rest }: IAuthGateViewLogo) => {
  return (
    <LogoStyled as={as} className={className} ref={ref}>
      <Logo {...rest} />
    </LogoStyled>
  )
}
export default AuthGateViewLogo
