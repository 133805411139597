import React, { useState } from "react"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"
import GroupSvg from "../../../../../components/icons/Group"
import { ICreateGroupProps } from "../MyGroups.types"
import Link from "../../../../../components/Link"
import { Disclaimer } from "../../AddBracketComponent"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import CookieUtils from "../../../../../utils/cookie-utils"
import { isCookiePresent, picksPoolsExists } from "../../../../../../common/cookies"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { fontWeight, palette, pxToRem } from "../../../../../../client/utils/style-utils"
import styled from "styled-components/macro"
import { fontFamily, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Analytics from "../../../../../utils/analytics"

const { breakpoints } = gridBreakPoints

export const ConfirmDialogWrap = styled.div`
  box-sizing: border-box;
  background-color: ${palette.white};
  border-radius: ${pxToRem(6)};
  font-size: ${pxToRem(14)};
  opacity: 1;
  width: ${pxToRem(280)};

  & > .data-information {
    text-align: center;
    display: flex;
    flex-flow: column;
    padding: 1rem 0;
    box-sizing: border-box;

    & > .text-container {
      & > .title {
        font-family: ${fontFamily.base};
        font-size: 18px;
        font-style: normal;
        font-weight: ${fontWeight.bold};
        line-height: 1;
        letter-spacing: -0.1px;
        text-align: center;
        color: ${palette.gray20};
        margin-bottom: 1rem;
      }

      & > .sub-title {
        font-family: ${fontFamily.base};
        font-size: 14px;
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1;
        letter-spacing: -0.1px;
        text-align: center;
        line-height: 1.25rem;
        color: ${palette.gray40};
      }
    }
  }

  & > .btn-container {
    border-top: 1px solid ${palette.gray90};
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0.5rem 0 0.5rem;

    & > button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @media (min-width: ${pxToRem(breakpoints.md)}) {
    width: ${pxToRem(320)};

    & > .data-information {
      flex-flow: row;

      & > .text-container {
        display: flex;
        flex-flow: column;
        margin-top: 0;
        text-align: center;
      }
    }

    & > .btn-container {
      justify-content: flex-end;
      border-top: none;
      padding-top: 0.5rem;
    }
  }
`

const CreateGroup = ({ createPoolUrl = "", disclaimerUrl = "", myVisibleEntries = [] }: ICreateGroupProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleCreatePool = (event) => {
    const managerEntries = myVisibleEntries.filter((e: any) => e.roles.includes("MANAGER"))
    if (managerEntries.length && !isCookiePresent(picksPoolsExists)) {
      Analytics.trackAction("create pool", "existing pools modal", "", "impression")
      event.preventDefault()
      setModalOpen(true)
      const date = new Date()
      const sixMonth = new Date(date.setMonth(date.getMonth() + 6))
      CookieUtils.setCookie(picksPoolsExists, "true", sixMonth)
      return false
    }

    return true
  }
  const createNewPoolTrackClick = () => Analytics.trackAction("create pool", "existing pools modal", "create new pool")
  const createPoolModal = (
    <Modal modalType="dialog" isOpen={modalOpen} onBackgroundClick={() => undefined}>
      <ConfirmDialogWrap>
        <div className="data-information">
          <div className="text-container">
            <div className="title" data-cy="existing-pools-hdr">
              Existing Pools Available
            </div>
            <div className="sub-title" data-cy="existing-pools-txt">
              We noticed you have existing pools that you currently manage. By reusing an existing pool you can update pool name, easily alert
              previous members that your pool is back, and keep historical standings intact.
            </div>
          </div>
        </div>
        <div className="btn-container">
          <Button variant="clear" aria-label="Cancel And Go Back To Bracket" onClick={() => setModalOpen(false)} data-cy="existing-pools-cancel-btn">
            Cancel
          </Button>
          <StyledButton
            aria-label="Create New Pool"
            as={Link}
            className="variant--primary cta"
            to={createPoolUrl}
            onClick={createNewPoolTrackClick}
            data-cy="existing-pools-create-new-btn"
          >
            Create New Pool
          </StyledButton>
        </div>
      </ConfirmDialogWrap>
    </Modal>
  )

  return (
    <>
      <div className="information">
        <div className="icon-container">
          <GroupSvg />
        </div>
        <div className="content-wrap">
          <span className="description" data-cy="create_pp_label">
            Create a private pool with friends, family and coworkers.
          </span>
        </div>
      </div>
      <StyledButton
        onClick={(event) => handleCreatePool(event)}
        as={Link}
        className="variant--primary cta"
        to={createPoolUrl}
        data-cy="btn-create-pool"
      >
        Create Pool
      </StyledButton>
      {createPoolModal}
      <Disclaimer variant={"bpm"} url={disclaimerUrl} />
    </>
  )
}

export default CreateGroup
