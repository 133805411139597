import React, { FC, useState, useCallback } from "react"
import styled from "styled-components"
import { buildClassNames as cx, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Back from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Back"
import GenericEntryModal, { IGenericEntryModalProps } from "../GenericEntryModal"
import { PoolDetailsFragment } from "../../../../../../__generated__/PoolDetailsFragment"
import { BackArrow, BonusTitle, PoolRulesModalContent } from "./PoolRulesModal.styles"
import BonusesContent from "./BonusesContent"
import PoolRulesContent, { PoolRulesContentProps } from "./RulesContent"

const DEFAULT_TITLE = "Pool Scoring Rules"
const BONUSES_TITLE = "Bonuses"
const px408 = pxToRem(408)
const px40 = pxToRem(40)

const PoolRulesModalRoot = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.5rem;
    background: linear-gradient(to left, transparent 32px, white 32px, white);
  }

  .modal__scrollable-area {
    padding: 1.5rem;
    box-sizing: border-box;
    max-height: calc(${px408} + 1px);
    overflow: hidden auto;
  }
  .modal__scrollable-area.mode__rules {
    padding: 0 1.5rem 1.5rem;
  }
`

const BonusContentWrapper = styled.div`
  padding: 0 1rem;
`

const BonusHeaderWrapper = styled.div`
  padding: 1.5rem ${px40} 0 ${px40};
`

const RulesContentWrapper = styled.div`
  padding: 0;
`

const RulesHeaderWrapper = styled.div`
  padding: 1.5rem;
`

const CustomPoolRulesModalContent = styled.div`
  padding-bottom: 0;
`

type PoolRulesContentPropsType = Omit<PoolRulesContentProps, "onShowBonuses">

interface IPoolRulesModalProps extends IGenericEntryModalProps {
  pool?: PoolDetailsFragment
  close?: () => void
  poolRulesContentProps?: PoolRulesContentPropsType
  mode?: "full" | "rules"
}
type PoolRulesScreen = "RULES" | "BONUSES"

const PoolRulesModal: FC<IPoolRulesModalProps> = ({ pool, title = DEFAULT_TITLE, close, poolRulesContentProps, mode = "full", ...rest }) => {
  const [screen, setScreen] = useState<PoolRulesScreen>("RULES")

  const showBonuses = useCallback(() => {
    setScreen("BONUSES")
  }, [])
  const showRules = useCallback(() => {
    setScreen("RULES")
  }, [])

  const bonusesTitle = (
    <BonusTitle>
      <BackArrow onClick={showRules}>
        <Back />
      </BackArrow>
      {BONUSES_TITLE}
    </BonusTitle>
  )

  const screenTitle = screen === "RULES" ? title : bonusesTitle
  const noBorder = screen === "BONUSES"
  const renderContentAs = { as: screen === "BONUSES" ? BonusContentWrapper : RulesContentWrapper }
  const renderHeaderAs = { as: screen === "BONUSES" ? BonusHeaderWrapper : RulesHeaderWrapper }

  return (
    <GenericEntryModal
      title={screenTitle}
      onEscapeKeydown={close}
      onBackgroundClick={close}
      closeBtnAction={close}
      afterClose={showRules}
      noBorder={noBorder}
      modalContentWrapperProps={renderContentAs}
      modalHeaderWrapperProps={renderHeaderAs}
      {...rest}
    >
      <PoolRulesModalRoot>
        <div
          className={cx({
            "modal__scrollable-area": true,
            [`mode__${mode}`]: true,
          })}
        >
          <PoolRulesModalContent as={CustomPoolRulesModalContent} className={cx({ "modal--wide": screen === "RULES" })}>
            {screen === "RULES" ? <PoolRulesContent {...poolRulesContentProps} onShowBonuses={showBonuses} pool={pool} dense /> : <BonusesContent />}
          </PoolRulesModalContent>
        </div>
      </PoolRulesModalRoot>
    </GenericEntryModal>
  )
}

export default PoolRulesModal
