import React, { FC } from "react"
import { BonusItemWrapper } from "./PoolRulesModal.styles"

interface IBonusItemProps {
  title: string
}

const BonusItem: FC<IBonusItemProps> = ({ title, children }) => {
  return (
    <BonusItemWrapper>
      <div className="bonusitem__title">{title}</div>
      <div className="bonusitem__description">{children}</div>
    </BonusItemWrapper>
  )
}
export const BonusesContent = () => (
  <>
    <div className="modal__emphasis">Bonuses are rewarded based on the team’s seed, the higher the seed the more points earned.</div>
    <BonusItem title="Points + Seed Bonus">
      If you correctly pick a #3 seed to win in the second round, you get 2 points (second round)* + 3 points (#3 seed) = 5 total points.
    </BonusItem>
    <BonusItem title="Points x Seed Bonus">
      If you correctly pick a #3 seed to win in the second round, you get 2 points (second round)* x 3 points (#3 seed) = 6 total points.
    </BonusItem>
    <div className="modal__note">
      *The point values in the examples shown above are based on the CBS Sports Standard Scoring rules of 2 points per correct pick in the second
      round. Point values can vary depending on customization.
    </div>
  </>
)
export default BonusesContent
