import { breakpoints, fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { FlexRow } from "../../../components/FlexComponents"
import Link from "../../../components/Link"
import { fontWeight, palette, pxToRem, zIndexes } from "../../../utils/style-utils"
import { navBg } from "./SiteNav.styles"

const px4 = pxToRem(4)
const px6 = pxToRem(6)
const px8 = pxToRem(8)
const px10 = pxToRem(10)
const px11 = pxToRem(11)
const px13 = pxToRem(13)
const px14 = pxToRem(14)
const px16 = pxToRem(16)
const px20 = pxToRem(20)
const px21 = pxToRem(21)
const px24 = pxToRem(24)
const px30 = pxToRem(30)
const px32 = pxToRem(32)
const px34 = pxToRem(34)

interface IPropsStyledNavDropdownItem {
  highlighted: boolean
}
export const LogoLockup = styled(FlexRow)`
  height: 100%;
  margin-right: ${px8};
  position: relative;
  overflow: visible;
  justify-content: flex-start;
`

export const SiteNavHamburger = styled(FlexRow)`
  display: flex;
  width: ${px32};
  height: 100%;
  justify-content: center;
  margin-top: 1px;
  padding: 5px;
  &.is-toggled--true,
  &:hover {
    background-color: ${palette.white};
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    display: none;
  }
`
export const CbsLogo = styled(FlexRow)`
  color: ${palette.uiNavBlue1};
  margin-left: ${px6};
  height: ${px34};
  width: ${px21};
  font-size: 17px;
  transition: opacity 0.2s ease;
  overflow: visible;
  span.icon-moon-cbs-eye {
    &::before {
      color: ${palette.white};
    }
  }
`
export const CbsLogoSection = styled.div`
  background-color: ${navBg};
  display: flex;
  overflow: visible;
  height: ${px34};
  position: relative;
`

// &::before {
//   content: "\\e95a";
// }
export const CbsLogoSport = styled.div`
  svg {
    height: 17px;
    width: auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  padding: 0 ${px14} 0 0;
  font-size: ${px20};
  letter-spacing: ${pxToRem(0.2)};
`
export const CbsLogoIcon = styled.div`
  transform: translateY(-50%);
  font-size: ${px4};
  position: absolute;
  right: ${px4};
  top: 48%;
  vertical-align: top;
`
export const DropdownContainer = styled.div`
  zoom: 1;
  background-color: #fff;
  box-shadow: 0px ${px10} ${px20} ${pxToRem(-5)} rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #232323;
  font-size: ${px16};
  padding: ${px16} ${px24};
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: ${zIndexes.siteNav};
  left: 0;
  white-space: nowrap;
  min-height: ${pxToRem(250)};
  overflow: auto;
`
export const NavSection = styled.div`
  border-right: ${pxToRem(1)} solid #e6e7e9;
  display: inline-block;
  margin-right: ${px30};
  padding-right: ${px30};
  vertical-align: top;
  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
`
export const StyledNavDropdownItem = styled(Link)<IPropsStyledNavDropdownItem>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${palette.default};
  font-size: ${px14};
  font-weight: ${fontWeight.semiBold};
  font-family: ${fontFamily.condensed};
  letter-spacing: ${pxToRem(1.5)};
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  @media (min-width: ${pxToRem(1190)}) {
    font-size: ${px16};
  }
  @media (hover: hover) {
    :hover:not(:active) {
      color: ${palette.blueActive};
    }
  }
  :active {
    color: ${palette.blueActive};
  }
`
export const NavDropdownItemIcon = styled.span<IPropsStyledNavDropdownItem>`
  color: ${palette.brandPrimaryBlue};
  font-size: ${px13};
  padding-right: ${px10};
  vertical-align: top;
`
export const NavDropdownItemText = styled.span`
  background-position: 0% 0%;
  background-size: auto 200%;
  line-height: 2.2;
  position: relative;
  color: inherit;
  font-weight: inherit;
  @media (hover: hover) {
    a:hover:not(:active) > & {
      box-shadow: inset 0 -6px 0 0 ${palette.white}, inset 0 -7px 0 0 ${palette.blueActive};
      background-position: 0% 100%;
    }
  }
  a:active > & {
    box-shadow: inset 0 -6px 0 0 ${palette.white}, inset 0 -7px 0 0 ${palette.blueActive};
    background-position: 0% 100%;
  }
  &.external-link:after {
    font-family: "icon-moon";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    vertical-align: middle;
    color: ${palette.neutral70Icon};
    content: "";
    padding-left: ${px4};
    font-size: ${px11};
    vertical-align: baseline;
  }
`
