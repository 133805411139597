import { useQuery } from "@apollo/client"
import { Field, Form, FormikErrors, FormikProps, withFormik } from "formik"
import React from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { PoolInvitationViaQuery, PoolInvitationViaQueryVariables } from "../../../../__generated__/PoolInvitationViaQuery"
import { extractValidationError } from "../../../../common/apiErrors"
import { encryptedPoolPasswordParamKey } from "../../../../common/email-invites"
import { pseudoDecrypt } from "../../../../common/string-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonBase } from "../../../components/Button"
import CustomCopy from "../../../components/CustomCopy"
import { FlexRow } from "../../../components/FlexComponents"
import { errorClassFor, FormErrors, FormSuccessStatus } from "../../../components/Form"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import SeasonDisclaimer from "../../../components/SeasonDisclaimer"
import Spinner from "../../../components/Spinner"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import Analytics from "../../../utils/analytics"
import { canUseDom, noop } from "../../../utils/misc-utils"
import { getInvitationInfo, getStringParam } from "../../../utils/url-utils"
import FixedBgScreen from "../../NonPoolPages/styles/FixedBgScreen.styles"
import { POOL_INVITATION_VIA_QUERY } from "../../queries"
import AuthGateViewLogo from "../components/AuthGateViewLogo"
import PoolAttributeTable from "../components/PoolAttributeTable"
import { ICreatePoolFormValues, IJoinPoolFormProps, IJoinPoolFormValues, IJoinPoolProps } from "../PoolSetupTypes.d"
import {
  AuthenticateRow,
  ChallengeEntryContainer,
  FormButton,
  InviterAvatar,
  PoolFormDesktopHr,
  PoolFormh2,
  PoolFormh3,
  PoolFormMobileHr,
  StyledForm,
} from "../styles/CommonPoolSetup.styles"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import LimboPage from "../../PoolPages/containers/MultipleEntriesArea/LimboPage"
import constants from "../../../../common/constants"

const containerStyles = {
  display: "block",
  maxWidth: "unset",
}
const titleStyles = {
  maxWidth: "unset",
}

const useAvatar = false

type TJoinPoolFormComponentProps = IJoinPoolFormProps & FormikProps<IJoinPoolFormValues>
export class JoinPoolFormComponent extends React.PureComponent<TJoinPoolFormComponentProps> {
  public componentDidMount() {
    if (this.props.redirectOnMount) {
      this.props.redirectOnMount()
    }
  }

  public render() {
    const props = this.props
    const { isSubmitting, errors, pool, season, needsAuthSearch, invitedViaName, invitedViaAvatar, status } = props
    const isValid = props.isValid || !Object.keys(errors).length

    const avatar = <InviterAvatar as="img" src={invitedViaAvatar} alt={`Invited by ${invitedViaName}`} />
    const logo = <AuthGateViewLogo gameInstanceUid={pool.gameInstanceUid} season={season} className="logo logo__join" />
    const invitation = (
      <>
        <strong>{invitedViaName}</strong>&nbsp;has invited you to join their <CustomCopy code="titleWithPool" />
      </>
    )
    const welcome = (
      <>
        Welcome to this <CustomCopy code="titleWithPool" />
      </>
    )

    const content = (
      <FlexRow className="fm fm--join-pool">
        <Field
          className={errorClassFor(props, "password")}
          type="text"
          name="password"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={true}
          placeholder="Enter Pool Password"
        />
        <FormButton
          className={`is-valid--${isValid} is-submitting--${isSubmitting} is-form-spinner`}
          as="button"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          <span>{status === FormSuccessStatus ? `Success!` : `Join`}</span>
          <Spinner />
        </FormButton>
      </FlexRow>
    )

    return (
      <ToggleNexusTheme isNexusStyles={false}>
        <StyledForm as={Form} className="join-pool">
          <AnalyticScreen feature="setup" subfeature="join pool" title="Setup - Join Pool" />
          <FixedBgScreen />
          {/*<MobileBackBtn />*/}
          <ChallengeEntryContainer className="join-pool is-modal--false" style={containerStyles}>
            {invitedViaAvatar && useAvatar ? avatar : logo}
            <PoolFormh2 className="title" style={titleStyles}>
              {invitedViaName ? invitation : welcome}
            </PoolFormh2>
            <PoolFormh3 className="sub-title">"{pool.name}"</PoolFormh3>
            <PoolFormDesktopHr className="dt" />
            <PoolFormMobileHr className="mt" />
            <PoolAttributeTable pool={pool} canEdit={false} switchStep={noop} className="recap--join-pool" />
            {needsAuthSearch ? (
              <AuthenticateRow>
                <ButtonBase as={Link} to={`/auth/login?${needsAuthSearch}`}>
                  Login / Register
                </ButtonBase>
              </AuthenticateRow>
            ) : (
              content
            )}
            <FormErrors {...props} />
            <SeasonDisclaimer className="disclaimer" season={season as any} />
          </ChallengeEntryContainer>
        </StyledForm>
      </ToggleNexusTheme>
    )
  }
}

const joinPoolFormikOptions = {
  mapPropsToValues: (props) => {
    return {
      poolId: props.poolId,
      password: props.encryptedPassword || "",
      // invitedVia: props.invitedVia,
      // invitedByEntryId: props.invitedByEntryId,
      invitationId: props.invitationId,
    }
  },

  validate: (values: ICreatePoolFormValues) => {
    const errors: FormikErrors<any> = {}
    if (!values.password) {
      errors.password = "Please fill in a pool password"
    }
    return errors
  },

  handleSubmit: (variables, actions) => {
    const { mutation, onFinish } = actions.props
    Analytics.trackInteraction("join pool - submit")

    return mutation({ variables })
      .then((_res) => {
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        setTimeout(onFinish, 900)
        Analytics.trackInteraction("join pool - success")
      })
      .catch((err) => {
        // console.log('err')
        // console.dir(err)
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
        Analytics.trackInteraction("join pool - fail")
      })
  },
}
export const JoinPool = withFormik<IJoinPoolFormProps, IJoinPoolFormValues>(joinPoolFormikOptions)(JoinPoolFormComponent)

function JoinPoolWrapper(props: IJoinPoolProps) {
  const { poolRoot, joinUrl, isPoolJoinRoute, entryId, allSegments, poolId, poolData, ...rest } = props
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch<any>()
  const messageBoardMatch = useRouteMatch({
    path: constants.MESSAGE_BOARD_ROUTE,
    strict: true,
    sensitive: true,
  })

  const testingChallengePoolMatch = useRouteMatch({
    path: constants.TESTING_CHALLENGE_POOL_ROUTE,
    strict: true,
    sensitive: true,
  })

  const invitedByInfo = getInvitationInfo(location.search)
  const invitedByKey = (invitedByInfo.invitedByEntryId || "").toString()
  const variables = { key: invitedByKey, poolId, skipInviter: !invitedByKey }
  const skip = !variables.poolId
  const poolInvitationViaQuery = useQuery<PoolInvitationViaQuery, PoolInvitationViaQueryVariables>(POOL_INVITATION_VIA_QUERY, { variables, skip })
  const invitedViaUser = poolInvitationViaQuery.data?.user
  const detailedPool = poolInvitationViaQuery.data?.pool
  const segment = allSegments.find((seg) => seg.season.id === detailedPool?.season.id)
  const { areaHasMultipleEntriesPerUser } = poolData || emptyObject
  if (!detailedPool || !segment) {
    return <LoadingView />
  }
  let encryptedPassword = detailedPool.reinvitedPassword || null
  if (!encryptedPassword) {
    try {
      encryptedPassword = props.encryptedPassword || getStringParam(encryptedPoolPasswordParamKey, location.search)
      encryptedPassword = (encryptedPassword && pseudoDecrypt(encryptedPassword.toString())) || null
    } catch (error) {
      if (canUseDom) {
        window.SH_ERROR_NOTIFY(error)
      }
    }
  }
  // TODO qac: memoize callbacks
  const onFinish = () => history.push(poolRoot)
  const redirectOnMount = isPoolJoinRoute ? (entryId ? onFinish : undefined) : () => history.replace(joinUrl)
  // NOTE qac: invitedViaUser && invitedViaUser.avatarUrl doesnt work for avatars!

  if (areaHasMultipleEntriesPerUser) {
    return (
      <LimboPage
        {...props}
        history={history}
        location={location}
        match={match}
        redirectOnMount={messageBoardMatch?.isExact || testingChallengePoolMatch?.isExact ? undefined : redirectOnMount}
      />
    )
  }
  return (
    <JoinPool
      {...rest}
      {...invitedByInfo}
      pool={detailedPool}
      poolId={detailedPool.id}
      season={segment.season}
      invitedViaName={invitedViaUser?.preferredEntryName || undefined}
      onFinish={onFinish}
      poolData={poolData}
      redirectOnMount={redirectOnMount}
      encryptedPassword={encryptedPassword}
    />
  )
}

export default JoinPoolWrapper
