import styled from "styled-components/macro"
import { colorFor } from "../../../../common/theme-helpers"
import { ButtonBase, OpacityBtn } from "../../../components/Button"
import { FillScreen, FlexCols, FlexRow } from "../../../components/FlexComponents"
import { breakpoints, fontWeight, layout, palette, pxToRem } from "../../../utils/style-utils"
import { IStepHeaderContainer } from "../PoolSetupTypes.d"
import {
  inputBorderFor,
  inputBgColorFor,
  inputFocusStyleFor,
  placeholderColorFor,
  settingsEditBtnColorFor,
} from "../../../../common/form-theme-helpers"

export const PoolSettingsUpdateBumper = styled.div`
  flex: 10 0 100%;
`

export const PoolSetupTopUi = styled.div`
  width: 100%;
  position: relative;
  flex: 0 0 ${({ theme }) => pxToRem(theme.isNexusStyles ? 0 : 16 * 5)};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2vh;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    flex: 0 0 ${({ theme }) => pxToRem(theme.isNexusStyles ? 0 : 16 * 5)};
  }
`
export const PoolFormContainer = styled(FillScreen)`
  flex: 1 0 auto;
  justify-content: flex-start;
  padding: ${({ theme }) => pxToRem(theme.isNexusStyles ? 16 : 0)} 0 0;
  ${({ theme }) => (!theme.isNexusStyles ? "" : "min-height: unset !important; flex: 1;justify-content: flex-start;")}
  color: ${colorFor};

  &.white-bkg {
    background-color: ${palette.white};
    background-image: url(/static/sport-assets/basketball/pool-setup-bg.svg);
    background-repeat: repeat-x;
  }

  &.is-bracket {
    overflow: initial;
    padding-top: 0;
  }

  &.fill-hack__true {
    flex: none;
  }

  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    ${({ theme }) => (!theme.isNexusStyles ? "min-height: 100%" : "flex: 1 1 100%")};
    padding: ${({ theme }) => pxToRem(!theme.isNexusStyles ? 0 : 16)} 0 0;
  }
`
export const PoolFormTransitionContainer = styled(FlexCols)`
  &.uses-animation--true {
    perspective: ${pxToRem(1200)};
  }
  flex: 1 1 100%;
  position: relative;
  & > *,
  & > * > * {
    width: 100%;
    flex: 1 0 100%;
    display: flex;
    flex-flow: column;
  }
`

export const FormGutters = styled.div`
  flex: 2;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    margin: 0 ${pxToRem(16)};
  }
`

export const FormCtaIcon = styled.div`
  & svg {
    border-radius: 100%;
    color: ${palette.gray1};
    border: ${pxToRem(1)} solid ${palette.gray6};
    margin: ${pxToRem(6)} auto;
    width: ${pxToRem(130)};
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    max-width: 40vw;
    display: block;
  }
`

export const FormP = styled.p`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
    }
  }
`

export const InviterAvatar = styled.img`
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  margin: ${pxToRem(32)} auto;
  background-color: #ccc;
  border: ${pxToRem(1)} solid #fff;
  overflow: hidden;
  border-radius: 100%;
  & + .title {
    margin-top: 0;
  }
`
export const AuthenticateRow = styled(FlexRow)`
  flex-wrap: wrap;
  & p {
    padding: ${pxToRem(16)};
  }
`

const spinnerSize = 42
export const FormButton = styled(ButtonBase)`
  position: relative;
  width: ${pxToRem(300)};
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    max-width: none;
    width: 100%;
  }
  &.is-submitting--true {
    color: rgba(255, 255, 255, 0);
    & svg {
      opacity: 1;
      transform: scale(1);
    }
  }
  & > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: ${pxToRem(-spinnerSize / 2)} 0 0 ${pxToRem(-spinnerSize / 2)};
    width: ${pxToRem(spinnerSize)};
    height: ${pxToRem(spinnerSize)};
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: scale(0);
    color: ${palette.white};
  }
`

export const StepCustomButton = styled(ButtonBase)`
  max-width: ${pxToRem(280)};
  flex-wrap: wrap;
  & span {
    color: #88d7ff;
    line-height: ${pxToRem(18)};
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    width: 100%;
    max-width: none;
  }
`

export const FormActionsContainer = styled(FlexRow)`
  padding: ${pxToRem(20)} 0;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    padding: ${pxToRem(60)} 0;
  }
`
export const FieldLabel = styled.label``

export const WhiteForm = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: ${pxToRem(layout.wrapperWidth)};
  & > * {
    width: 100%;
    margin-bottom: ${pxToRem(12)};
  }
`

export const InviteStepCustomContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
  max-width: ${pxToRem(layout.wrapperWidth)};
  padding-bottom: 4vh;

  & input {
    border: ${pxToRem(1)} solid ${inputBorderFor};
    background-color: ${inputBgColorFor};
    &:focus {
      ${inputFocusStyleFor};
    }
    &::placeholder {
      color: ${placeholderColorFor};
    }
  }
  & fieldset:not(:empty) {
    margin-bottom: ${pxToRem(8)};
    background-color: #ffffff61;
    border-radius: ${pxToRem(6)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & form > *:last-child {
      margin-left: ${pxToRem(12)};
    }
    & form {
      padding-top: 4vh;
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & form > *:first-child {
      padding-left: ${pxToRem(6)};
      padding-right: ${pxToRem(6)};
    }
  }
`

export const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: ${pxToRem(layout.wrapperWidth)};

  & input:not([type="checkbox"]) {
    max-width: ${pxToRem(450)};
    padding: ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(31)};
    letter-spacing: ${pxToRem(1.4)};
    border-radius: ${pxToRem(3)};
    display: block;
    outline: none;
    margin: 0 auto ${pxToRem(32)};
    width: 100%;
    font-weight: ${fontWeight.semiBold};
    border: ${pxToRem(1)} solid ${inputBorderFor};
    background-color: ${inputBgColorFor};
    transition: all 0.2s ease;
    color: inherit;
    &:focus {
      ${inputFocusStyleFor};
    }
    &::placeholder {
      color: ${placeholderColorFor};
    }
  }
  & input:not([type="checkbox"]),
  label {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: ${pxToRem(450)};
    width: 100%;
    padding: ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(31)};
    border-radius: ${pxToRem(3)};
    font-weight: ${fontWeight.semiBold};
  }
  & input[type="checkbox"] {
    margin-right: 0.5em;
  }
  & .form-row {
    margin-top: 0.5rem;
  }
  & .form-row input {
    margin-bottom: 0;
  }
  & .form-input--checkbox label {
    white-space: normal;
    font-size: 0.9rem;
    line-height: 1.3;
    padding-left: 0;
    padding-right: 0;
  }
  & input.has-errors {
    border-color: ${palette.formError};
  }
  &.is-modal--true {
    padding: 0;
    min-height: 60vh;
    position: relative;
  }

  &.custom-step--invite {
    padding-bottom: 4vh;
  }
  &.custom-step--invite fieldset:not(:empty) {
    margin-bottom: ${pxToRem(8)};
    background-color: #ffffff61;
    border-radius: ${pxToRem(6)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.custom-step--invite form > *:last-child {
      margin-left: ${pxToRem(12)};
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.custom-step--invite form > *:first-child {
      padding-left: ${pxToRem(6)};
      padding-right: ${pxToRem(6)};
    }
  }

  &.is-update--true {
    display: block;
  }

  & .season-disclaimer {
    display: block;
    padding: 1rem;
    text-align: center;
  }
`
export const ChallengeEntryContainer = styled.div`
  & {
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    max-width: ${pxToRem(650)};
    margin: 0 auto;
    color: ${colorFor};
    &.join-pool {
      display: block;
      max-width: unset;
    }
  }

  & .logo__join {
    height: 15vh;
    padding-top: 2vh;
    min-height: unset;
    display: flex;
    justify-content: center;
  }
  & .fm {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & .fm > * {
    margin: ${pxToRem(32)} auto 0;
  }
  & .fm.fm--join-pool {
    justify-content: center;
    align-items: center;
    padding: 0 ${pxToRem(16)};
  }
  & .fm.fm--join-pool > * {
    margin: ${pxToRem(12)} 0 0;
  }
  & small {
    display: block;
    padding: 2vh ${pxToRem(6)};
    margin: 0 auto;
    font-size: ${pxToRem(12)};
    line-height: 1.2em;
    max-width: ${pxToRem(900)};
    text-align: center;
  }
  & [type="submit"] {
    max-width: ${pxToRem(450)};
    width: 100%;
    margin: ${pxToRem(32)} auto 0;
    min-width: ${pxToRem(150)};
    flex: 1 0 100%;
  }
  & input:not([type="checkbox"]) {
    margin: 0 ${pxToRem(34)};
    max-width: ${pxToRem(450)};
    min-width: ${pxToRem(350)};
  }
  & > * > p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(16)};
    line-height: 1.3em;
  }
  & .title {
    text-transform: none;
    text-align: center;
    line-height: 1.1em;
  }
  & .sub-title {
    text-align: center;
    letter-spacing: ${pxToRem(1.2)};
    font-weight: ${fontWeight.semiBold};
    text-transform: none;
  }
  @media (min-width: ${pxToRem(660)}) {
    & [type="submit"] {
      height: ${pxToRem(53)};
      min-height: auto;
      max-width: ${pxToRem(150)};
      min-width: ${pxToRem(150)};
      flex: 0 0 auto;
    }
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & .title {
      margin: 0 auto;
    }
    & .dt {
      margin: 2vh auto !important;
    }
    & .fm {
      justify-content: space-between;
    }
    & .logo__join {
      height: 8rem;
      height: 20vh;
      max-height: ${pxToRem(180)};
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.is-modal--true {
      min-height: 90vh;
    }
    & [type="submit"] {
      flex: 1 0 auto;
      margin-top: 2vh;
    }
    & .title {
      font-size: ${pxToRem(22)};
      margin-left: 0;
      margin-right: 0;
      width: 92%;
      margin-right: auto;
      margin-left: auto;
    }
    & .mt {
      margin: 2vh auto;
    }
    & input:not([type="checkbox"]) {
      margin: 2vh auto 0;
      min-width: auto;
    }
    & small {
      padding: 2vh ${pxToRem(8)};
    }
    & > * > div {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  &.is-modal--false {
    & small a {
      color: currentColor !important;
    }
  }
  &.is-modal--true {
    display: block;
    max-width: 100%;
    background-color: ${palette.white};
    width: ${pxToRem(700)};
    padding: 0 ${pxToRem(32)};
    border-radius: ${pxToRem(7)};
    min-height: 60vh;
    & > * {
      white-space: normal;
    }
    @media (max-width: ${pxToRem(600)}) {
      padding: 0;
      & p {
        line-height: ${pxToRem(22)} !important;
      }
      & .logo {
        min-height: 15vh;
        max-height: 30vh;
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
      & .logo svg {
        height: auto;
      }
      & input:not([type="checkbox"]) {
        margin: 2vh auto 0;
      }
    }
  }
`

export const MobileBackBtnStyled = styled(OpacityBtn)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 2vh;
  color: ${colorFor};
  padding: ${pxToRem(16)};
  padding-top: 0;
  & > * {
    padding-right: ${pxToRem(8)};
    color: inherit;
    font-weight: ${fontWeight.extraBold};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`

export const PoolFormSection = styled.div`
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    text-align: left;
    margin: 0 ${pxToRem(12)} ${pxToRem(8)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    margin: 0 auto ${pxToRem(32)};
  }
`
export const PoolFormh3 = styled(PoolFormSection)`
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    text-align: center;
    margin: 2vh auto;
    font-size: ${pxToRem(18)};
    letter-spacing: ${pxToRem(6.1)};
    text-transform: uppercase;
  }
  @media (max-height: ${pxToRem(600)}) {
    font-size: ${pxToRem(12)};
    line-height: 1em;
  }
`
export const PoolFormh2 = styled(PoolFormSection)`
  text-align: center;
  max-width: ${pxToRem(700)};
  font-size: ${pxToRem(36)};
  line-height: 1.2em;
  font-weight: ${fontWeight.extraBold};
  text-transform: uppercase;
  letter-spacing: ${pxToRem(0.8)};
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    font-size: ${pxToRem(24)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    margin-bottom: 0;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-size: ${pxToRem(26)};
  }
  @media (max-height: ${pxToRem(600)}) {
    font-size: ${pxToRem(16)};
    line-height: 1em;
  }
`
export const PoolFormHr = styled.div`
  background-color: ${colorFor};
  border-radius: ${pxToRem(3)};
`
export const PoolFormDesktopHr = styled(PoolFormHr)`
  height: ${pxToRem(3)};
  width: ${pxToRem(97)};
  margin: auto !important;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`
export const PoolFormMobileHr = styled(PoolFormHr)`
  margin: ${pxToRem(16)} ${pxToRem(12)} 0;
  width: ${pxToRem(60)};
  height: ${pxToRem(2)};
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`

export const StepHeaderContainer = styled(FlexCols)<IStepHeaderContainer>`
  justify-content: flex-start;
  min-height: 25vh;
  & .img {
    margin: 5vh auto;
  }
  @media (min-height: ${pxToRem(500)}) {
    flex: 2 0 auto;
    min-height: ${pxToRem(165)};
  }
  @media (max-height: ${pxToRem(900)}) {
    flex: 2 0 auto;
    min-height: ${pxToRem(120)};
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    padding-bottom: 2vh;
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) and (max-height: ${pxToRem(500)}) {
    /* NOTE qac: added these late... hopefully this is ok */
    justify-content: flex-end;
    flex: 0 0 auto;
    min-height: unset;
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    min-height: ${pxToRem(320)};
    max-height: ${pxToRem(456)};
  }
`

export const LogoStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  min-height: ${pxToRem(170)};
  padding: 2rem;
  & svg,
  img {
    max-height: ${pxToRem(170)};
  }

  &.with-sponsor {
    align-items: center;
    display: flex;
    & svg {
      max-height: 6rem;
      max-width: 14.5rem;
    }
    & img {
      max-height: 8rem;
    }
  }
  @media (max-width: 600px) {
    & {
      min-height: 15vh;
    }
    & svg,
    img {
      max-height: 15vh;
    }

    &.with-sponsor {
      align-items: center;
      display: flex;
      & svg {
        max-height: 4rem;
        max-width: 9.75rem;
      }
      & img {
        max-height: 6rem;
      }
    }
  }
`
export const Spacer = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${palette.gray20};
  margin: 0 1rem !important;
`

// TODO qac: ewww
export const PoolSetupStepFormRow = styled(FlexRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  transition: all 0.5s ease-in-out;
  overflow: visible;
  flex: 0 0 auto;
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & > [class*="PoolSetupStepFormCols"] {
      margin: 0;
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: block;
    flex: 0 1 auto;
    padding-bottom: ${pxToRem(16)};
    & > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
`
export const PoolSetupStepFormColDesktopDescription = styled.p`
  padding: ${pxToRem(28)} 0;
  font-size: ${pxToRem(15)};
  text-align: center;
  line-height: ${pxToRem(24)};
  max-width: ${pxToRem(500)};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`
export const PoolSetupStepFormCols = styled(FlexCols)`
  padding: ${pxToRem(4)};
  max-width: ${pxToRem(320)};
  margin: 0 auto;
  min-height: ${pxToRem(65)};
  & p {
    padding: ${pxToRem(28)} 0;
    font-size: ${pxToRem(16)};
    font-weight: ${fontWeight.semiBold};
    text-align: center;
    line-height: ${pxToRem(24)};
    @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(layout.useMobileNavW)}) {
    &.is-desktop-alt {
      position: fixed;
      right: 0;
      bottom: 1rem;
      overflow: hidden;
      & div {
        display: none !important;
      }
      & button:hover {
        opacity: 0.8;
      }
      & button:active {
        opacity: 0.6;
      }
      & button:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-color: transparent transparent transparent ${palette.buttonBlue1};
        border-style: solid;
        border-width: ${pxToRem(10)};
        position: absolute;
        right: ${pxToRem(28)};
        top: ${pxToRem(26)};
      }
      & * {
        background: none !important;
        border: none !important;
        font-size: ${pxToRem(22)};
        white-space: nowrap;
        margin-right: ${pxToRem(12)};
      }
    }
  }
`

export const PoolSetupStepFormColPEmpty = styled.div`
  background: rgba(199, 199, 199, 0.23);
  width: ${pxToRem(72)};
  height: ${pxToRem(3)};
  max-height: ${pxToRem(3)};
  border-radius: ${pxToRem(3)};
  margin-top: ${pxToRem(45)};
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`

export const PoolSetupStepFormColsDl = styled(FlexCols)`
  max-width: ${pxToRem(420)};
  padding: 0 ${pxToRem(16)};
  font-size: ${pxToRem(15)};
  @media (max-width: ${pxToRem(420)}) {
    max-width: 100%;
    overflow: hidden;
  }
`

export const poolSettingsRowW = 350
export const PoolSetupStepFormDlRow = styled(FlexRow)`
  min-height: ${pxToRem(40)};
  width: 100%;
  justify-content: space-between;
  white-space: normal;
  max-width: ${pxToRem(poolSettingsRowW)};
  margin: 0 auto;
  & strong {
    text-align: right;
    flex: 1 1 auto;
    font-weight: ${fontWeight.bold};
  }
  & * {
    line-height: 1.3em;
  }
  @media (max-height: ${pxToRem(800)}) {
    & {
      min-height: ${pxToRem(32)};
    }
  }
`

// const recapOverflowBehavior = ({theme}) => theme.isNexusStyles ? "" : `overflow: auto; max-height: 40vh; padding: ${pxToRem(8)} 0 ${pxToRem(16)}; box-shadow: inset 0 -1.5rem 1.5rem -1.5rem #00000063`;
export const PoolSetupStepFormRecapContainer = styled(PoolSetupStepFormColsDl)`
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  max-width: ${pxToRem(1000)};
  padding: ${pxToRem(16)} 0 ${pxToRem(24)};
  align-items: flex-start;
  flex: 0 0 auto;
  min-height: 20vh;
  & > * {
    flex: 1 1 auto;
    padding: 0 ${pxToRem(16)};
  }
  @media (max-width: ${pxToRem(350)}) {
    & > * {
      min-width: 100%;
    }
  }
  @media (max-height: ${pxToRem(1200)}) and (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.allow-mobile-overflow--true {
      overflow: auto;
      max-height: 30vh;
      padding: ${pxToRem(8)} 0 ${pxToRem(16)};
      box-shadow: inset 0 -1.5rem 1.5rem -1.5rem #00000063;
    }
  }
  &.recap--join-pool {
    padding-bottom: 0;
    margin-bottom: ${pxToRem(16)};
  }
`
export const SettingsEditBtn = styled(OpacityBtn)`
  display: inline-block;
  margin-left: ${pxToRem(6)};
  padding: ${pxToRem(6)};
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  color: ${settingsEditBtnColorFor};
  &[disabled] {
    opacity: 0;
  }
  & .close {
    width: 1.5em;
    height: 1.5em;
  }
`

export const PoolSetupStepPicksCapStyles = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  max-width: ${pxToRem(600)};
  padding: 0 ${pxToRem(16)};
  & > div {
    width: 100%;
    margin: 0 auto;
  }
  & button {
    max-width: ${pxToRem(300)};
  }
  & .Dropdown {
    width: 100%;
  }
  & input {
    max-width: ${pxToRem(300)};
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & {
      flex: 2 1 auto;
      width: 100%;
      align-items: center;
      max-height: ${pxToRem(180)};
    }
    & > div {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      flex: 1;
      width: 100%;
    }
    & > div > div {
      width: 100%;
      max-width: ${pxToRem(300)};
    }
    & button {
      margin-bottom: ${pxToRem(32)};
    }
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) and (max-height: ${pxToRem(800)}) {
    & {
      margin-top: 15vh;
    }
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & {
      flex: 2 0 100%;
      justify-content: space-between;
      max-height: ${pxToRem(220)};
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    & button {
      padding: 0;
      min-width: unset;
      height: unset;
      width: ${pxToRem(100)};
      min-height: ${pxToRem(30.5)};
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    & .Dropdown-selectedText {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & input {
      margin: 0;
    }
    & input + button {
      height: 100%;
    }
  }
`

export const PoolSetupStepRoundBonusesCols = styled(PoolSetupStepFormCols)`
  max-width: ${pxToRem(450)};
  padding: 0 ${pxToRem(24)};
  & label {
    font-size: ${pxToRem(15)};
    padding-bottom: ${pxToRem(8)};
    text-align: center;
  }
  & input {
    margin-bottom: 0;
    max-width: ${pxToRem(150)};
    text-align: center;
  }
  &:last-child {
    margin-bottom: ${pxToRem(16)};
  }
`
