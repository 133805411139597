import * as React from "react"
import { useState } from "react"
import LocalKey from "../../../Base/LocalKey"
import { IconButton } from "../../../components/Button"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import Modal, { ModalWrapper } from "../../../components/Modal"
import WilliamHillLink from "../../../components/WilliamHillLink"
import {
  AuthBtn,
  DebugModeContainer,
  MobileNavHamburgerBox,
  MobileNavHamburgerInner,
  MobileNavModalAuthBtnContainer,
  MobileNavModalInner,
  NetworkBar,
  NetworkBarNetworks,
  NetworkBarSocial,
  SocialButton,
  StickyNav,
  StyledMobileNavHamburger,
  StyledNetworkBarNetworksItem,
} from "../styles/SiteNav.styles"
import { IDebugPanelProps, IMobileNavModalProps, LayoutProps } from "../typings"
import SiteNavDropdown from "./SiteNavDropdown"
import SiteNavList from "./SiteNavList"
import SiteNavSide, { AccountSpoes } from "./SiteNavSide"
import { SiteNavSidePlayItemContents } from "./SiteNavSidePlayItem"
import constants from "../../../../common/constants"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
const DebugInfoModal = React.lazy(() => import("./DebugInfoModal"))

const MobileNavHamburger = ({ toggle }) => {
  return (
    <StyledMobileNavHamburger as="button" onClick={toggle} type="button" aria-label="Menu" aria-controls="navigation">
      <MobileNavHamburgerBox>
        <MobileNavHamburgerInner />
      </MobileNavHamburgerBox>
    </StyledMobileNavHamburger>
  )
}

const NetworkBarNetworksItem = ({ href, children, highlighted = false }) => (
  <StyledNetworkBarNetworksItem highlighted={highlighted} href={href} target="_blank" rel="noopener nofollow">
    {children}
  </StyledNetworkBarNetworksItem>
)

const MobileNavLoggedOutItems = ({ pathname }) => {
  const search = `returnUrl=${encodeURIComponent(pathname)}`
  return (
    <React.Fragment>
      <MobileNavModalAuthBtnContainer>
        <AuthBtn
          as={Link}
          to={{
            pathname: "/auth/login",
            search,
          }}
          rel="nofollow noopener"
        >
          Login
        </AuthBtn>
        <AuthBtn
          as={Link}
          to={{
            pathname: "/auth/signup",
            search,
          }}
          rel="nofollow noopener"
        >
          Create Account
        </AuthBtn>
      </MobileNavModalAuthBtnContainer>
      <SiteNavSidePlayItemContents />
    </React.Fragment>
  )
}

class MobileNavModal extends React.PureComponent<IMobileNavModalProps> {
  public static modalKey = "MobileNavModal"

  public render() {
    const { isOpen, close, ...layoutProps } = this.props
    return (
      <Modal isOpen={isOpen} onBackgroundClick={close} className="mobile-nav-modal" modalType="mobileNav">
        <MobileNavModalInner>
          {layoutProps.hasCbsAccount ? <AccountSpoes {...layoutProps} /> : <MobileNavLoggedOutItems pathname={layoutProps.pathname} />}
        </MobileNavModalInner>
      </Modal>
    )
  }
}

const boldFont = { fontWeight: "bold" } as React.CSSProperties

const DebugPanel: React.FC<IDebugPanelProps> = (props) => {
  const { isOpen, close, ...rest } = props
  return (
    <Modal isOpen={isOpen} onBackgroundClick={close} modalType="modal">
      <ModalWrapper header="DEV MODE INFO" closeBtnAction={close}>
        <React.Suspense fallback={<LoadingView />}>
          <DebugInfoModal {...rest} />
        </React.Suspense>
      </ModalWrapper>
    </Modal>
  )
}

const SiteNav: React.FC<LayoutProps> = (props) => {
  const [devModeDialogOpen, setDevModeDialogOpen] = useState(false)
  const [devModeTabOpen, setDevModeTabOpen] = useState(false)
  const poolDataContext: PoolDataContextType = React.useContext(PoolDataContext)

  // Want to show debug component if the debug cookie is set
  const canSeeDebugInfo = poolDataContext?.centralCurrentUsersEntriesQuery.data?.canSeeDebugInfo || false
  const isInDebugMode = poolDataContext?.centralCurrentUsersEntriesQuery.data?.isInDebugMode || false

  const layoutProps = props
  const poolId = props.poolId

  const isSkybox = layoutProps.adType === "skybox"
  // console.dir(layoutProps);
  const isInPools = !!props.poolId

  return (
    <LocalKey keyName="TopNavToggled">
      {({ value, update }) => (
        <React.Fragment>
          <NetworkBar id={isSkybox ? "cbs-network-bar" : "faux-cbs-network-bar"}>
            <NetworkBarNetworks>
              <NetworkBarNetworksItem href="https://www.cbssports.com/" highlighted={true}>
                CBSSports.com
              </NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://247sports.com/">247Sports</NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://www.maxpreps.com/">maxpreps</NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://www.scout.com/fantasy">Scout</NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://www.sportsline.com/">SportsLine</NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://shop.cbssports.com/?utm_source=cbssportscom_hp&utm_medium=bm_referral&utm_term=kw--CBS_Sports">
                Shop
              </NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://www.golfbook.com/?utm_source=cbssports&utm_medium=link&utm_campaign=globaldesktop_nav">
                Play Golf
              </NetworkBarNetworksItem>
              <NetworkBarNetworksItem href="https://www.stubhub.com/?gcid=chAFF-_-geoUS-_-genAllTix-_-dt170502-_-cmpHpdropdown-_-partCBSSports">
                Stubhub
              </NetworkBarNetworksItem>
              {constants.SHOW_GAMBLING_PARTNER_BRANDING && (
                <WilliamHillLink Component={NetworkBarNetworksItem} placement={constants.BRAND_BAR_PLACEMENT} pathname={layoutProps.pathname}>
                  Caesars Sportsbook
                </WilliamHillLink>
              )}
            </NetworkBarNetworks>
            <NetworkBarSocial>
              <SocialButton
                href="https://www.facebook.com/FantasyFootballToday/"
                className="icon-moon-facebook"
                target="_blank"
                rel="nofollow noopener"
                aria-label="Facebook"
                title="Facebook"
              />
              <SocialButton
                href="https://twitter.com/cbsfantasy/"
                className="icon-moon-twitter"
                target="_blank"
                rel="nofollow noopener"
                aria-label="Twitter"
                title="Twitter"
              />
            </NetworkBarSocial>
          </NetworkBar>
          <StickyNav id={isSkybox ? "fantNavContainer" : "faux-cbs-site-nav"} className={`top-nav-toggled--${!!value} is-sticky--${false}`}>
            <SiteNavDropdown {...layoutProps} />
            {/*<SitNavPromo as="a" href="https://www.cbssports.com/fantasy/games/football/">Play</SitNavPromo>*/}
            <SiteNavList {...layoutProps} />
            <SiteNavSide {...layoutProps} />
            {(canSeeDebugInfo && (
              <DebugModeContainer closed={!devModeTabOpen}>
                <div>
                  [ &nbsp;
                  <Link to={`${props.poolRootPathname}/file-bug`} target={"newBug"}>
                    Bug
                  </Link>
                  &nbsp; | &nbsp;
                  <Link to={isInPools ? `/admin/maint/pool/${poolId}` : "/admin/game-instances"}>Maint</Link>
                  &nbsp; ]
                </div>
                <div>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault()
                      setDevModeDialogOpen(!devModeDialogOpen)
                    }}
                  >
                    &nbsp;[Show Dev Mode Info]
                  </Link>
                </div>
                <div style={boldFont}>&nbsp;Developer Mode: {isInDebugMode ? "On" : "Off"}</div>
                <DebugPanel isOpen={devModeDialogOpen} close={() => setDevModeDialogOpen(!devModeDialogOpen)} deviceType={layoutProps.deviceType} />
                <IconButton type="button" onClick={() => setDevModeTabOpen(!devModeTabOpen)}>
                  <span role="img" aria-label="Debug Menu">
                    🐛
                  </span>
                </IconButton>
              </DebugModeContainer>
            )) ||
              null}
            <MobileNavHamburger toggle={() => update(!value)} />
            <MobileNavModal isOpen={!!value} close={() => update(null)} {...layoutProps} />
          </StickyNav>
        </React.Fragment>
      )}
    </LocalKey>
  )
}

export default SiteNav
