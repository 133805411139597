import * as React from "react"
import styled from "styled-components/macro"
import { errorClassFor, errorClassname } from "../../../components/Form"
import { palette, pxToRem } from "../../../utils/style-utils"

export const BoxWhiteInputContainer = styled.div`
  position: relative;
  flex: 1 1 100%;
`
// export const BoxWhiteInputLabel = styled.label`
// position: absolute;
// left: ${pxToRem(12)};
// top: ${pxToRem(10)};
// padding: ${pxToRem(4)};
// background-color: #fff;
// font-weight: ${fontWeight.semiBold};
// transition: all 0.1s ease-out;
// pointer-events: none;
// `
// example:
// <Field
//   name="name"
//   component={FormikBoxWhiteInput}
//   type="text"
//   autoComplete="off"
//   placeholder="Group Name"
//   required={true}
// />
interface IFormikBoxWhiteInputProps extends React.HTMLAttributes<HTMLInputElement> {
  field?: any
  form?: any
  asElement?: string
  defaultValue?: any
}
export const FormikBoxWhiteInput = React.forwardRef<HTMLInputElement, IFormikBoxWhiteInputProps>((props, ref) => {
  const { field, form, className, defaultValue, asElement, ...rest } = props
  return (
    <BoxWhiteInput
      {...field}
      as={asElement}
      {...rest}
      ref={ref}
      className={`${className || ""} ${(field && form && errorClassFor(form, field.name)) || ""}`}
    />
  )
})
export const BoxWhiteInput = styled.input`
  width: 100%;
  display: block;
  padding: ${pxToRem(8)} ${pxToRem(10)} ${pxToRem(9)};
  border: ${pxToRem(1)} solid ${palette.gray3};
  border-radius: ${pxToRem(2)};
  transition: border-color 0.2s ease;
  max-width: 100%;
  height: 100%;
  &:focus {
    border-color: ${palette.blue2};
  }
  &.${errorClassname} {
    border-color: ${palette.formError};
  }
  &[rows] {
    min-height: ${pxToRem(90)};
    min-width: 100%;
    resize: vertical;
    overflow: auto;
  }
`
export const BoxWhiteInputLabelStyled = styled.label`
  display: block;
  color: ${palette.default};
  & .white-input__label {
    position: relative;
  }
  & > div {
    padding-bottom: 1em;
  }
  &.box-white-input-is-row--true {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    & > div {
      padding-bottom: 0;
      padding-right: 1em;
    }
    & > input[type="number"] {
      max-width: ${pxToRem(100)};
    }
  }
`

export const BoxWhiteInputLabel = ({ field, label, isRow = false, className = "", ...rest }) => {
  const classNames = [`box-white-input`, `box-white-input-is-row--${isRow}`]
  if (className) {
    classNames.push(className)
  }
  return (
    <BoxWhiteInputLabelStyled className={classNames.join(" ")} {...rest}>
      <div className="white-input__label">{label}</div>
      {field}
    </BoxWhiteInputLabelStyled>
  )
}
