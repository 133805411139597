import React from "react"
import CloseSvg from "../../../components/icons/Close"
import PencilSvg from "../../../components/icons/Pencil"
import { SettingsEditBtn } from "../styles/CommonPoolSetup.styles"

export const toggleEditActionName = "toggle-edit|"

export const PoolSettingsEditBtn = ({ poolId, switchStep, attribute, disabled = false, isClose = false, ...rest }) => (
  <SettingsEditBtn
    as="button"
    type="button"
    {...rest}
    disabled={!attribute || disabled}
    onClick={(evt) => switchStep(`${toggleEditActionName}${attribute}`, poolId, evt)}
  >
    {isClose ? <CloseSvg icon={true} /> : <PencilSvg />}
  </SettingsEditBtn>
)

export default PoolSettingsEditBtn
