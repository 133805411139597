import { gql } from "@apollo/client"

export const POOL_SETTINGS_FRAGMENT = gql`
  fragment PoolSettingsFragment on PoolSettings {
    id
    picksDeadlineType
    periodLength
    spreadType
    multipleEntriesOption
    maxEntriesPerUser
    mainTiebreaker
    secondaryTiebreaker
    thirdTiebreaker
    fourthTiebreaker
    gamesPerPeriod
    pickCountRangeOption
    minPicksPerPeriodCount
    maxPicksPerPeriodCount
    gameWeightType
    roundBonusType
    roundBonuses
    roundModifiers
    roundModifiersOption
    tournamentIds
    includeChampionshipRound
    includeMessageBoard
    openInvites
  }
`
export const CENTRAL_TEAM_FRAGMENT = gql`
  fragment CentralTeamFragment on Team {
    id
    abbrev
    location
    nickName
    shortName
    sportType
    colorHexDex
    colorPrimaryHex
    colorSecondaryHex
    conferenceAbbrev
    cbsTeamId
    wins
    losses
    ties
    imageUrl
    mediumName
  }
`
export const CENTRAL_SEASON_FRAGMENT = gql`
  fragment CentralSeasonFragment on Season {
    id
    season
    year
    masterProductId
    challengePoolSlug
    challengePoolId
    requireCbsAccountBeforeEntry
    productAbbrev
    isArchived
    isCurrent
    svgLogoFullPath
    customCopy
    appPromoUrl
    rulesUrl
    disclaimerHtml
    hasPublicPools
    hasSubPools
    periodPrizeAmount
    seasonPrizeAmount
    seasonCustomPrize
    hasPrizes
    sisterSeasonId
    seasonSignupSponsorDataForm {
      id
      name
      inputFields {
        id
        type
        label
        name
        extraHtml
      }
    }
    whenToWatch {
      id
      periodName
      dates
    }
    gameInstance {
      id
      uid
      client
    }
    currentSimulatedState {
      id
      seasonId
      state
      description
      isCurrent
    }
  }
`
export const CENTRAL_SEGMENT_DB_ATTRS_FRAGMENT = gql`
  fragment CentralSegmentDbAttrsFragment on Segment {
    id
    gameType
    sportType
    seasonType
    subsection
    poolSettings {
      ...PoolSettingsFragment
    }
  }
  ${POOL_SETTINGS_FRAGMENT}
`
export const CENTRAL_SEGMENT_FRAGMENT = gql`
  fragment CentralSegmentFragment on Segment {
    analyticsChannel
    landingUrl
    baseUrl
    shouldPromoteToPlay
    isInComingSoon
    hasStarted
    hasEnded
    ...CentralSegmentDbAttrsFragment
    season {
      ...CentralSeasonFragment
    }
  }
  ${CENTRAL_SEGMENT_DB_ATTRS_FRAGMENT}
  ${CENTRAL_SEASON_FRAGMENT}
`

export const CENTRAL_TEAMS_QUERY = gql`
  query CentralTeamsQuery($sportTypes: [GameSportTypeEnumType!], $subsection: GameSubsectionEnumType) {
    teams(sportTypes: $sportTypes, subsection: $subsection) {
      ...CentralTeamFragment
    }
  }
  ${CENTRAL_TEAM_FRAGMENT}
`

export const CENTRAL_BRACKET_STATE_QUERY = gql`
  query CentralBracketsState {
    mensBracketState: bracketState(gameInstanceUid: "cbs-ncaab-tournament-challenge") {
      id
      isPreTournament
      locksAt
      isLocked
    }
    womensBracketState: bracketState(gameInstanceUid: "cbs-ncaaw-tournament-challenge") {
      id
      isPreTournament
      locksAt
      isLocked
    }
  }
`
export const CENTRAL_GAME_INSTANCES_QUERY = gql`
  query CentralGameInstancesQuery($gameType: GameGameTypeEnumType!, $sportTypes: [GameSportTypeEnumType!]!, $subsection: GameSubsectionEnumType) {
    allSegments(gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
      ...CentralSegmentFragment
      tournaments {
        id
        cbsId
        name
        abbrev
      }
      season {
        gameInstance {
          id
          uid
          client
          poolType
        }
      }
    }
    gameInstances(gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
      edges {
        node {
          client
          poolType
          id
          uid
          customCopy
          currentPeriod {
            id
            description
            order
            isCurrent
            isPickable
            locksAt
            startsAt
            notificationBannerHtml
            notificationBannerBackgroundColor
            notificationBannerColor
            globalNotificationHtml
            globalNotificationBackgroundColor
            globalNotificationColor
            segment {
              id
              season {
                id
                season
                year
                productAbbrev
                challengePool {
                  poolSettings {
                    ... on PoolSettings {
                      maxEntriesPerUser
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CENTRAL_SEGMENT_FRAGMENT}
`

export const ALL_CENTRAL_GAME_INSTANCES_QUERY = gql`
  query AllCentralGameInstancesQuery {
    allSegments {
      id
      landingUrl
      baseUrl
      shouldPromoteToPlay
      isInComingSoon
      hasStarted
      hasEnded
      subsection
      sportType
      analyticsChannel
      gameType
      seasonType
      poolSettings {
        ... on PoolSettings {
          id
          multipleEntriesOption
        }
      }
      season {
        id
        season
        year
        masterProductId
        challengePoolSlug
        challengePoolId
        requireCbsAccountBeforeEntry
        productAbbrev
        isArchived
        isCurrent
        currentSimulatedState {
          id
        }
        gameInstance {
          id
          uid
        }
      }
      tournaments {
        id
        cbsId
        name
        abbrev
      }
      season {
        gameInstance {
          id
          uid
          client
          poolType
        }
      }
    }
    gameInstances {
      edges {
        node {
          client
          poolType
          id
          uid
          customCopy
          currentPeriod {
            id
            description
            order
            isCurrent
            isPickable
            locksAt
            startsAt
            notificationBannerHtml
            notificationBannerBackgroundColor
            notificationBannerColor
            globalNotificationHtml
            globalNotificationBackgroundColor
            globalNotificationColor
            segment {
              id
              season {
                id
                season
                year
                productAbbrev
                challengePool {
                  poolSettings {
                    ... on PoolSettings {
                      maxEntriesPerUser
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const CENTRAL_POOL_DETAILS_FRAGMENT = gql`
  fragment CentralPoolDetailsFragment on Pool {
    id
    name
    slogan
    url
    password
    poolSettings {
      ...PoolSettingsFragment
    }
    gameInstanceUid
    lastScoredAt
    leaderTotalFantasyPoints
    canUseTeamLogos
    currentSeasonPoolId
    currentSeasonPoolUrl
    parentPoolId
    entriesCount
    membersCount
    isPublic
    usesMagicLink
    avatarUrl
    isInlineImage
    canAddEntry
    inviteUrl
    season {
      ...CentralSeasonFragment
    }
    hasSeenManagerWelcomeBackModal
    inviteAllEmailSent
    helpDeskUrl
    canViewEarly
    hasSeenNewSettingPopover
  }
  ${CENTRAL_SEASON_FRAGMENT}
  ${POOL_SETTINGS_FRAGMENT}
`

export const CENTRAL_POOL_DETAILS_QUERY = gql`
  query CentralPoolDetailsQuery($poolId: ID!) {
    pool(id: $poolId) {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`

export const POOL_BY_SLUG_QUERY = gql`
  query PoolBySlug($slug: String!) {
    pool: poolBySlug(slug: $slug) {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`

export const CENTRAL_ENTRY_FRAGMENT = gql`
  fragment CentralEntryFragment on Entry {
    id
    name
    email
    hideEmail
    avatarUrl
    gameInstanceUid
    needsToMakePicks
    unreadMessageIds
    hasMadeAPick
    userId
    url
    roles
    poolPassword
    hidePicksOnboarding
    totalPicksCount
    poolRank
    fantasyPoints
    maxPoints
    reinvitedPassword
    currentSeasonEntryId
    hasAutogeneratedName
    createdAt
    championTeam {
      id
      cbsTeamId
      sportType
      abbrev
    }
    isVisible
    maxPicksCount
    canDeleteEntry
    canLeavePool
    periodPoint {
      id
      poolRank
      fantasyPoints
    }
    pool {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`

export const CENTRAL_CURRENT_USERS_ENTRIES_FRAGMENT = gql`
fragment CentralCurrentUsersEntriesFragment on User {
  id
  picksDbId
  custId
  hasCbsAccount
  hasFantasyUser
  authenticationMessage
  preferredEntryName
  email
  avatarUrl
  isCbsAdmin
  firstName
  lastName
  entries(first: 200, gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        ...CentralEntryFragment
      }
    }
  }
  ${CENTRAL_ENTRY_FRAGMENT}
}
`

export const CENTRAL_CURRENT_USERS_PENDING_INVITATIONS_QUERY = gql`
  query CurrentUserPendingInvites($gameInstanceUid: String!) {
    pendingInvitations(gameInstanceUid: $gameInstanceUid) {
      pool {
        id
        name
        entriesCount
        membersCount
        slogan
        avatarUrl
        url
        usesMagicLink
        inviteUrl
        poolSettings {
          ...PoolSettingsFragment
        }
      }
      subPool {
        id
        poolId
        name
        entriesCount
        url
      }
      pendingInvitationId
      invitedBy
    }
    ${POOL_SETTINGS_FRAGMENT}
  }
`
export const CENTRAL_CURRENT_USERS_ENTRIES_QUERY = gql`
  query CentralCurrentUsersEntriesQuery(
    $gameType: GameGameTypeEnumType!
    $sportTypes: [GameSportTypeEnumType!]!
    $subsection: GameSubsectionEnumType
  ) {
    geoInfo {
      id
      countryCode
      region
    }
    gamblingPartnerInfo {
      id
      isPromoAvailable
      disclaimer
      placements {
        id
        name
        impressionUrl
        linkUrl
      }
    }
    canSeeDebugInfo
    isInDebugMode
    isCbsAppWebview
    isIosBrowser
    isAndroidBrowser
    currentUser {
      ...CentralCurrentUsersEntriesFragment
    }
  }
  ${CENTRAL_CURRENT_USERS_ENTRIES_FRAGMENT}
`

export const ALL_CENTRAL_CURRENT_USERS_ENTRIES_QUERY = gql`
  query AllCentralCurrentUsersEntriesQuery(
    $gameType: GameGameTypeEnumType
    $sportTypes: [GameSportTypeEnumType!]
    $subsection: GameSubsectionEnumType
  ) {
    geoInfo {
      id
      countryCode
      region
    }
    gamblingPartnerInfo {
      id
      isPromoAvailable
      disclaimer
      placements {
        id
        name
        impressionUrl
        linkUrl
      }
    }
    canSeeDebugInfo
    isInDebugMode
    isCbsAppWebview
    isIosBrowser
    isAndroidBrowser
    currentUser {
      ...CentralCurrentUsersEntriesFragment
    }
  }
  ${CENTRAL_CURRENT_USERS_ENTRIES_FRAGMENT}
`

export const ASSET_SRC_QUERY = gql`
  query AssetSrcQuery($fullPath: String!) {
    asset(fullPath: $fullPath) {
      id
      src
      contentType
    }
  }
`

export const CENTRAL_CHALLENGE_ENTRY_LOOKUP_QUERY = gql`
  query CentralChallengeEntryLookupQuery($entryId: ID!) {
    entry(id: $entryId) {
      ...CentralEntryFragment
    }
  }
  ${CENTRAL_ENTRY_FRAGMENT}
`
