import styled from "styled-components/macro"
import {
  altActiveBgFor,
  altHoverBgFor,
  themeBtnBgColor,
  themeBtnBorderColor,
  themeBtnHighlightBgColor,
  themeBtnHighlightBorderColor,
} from "../../common/theme-helpers"
import { fontWeight, palette, pxToRem } from "../utils/style-utils"

// interface IButtonBase {
//   padding: number;
//   width: number;
// }
// padding: ${(({padding}) => pxToRem(padding || 20))} ${(({padding}) => pxToRem((padding || 32) * 1.6))};
// width: ${(({width}) => pxToRem(width || 280))};

const buttonBaseW = 343
const buttonSmallBaseW = 115

export const ButtonBaseBase = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(2)};
  border: ${pxToRem(1)} solid ${themeBtnBorderColor};
  padding: ${pxToRem(buttonBaseW / 22)} ${pxToRem(buttonBaseW / 8.75)};
  width: ${pxToRem(buttonBaseW)};
  min-height: ${pxToRem(buttonBaseW / 8.575)};
  max-width: 80%;
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.semiBold};
  transition: all 0.2s ease-out;
  &:not(.is-form-spinner) > .spinner {
    /* give spinner no padding */
    margin: -0.6em 0;
    height: 2em;
    width: 2em;
  }
`
// NOTE qac: fu safari: https://stackoverflow.com/a/44756423

// &:disabled {
//   border-color: ${themeBtnBorderColor}9e;
//   background-color: ${themeBtnBgColor}9e;
// }
export const ButtonBase = styled(ButtonBaseBase)`
  background-color: ${themeBtnBgColor};
  color: ${palette.white};
  &:active {
    border-color: ${themeBtnHighlightBorderColor};
    background-color: ${themeBtnHighlightBgColor};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      border-color: ${themeBtnHighlightBorderColor};
      background-color: ${themeBtnHighlightBgColor};
    }
  }
`

export const ButtonBaseAlt = styled(ButtonBaseBase)`
  background-color: ${themeBtnBgColor};
  color: currentColor;
  border-color: ${palette.gray3};
  &:disabled {
    border-color: ${palette.gray3};
  }
  &:active {
    background-color: ${altActiveBgFor};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: ${altHoverBgFor};
    }
  }
`
export const ButtonSmallBase = styled(ButtonBase)`
  padding: ${pxToRem(buttonSmallBaseW / 14)} ${pxToRem(buttonSmallBaseW / 8)};
  min-width: ${pxToRem(buttonSmallBaseW)};
  width: auto;
  flex: 0 1 auto;
  min-height: ${pxToRem(buttonSmallBaseW / 3.5)};
  font-size: ${pxToRem(12)};
`
export const ButtonSmallBaseAlt = styled(ButtonBaseAlt)`
  padding: ${pxToRem(buttonSmallBaseW / 14)} ${pxToRem(buttonSmallBaseW / 8)};
  min-width: ${pxToRem(buttonSmallBaseW)};
  width: auto;
  flex: 0 1 auto;
  min-height: ${pxToRem(buttonSmallBaseW / 3.5)};
  font-size: ${pxToRem(12)};

  &.is-transparent--true {
    background-color: transparent;
  }
`

export const LinkButton = styled.button`
  transition: all 0.2s ease-out;
  color: ${palette.blue1};
  will-change: background-color, color;
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.semiBold};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${palette.blue2};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: rgba(0, 0, 0, 0.02);
      color: ${palette.blue2};
    }
  }
`

export const IconButton = styled.button`
  transition: all 0.2s ease-in-out;
  color: currentColor;
  display: flex;
  flex-flow: column;
  align-items: center;
  will-change: opacity;
  padding: 0.5em;
  & svg {
    width: 1.5em;
    height: 1.5em;
    margin-bottom: 0.25em;
  }
  &.is-inline--true {
    flex-flow: row nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: ${pxToRem(40)};
    padding-right: 0.25em;
    & > * {
      margin-right: 0.25em;
    }
    & svg {
      margin-bottom: 0;
    }
  }
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.7;
  }
`
export const OpacityBtn = styled.button`
  transition: all 0.2s ease-in-out;
  color: currentColor;
  will-change: opacity;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (hover: hover) {
    &:hover:not(:active) {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.7;
  }
`

export const ButtonWithAfterContentInteraction = styled.button`
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s ease-out;
  }
  &.is-light-bg--true:after {
    background: rgba(0, 0, 0, 0.08);
  }
  @media (hover: hover) {
    &:hover:not(:active):after {
      opacity: 0.75;
    }
  }
  &:active:after {
    opacity: 1;
  }
`

export default ButtonBaseBase
