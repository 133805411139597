import { useQuery } from "@apollo/client"
import React from "react"
import { GetLocalState } from "../../__generated__/GetLocalState"
import { GET_LOCAL_STATE_QUERY, getStateFromQuery } from "../utils/local-state"
import { TDeviceType } from "../utils/style-utils"

export interface IInjectedIDeviceTypeProps {
  deviceType: TDeviceType
}
export interface IMakeDeviceTypeProps {
  children: (props: IInjectedIDeviceTypeProps) => React.ReactNode
}

export function useDeviceType() {
  const queryProps = useQuery<GetLocalState>(GET_LOCAL_STATE_QUERY)
  const state = getStateFromQuery(queryProps)
  return state.deviceType
}

const DeviceTypeComponent = ({ children }) => {
  const deviceType = useDeviceType()
  return children({ deviceType })
}
export default DeviceTypeComponent
