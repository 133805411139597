import React from "react"
import { Logo } from "../../../components/Logos"
import { IAuthGateViewLogo } from "../../NonPoolPages/AuthGateTypings.d"
import { LogoStyled, Spacer } from "../styles/CommonPoolSetup.styles"

export const AuthGateViewLogo = ({ as, className, ref, sponsor, ...rest }: IAuthGateViewLogo) => {
  return (
    <LogoStyled as={as} className={sponsor ? `${className} with-sponsor` : className} ref={ref}>
      <Logo {...rest} />
      {sponsor ? <Spacer /> : undefined}
      {sponsor}
    </LogoStyled>
  )
}
export default AuthGateViewLogo
