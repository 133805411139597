import * as React from "react"
// import invariant from "invariant"
import warning from "warning"
import { CentralSeasonFragment } from "../../__generated__/CentralSeasonFragment"
import { CentralSegmentFragment } from "../../__generated__/CentralSegmentFragment"
// import { CentralSegmentFragment } from "../../__generated__/CentralSegmentFragment";
// import SoloLogo from "./icons/Solo";
import groupImg from "../assets/Group.png"
import soloImg from "../assets/Solo.png"
import NcaabBracketGames from "./icons/BracketGames"
import DefaultLogo from "./icons/Circle"
import NcaabConfBracketGames from "./icons/NcaabConfBracketGames"
import NcaafBowlPickemLogo from "./icons/NcaafBowlPickem"
import NcaafPickemLogo from "./icons/NcaafPickem"
import NflParlayLogo from "./icons/NflParlay"
import NflPickemLogo from "./icons/NflPickem"
import NflPickemPlayoffLogo from "./icons/NflPickemPlayoff"
import InlineAsset from "./InlineAsset"
// import NflPickSixLogo from "./icons/PickSix";

export interface ILogoBaseProps {
  gameInstanceUid: string
  segment?: CentralSegmentFragment
  season?: CentralSeasonFragment
  id?: string
}

export interface ILogo extends ILogoBaseProps {
  isChoicePage?: boolean
  className?: string
  style?: any
}

function SoloLogo(props) {
  return <img src={soloImg} loading="lazy" alt="Play solo" {...props} />
}
function NotSoloLogo(props) {
  return <img src={groupImg} loading="lazy" alt="Play in a group" {...props} />
}

const GenericLogosForChoice = {
  "cbs-ncaaf-pickem-challenge": SoloLogo,
  "cbs-ncaaf-pickem-manager": NotSoloLogo,
}

const Logos = {
  "cbs-football-pickem-manager": NflPickemLogo,
  "cbs-nfl-survivor-manager": NflPickemLogo,
  "cbs-nfl-pickem-challenge": NflPickemLogo, // NflPickemPlayoffLogo, - this is only for post
  "cbs-nfl-pickem-playoff-challenge": NflPickemPlayoffLogo,
  "cbs-nfl-pickem-manager": NflPickemPlayoffLogo,
  "cbs-nfl-pickem": NflPickemLogo,
  "cbs-ncaab-confbracket": NcaabConfBracketGames,
  "cbs-ncaaf-pickem-challenge": NcaafPickemLogo,
  "cbs-ncaaf-pickem-manager": NcaafPickemLogo,
  popc: NflPickemPlayoffLogo,
  popm: NflPickemPlayoffLogo,
  cnfbpc: NcaabConfBracketGames,
  cnfbpm: NcaabConfBracketGames,
  ffbopc: NcaafPickemLogo,
  ffbopm: NcaafPickemLogo,
  opm: NflPickemLogo,
  opc: NflPickemLogo,
  plopc: NflParlayLogo,
  plopm: NflParlayLogo,
  "cbs-nfl-parlay-challenge": NflParlayLogo,
  "cbs-nfl-parlay": NflParlayLogo,
  "cbs-ncaaf-pickem": NcaafPickemLogo,
  ffbpopc: NcaafBowlPickemLogo,
  ffbpopm: NcaafBowlPickemLogo,
  bpc: NcaabBracketGames,
  // wbpc: NcaabBracketGames,
  // wlnbpc: NcaabBracketGames,
  bpm: NcaabBracketGames,
  // wbpm: NcaabBracketGames,
  // wlnwbpc: NcaabBracketGames,
  default: DefaultLogo,
}

export const Logo = React.memo((props: ILogo) => {
  const { isChoicePage = false, segment, season, gameInstanceUid, ...rest } = props
  const ssn = (segment && segment.season) || season
  if (!isChoicePage && ssn?.svgLogoFullPath) {
    return <InlineAsset {...rest} fullPath={ssn.svgLogoFullPath} isUrl />
  }
  let LogoEl = (ssn?.productAbbrev && Logos[ssn?.productAbbrev]) || Logos[gameInstanceUid]
  if (isChoicePage && GenericLogosForChoice[gameInstanceUid]) {
    LogoEl = GenericLogosForChoice[gameInstanceUid]
  }
  warning(!!LogoEl, `unknown gameInstanceUid/prodAbbrev: ${ssn?.productAbbrev || gameInstanceUid}`)
  if (!LogoEl) {
    LogoEl = DefaultLogo
  }
  return <LogoEl {...rest} />
})

export default Logos
