import React, { useCallback, useContext } from "react"
import { Prompt } from "react-router-dom"
import { Location } from "history"
import { ManagerModeToastContainer } from "../styles/Layout.styles"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import { canUseDom } from "../../../utils/misc-utils"
import constants from "../../../../common/constants"

interface IManagerModeToastProps {
  location: Location<any>
  poolId: string
}

export const ManagerModeToast = ({ location, poolId }: IManagerModeToastProps) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { toggleManagerMode } = poolDataContext || {}

  const turnOffManagerMode = useCallback(
    (isNavigating = false) => {
      if (typeof toggleManagerMode === "function") {
        toggleManagerMode(false, () => {
          if (canUseDom && !isNavigating) {
            window.location.reload()
          }
        })
      }
    },
    [poolId, toggleManagerMode],
  )
  const handleNavigation = useCallback(
    (newLocation: Location) => {
      if (newLocation.pathname !== location.pathname) {
        turnOffManagerMode(true)
        if (canUseDom) {
          const searchParams = new URL(window.location.href).searchParams
          searchParams.delete("entryId")
          const newURL = `${constants.DOMAIN}${newLocation.pathname}${
            [...new Set(searchParams.keys())].length > 0 ? `?${searchParams.toString()}` : ""
          }`
          window.location.href = newURL
        }
      }
      return true
    },
    [turnOffManagerMode, location.pathname, location.search],
  )

  return (
    <ManagerModeToastContainer>
      <Prompt message={handleNavigation} />
      <div>You are currently in Manager Mode</div>
      <Button type="button" onClick={() => turnOffManagerMode()}>
        Turn Off
      </Button>
    </ManagerModeToastContainer>
  )
}

export default ManagerModeToast
