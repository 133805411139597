import * as React from "react"

const TricoFullSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 588.51 242.31">
    <path
      fill="#231f20"
      d="M366.26,302.17H397.7v-122H366.26ZM107,204.89h41v97.28h31.45V204.89h41V180.14H107Zm245.41,15.87v-.35c0-11.51-4.29-21.09-12.25-27.89-9.39-8-23.48-12.38-41.65-12.38H233.14v122h31.45v-97.8h31.24c15.31,0,24.71,5.93,24.71,17.43v.35c0,10.29-8.78,17.26-24.1,17.26H270.57l50.94,62.76h35.17l-34.92-43.59c18.18-5.75,30.63-18.12,30.63-37.82m257-42.71c-44.11,0-76.17,28.41-76.17,63.1v.35c0,34.69,31.65,62.76,75.76,62.76s76.16-28.42,76.16-63.11v-.34c0-34.7-31.65-62.76-75.75-62.76m42.88,63.45c0,20.92-17.56,38-42.88,38s-43.29-17.43-43.29-38.35v-.34c0-20.93,17.56-38,42.88-38s43.29,17.43,43.29,38.35Zm-169.2,38c-24.5,0-41.45-17.43-41.45-38.35v-.34c0-20.93,17.36-38,41.45-38,14.24,0,25.45,5.19,36.43,13.68l17.57-21.69c-12.8-10-28.55-16.74-53.79-16.74-43.91,0-74.53,28.41-74.53,63.1v.35c0,35,31.23,62.76,73.3,62.76,24.74,0,40.43-6.74,54-17.83L519.6,266c-10.8,8.23-20.68,13.46-36.49,13.46"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#231f20"
      d="M676.64,190.56a9.43,9.43,0,1,1,9.45,9.33,9.28,9.28,0,0,1-9.45-9.33m9.45,7.78a7.75,7.75,0,1,0-7.58-7.78,7.51,7.51,0,0,0,7.58,7.78m-2-2.39H682.5V185.22h4.09c2.53,0,3.78.94,3.78,3.06a2.79,2.79,0,0,1-2.78,2.95l3.06,4.72h-1.83L686,191.3h-1.87Zm2-6c1.38,0,2.61-.09,2.61-1.74,0-1.33-1.21-1.58-2.34-1.58h-2.22v3.32Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M187.32,341.39v3.87h-8.49v26.59h-4.49V345.26h-8.49v-3.87Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M196.18,341.39V354h13.69v-12.6h4.37v30.46h-4.37V357.94H196.18v13.91h-4.37V341.39Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M240.41,341.39v3.78H226V354h10.29v3.74H226v10.16h15v3.91H221.67V341.39Z" transform="translate(-106.98 -178.05)" />
    <path fill="#231f20" d="M276.86,341.39v3.78H262.75V354h10.54v3.83H262.75v14h-4.37V341.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M285.55,341.39v19.28c0,5.38,1.81,7.86,6.89,7.86s6.85-2.52,6.85-7.86V341.39h4.33V361.6c0,7.06-3.95,10.75-11.43,10.75-7.22,0-11-3.82-11-11.21V341.39Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M329.49,341.39v3.87H321v26.59h-4.49V345.26H308v-3.87Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M338.3,341.39v19.28c0,5.38,1.81,7.86,6.89,7.86S352,366,352,360.67V341.39h4.33V361.6c0,7.06-3.95,10.75-11.43,10.75-7.22,0-11-3.82-11-11.21V341.39Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#231f20"
      d="M373,341.39c8,0,11.84,2.57,11.84,8.53,0,4-2,6.64-6,7.77l7,14.16H381l-6.34-13.15H368v13.15h-4.33V341.39ZM368,355h5c5,0,7.35-1.47,7.35-4.91,0-3.7-2.27-5-7.27-5H368Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M410,341.39v3.78H395.59V354h10.29v3.74H395.59v10.16h15v3.91H391.22V341.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M450.53,356.64c0,9.79-4.87,15.71-12.31,15.71s-12.3-5.92-12.3-15.71S430.75,341,438.22,341,450.53,346.81,450.53,356.64Zm-20.12,0c0,7.65,2.9,11.81,7.81,11.81s7.82-4.21,7.82-11.81-2.9-11.8-7.82-11.8S430.41,349,430.41,356.64Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M474.34,341.39v3.78H460.23V354h10.54v3.83H460.23v14h-4.37V341.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M492.57,341.39l4.62,17.31c.5,1.93,1,4,1.39,6.17l.16.05a61.37,61.37,0,0,1,1.47-6.14l4.71-17.39h3.91l4.66,17.31c.55,2,1,4.07,1.34,6.17l.21.05c.38-2,.84-4,1.43-6.14l4.66-17.39h4.37l-8.4,30.46h-4l-4.7-17.1c-.63-2.27-1.14-4.41-1.56-6.43-.75,3.41-1.26,5.51-1.51,6.39l-4.75,17.14h-4l-8.4-30.46Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M534.61,341.39v30.46h-4.37V341.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M552.17,341.39c7.14,0,10.63,2.78,10.63,8.66,0,5.62-3.49,8.78-9.88,8.78h-6.13v13h-4.37V341.39Zm-5.38,13.7h4.79c4.66,0,6.76-1.22,6.76-5.09,0-3.69-2.14-4.91-6.89-4.91h-4.66Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#231f20" d="M586.9,341.39v3.78H572.54V354h10.29v3.74H572.54v10.16h15v3.91H568.17V341.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#231f20"
      d="M601.73,341.39c8,0,11.84,2.57,11.84,8.53,0,4-2.05,6.64-6,7.77l7,14.16h-4.79l-6.34-13.15h-6.68v13.15H592.4V341.39Zm-5,13.57h5c5,0,7.35-1.47,7.35-4.91,0-3.7-2.27-5-7.27-5h-5.12Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#231f20"
      d="M633.56,349.37c-1.13-3.15-3.27-4.7-6.21-4.7-2.65,0-4.37,1.59-4.37,3.78,0,2.73,1.89,4,5.71,5.63,2.4,1,4.71,1.8,6.51,3.32a7.68,7.68,0,0,1,3,6.26c0,5.08-3.82,8.65-9.78,8.65-5.42,0-9.29-2.73-11-7.77l4.08-1.6c1.3,3.61,3.82,5.59,7,5.59s5.3-2,5.3-4.75c0-3-2.48-4.11-7-6-5.5-2.27-8.36-4.37-8.36-8.74,0-4.58,3.7-8.11,9.28-8.11,4.92,0,8.41,2.52,9.79,7.1Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#ee3124"
      d="M305.42,397.37c-1.13-3.15-3.27-4.7-6.21-4.7-2.65,0-4.37,1.59-4.37,3.78,0,2.73,1.89,4,5.71,5.63,2.39,1,4.7,1.8,6.51,3.32a7.68,7.68,0,0,1,3,6.26c0,5.08-3.82,8.65-9.78,8.65-5.42,0-9.29-2.73-11-7.77l4.07-1.6c1.31,3.61,3.83,5.59,7.06,5.59s5.29-2,5.29-4.75c0-3-2.47-4.11-7-6-5.5-2.27-8.36-4.37-8.36-8.74,0-4.58,3.7-8.11,9.28-8.11,4.92,0,8.4,2.52,9.79,7.1Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#ee3124" d="M320.25,389.39v30.46h-4.37V389.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#ee3124"
      d="M333.39,389.39l9.71,16.72c1.17,2.06,2.35,4.24,3.48,6.55-.12-1.76-.17-3.82-.17-6.09V389.39h4.12v30.46h-3.82l-11-18.23c-1.1-1.81-2.36-4.12-3.79-7,.13,2,.17,4.12.17,6.47v18.74H328V389.39Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#ee3124"
      d="M374,398.55c-.89-3.91-3.07-5.88-6.47-5.88-4.5,0-7.23,4.16-7.23,12.05s2.69,11.77,7.52,11.77c3.45,0,6-2.19,6.76-6.14l4,1c-1.56,5.93-5.21,9-10.93,9-7.6,0-11.84-5.42-11.84-15.67,0-10,4.54-15.71,11.84-15.71,5.59,0,9.12,2.94,10.38,8.57Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#ee3124" d="M402.06,389.39v3.78H387.7V402H398v3.74H387.7v10.16h15v3.91H383.33V389.39Z" transform="translate(-106.98 -178.05)" />
    <path
      fill="#ee3124"
      d="M431.13,389v27.48H436v3.4H422.14v-3.4h4.95V393.51l-5.46.46v-2.73a30.22,30.22,0,0,0,6.6-2.27Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#ee3124"
      d="M446.45,415.06a9.7,9.7,0,0,0,5.38,1.68c4.75,0,8.07-4.12,8.23-11.17a10.1,10.1,0,0,1-7.31,3.19c-5.33,0-9.28-4-9.28-9.75,0-6,4.16-10,10.13-10,6.93,0,10.75,4.88,10.75,14.12,0,10.84-5,17.22-12.52,17.22a12.11,12.11,0,0,1-7.14-2Zm7.15-22.52c-3.41,0-6,2.61-6,6.43s2.44,6.39,5.92,6.39a7.28,7.28,0,0,0,6.6-4.88C459.94,395.44,457.33,392.54,453.6,392.54Z"
      transform="translate(-106.98 -178.05)"
    />
    <path
      fill="#ee3124"
      d="M481.44,389v27.48h4.87v3.4H472.45v-3.4h5V393.51L472,394v-2.73a30.12,30.12,0,0,0,6.59-2.27Z"
      transform="translate(-106.98 -178.05)"
    />
    <path fill="#ee3124" d="M514.2,389.43v2.65l-12.56,28.15h-4.53l12.35-27h-15v-3.78Z" transform="translate(-106.98 -178.05)" />
  </svg>
)

export default TricoFullSvg
