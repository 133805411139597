import { useContext } from "react"
import { useQuery } from "@apollo/client"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"
import { NEWSLETTER_SUBSCRIPTIONS_QUERY } from "../App/queries"
import {
  NewsletterSubscriptions,
  NewsletterSubscriptionsVariables,
  NewsletterSubscriptions_newsletterSubscriptions_options,
} from "../../__generated__/NewsletterSubscriptions"
import { NewsletterSites_newsletter247Sites } from "../../__generated__/NewsletterSites"
import { emptyArray, emptyObject } from "../../common/misc-utils"
import constants from "../../common/constants"

export interface NewsletterSubscription {
  sportType: string
  options: Array<NewsletterSubscriptionOption>
  sites: Array<NewsletterSites_newsletter247Sites>
  title: string | null
  gameInstanceUid: string | null
}

export interface NewsletterSubscriptionOption {
  id: string
  mCode: string
  text: string
}

export type Newsletter247SiteType = NewsletterSites_newsletter247Sites

const DISPLAY_START_TIMESTAMP = 1710475200000 // Fri Mar 15 2024 00:00:00 GMT-0400 (Eastern Daylight Time)
const DISPLAY_END_TIMESTAMP = 1711080000000 // Fri Mar 22 2024 00:00:00 GMT-0400 (Eastern Daylight Time)

// For Kos to test in DEV
const TEST_DISPLAY_START_TIMESTAMP = 1704949200000 // Thu Jan 11 2024 00:00:00 GMT-0500 (Eastern Standard Time)
const TEST_DISPLAY_END_TIMESTAMP = 1705120200000 // Fri Jan 12 2024 23:30:00 GMT-0500 (Eastern Standard Time)

const EMPTY_NEWSLETTER_SUBSCRIPTION = {
  title: "",
  gameInstanceUid: "",
  sportType: "",
  options: [],
  sites: [],
  showModal: false,
  sportYear: new Date().getFullYear(),
}

const toNewsletterSubscriptionOption: (obj: NewsletterSubscriptions_newsletterSubscriptions_options) => NewsletterSubscriptionOption = ({
  id,
  mCode,
  text,
}: NewsletterSubscriptions_newsletterSubscriptions_options) => ({
  id,
  mCode,
  text,
})

const useNewsletterSubscriptionOptions: () => NewsletterSubscription & { showModal: boolean; sportYear: number } = () => {
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const sportYear = poolData?.detailedPeriod?.segment.season.year ?? new Date().getFullYear()
  const gameInstanceUid = poolData?.gameInstanceUid ?? null
  const sportType = poolData?.currentSportTypes?.[0] ?? ""
  const variables = {
    sportYear,
    gameInstanceUid,
    sportType,
  }
  const skip = !(variables.gameInstanceUid || variables.sportType)

  /**
   * CBS Newsletter options
   */
  const { data } = useQuery<NewsletterSubscriptions, NewsletterSubscriptionsVariables>(NEWSLETTER_SUBSCRIPTIONS_QUERY, {
    variables,
    skip,
  })
  const { options, title } = data?.newsletterSubscriptions ?? emptyObject
  const newsletterOptions = options?.map(toNewsletterSubscriptionOption) ?? emptyArray
  const hasNewsletterOptions = newsletterOptions.length > 0

  const currentDateTimestamp = Date.now()

  // TODO: remove test condition after it passes QA
  const withinDisplayTimeframe = constants.IS_PROD
    ? DISPLAY_START_TIMESTAMP < currentDateTimestamp && currentDateTimestamp < DISPLAY_END_TIMESTAMP
    : TEST_DISPLAY_START_TIMESTAMP < currentDateTimestamp && currentDateTimestamp < TEST_DISPLAY_END_TIMESTAMP

  /**
   * 247 Newsletter sites
   */
  const newsletterSites = data?.newsletter247Sites ?? emptyArray
  const hasSites = newsletterSites.length > 0

  if (!hasNewsletterOptions && !hasSites) {
    return EMPTY_NEWSLETTER_SUBSCRIPTION
  }

  return {
    title,
    gameInstanceUid,
    sportType,
    sportYear,
    options: newsletterOptions,
    sites: newsletterSites,
    showModal: data?.currentUser?.hasSeenNewsletterSubscriptionModal === false && withinDisplayTimeframe,
  }
}

export default useNewsletterSubscriptionOptions
