/* eslint-disable react-hooks/rules-of-hooks */
import { useSubscription, useQuery } from "@apollo/client"
import { CentralChallengeEntryLookupQuery, CentralChallengeEntryLookupQueryVariables } from "../../__generated__/CentralChallengeEntryLookupQuery"
import { CentralCurrentUsersEntriesQuery, CentralCurrentUsersEntriesQueryVariables } from "../../__generated__/CentralCurrentUsersEntriesQuery"
import {
  CentralGameInstancesQuery,
  CentralGameInstancesQueryVariables,
  CentralGameInstancesQuery_allSegments,
} from "../../__generated__/CentralGameInstancesQuery"
import { CentralPoolDetailsFragment } from "../../__generated__/CentralPoolDetailsFragment"
import { CentralPoolDetailsQuery, CentralPoolDetailsQueryVariables } from "../../__generated__/CentralPoolDetailsQuery"
import { CentralSeasonFragment } from "../../__generated__/CentralSeasonFragment"
import { CentralSegmentFragment } from "../../__generated__/CentralSegmentFragment"
import { CentralTeamsQuery, CentralTeamsQueryVariables } from "../../__generated__/CentralTeamsQuery"
import { EntryDetailsQuery, EntryDetailsQueryVariables } from "../../__generated__/EntryDetailsQuery"
import { PoolPeriodQuery, PoolPeriodQueryVariables, PoolPeriodQuery_gameInstance_period } from "../../__generated__/PoolPeriodQuery"
import {
  PoolUsersEntriesQuery,
  PoolUsersEntriesQuery_pool_entries_edges_node,
  PoolUsersEntriesQueryVariables,
} from "../../__generated__/PoolUsersEntriesQuery"
import { cacheKeyFor, isMarchMadnessProductAbbrevMatcher, isNCAAWTournamentMatcher } from "../../common/common-utils-helpers"
import {
  IBracketUtilMatchup,
  IPickUtilsEvent,
  IPickUtilsGameSettingsValues,
  IPickUtilsPeriod,
  IPickUtilsPicks,
  IPickUtilsTeam,
} from "../../common/common-utils-types.d"
import {
  ENUM_AVAILABLE,
  ENUM_BRACKET,
  ENUM_CHALLENGE,
  ENUM_MANAGER,
  ENUM_PARLAY,
  ENUM_PICKEM,
  ENUM_REGULAR,
  ClientsEnum,
  PoolSettingsTypesEnum,
} from "../../common/enums"
import { unique, oneMinute, emptyArray, oneSecond, emptyObject } from "../../common/misc-utils"
import { mapToId } from "../../common/misc-utils"
import PickUtils from "../../common/pick-utils"
import { IFullPoolSettings } from "../../common/pool-settings"
import { getSeasonNumericValue, sortFrontendSegments } from "../../common/sorters"
import { extractFeId, withoutDomain, poolCreateRegexp } from "../../common/url-utils"
import {
  IAuthGateFuncProps,
  IAuthGateFuncProps2,
  IEntryWithRoles,
  IGameOgProps,
  IGameRootRouteMatch,
  IMyEntriesForArea,
  IPoolData,
  IPoolDataSub,
  TPoolRouteRouterProps,
} from "../../routes.d"
import { IAuthGateProps } from "../App/NonPoolPages/AuthGateTypings"
import {
  CURRENT_MEMBERSHIP_QUERY,
  ENTRY_DETAILS_QUERY,
  EVENT_UPDATES_SUBSCRIPTION,
  POOL_PERIOD_QUERY,
  POOL_USERS_ENTRIES_QUERY,
} from "../App/queries"
import {
  CENTRAL_BRACKET_STATE_QUERY,
  CENTRAL_CHALLENGE_ENTRY_LOOKUP_QUERY,
  CENTRAL_CURRENT_USERS_ENTRIES_QUERY,
  CENTRAL_CURRENT_USERS_PENDING_INVITATIONS_QUERY,
  CENTRAL_GAME_INSTANCES_QUERY,
  CENTRAL_POOL_DETAILS_QUERY,
  CENTRAL_TEAMS_QUERY,
} from "../utils/central-queries"
import { canUseDom, edgeToNode, toNodes } from "../utils/misc-utils"
import { filterNulls, getCreatePoolParams, getParam, getStringParam } from "../utils/url-utils"
import { useDeviceType } from "../Base/DeviceType"
import { EventUpdatesSubscription, EventUpdatesSubscriptionVariables } from "../../__generated__/EventUpdatesSubscription"
import useUpsertEntryMutation from "./useUpsertEntryMutation"
import {
  CurrentUserPendingInvites,
  CurrentUserPendingInvitesVariables,
  CurrentUserPendingInvites_pendingInvitations,
} from "../../__generated__/CurrentUserPendingInvites"
import { sortEntries } from "../utils/data-utils"
import { CentralBracketsState } from "../../__generated__/CentralBracketsState"
import { NCAAB_S16_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { match, useLocation, useRouteMatch } from "react-router-dom"
import { CurrentMembershipQuery, CurrentMembershipQueryVariables } from "../../__generated__/CurrentMembershipQuery"
import { pascalCase } from "../../common/string-utils"
import { GameGameTypeEnumType, GameSportTypeEnumType, GameSubsectionEnumType } from "../../__generated__/globalTypes"
import constants, { managerModeEnabledCookieName, managerModeHeaderName } from "../../common/constants"
import { get as getCookie, set as setCookie, remove as removeCookie } from "es-cookie"

const defaultPollMs = 15 * oneMinute

const orderParam = `order`

export function getAuthGateProps(
  { isCbsAppWebview, gameInstanceUid, isSingleGameInstanceArea, productSeason: defaultProductSeason }: IAuthGateFuncProps,
  { pathname, search }: IAuthGateFuncProps2,
  overrideSegment?: CentralGameInstancesQuery_allSegments | null,
): IAuthGateProps {
  const productSeason = overrideSegment?.season || defaultProductSeason
  const onboardingFixSeasonSlug = isSingleGameInstanceArea && productSeason && productSeason.challengePoolSlug
  // const _poolRootParts = (poolDetail && poolDetail.url || "").split(gameInstanceRoot || "non");
  // const poolRootPathname = _poolRootParts.length > 1 && `${gameInstanceRoot}${_poolRootParts[1]}` || undefined;
  // const currentSearch = search ? `?${search.replace("?", "")}` : "";
  const searchParts = (search || "")
    .replace("?", "")
    .split("&")
    .filter((s) => !!s)
  const returnUrlSearch = searchParts.length ? `?${searchParts.join("&")}` : ""
  const productAbbrev = (productSeason && productSeason.productAbbrev) || ""
  const masterProductId = (productSeason && productSeason.masterProductId) || 0
  // NOTE qac: this is so the onboarding of parlay returns the user to tha challenge page after auth
  const authGateReturnUrlWithOnboardingFix =
    (onboardingFixSeasonSlug && !pathname.includes(onboardingFixSeasonSlug) && `${pathname}/${onboardingFixSeasonSlug}`) || pathname
  const authGateReturnUrl = authGateReturnUrlWithOnboardingFix + returnUrlSearch
  const returnUrl = authGateReturnUrl
  return {
    returnUrl,
    productAbbrev,
    masterProductId,
    gameInstanceUid,
    isCbsAppWebview,
    season: productSeason,
    productSeason,
    isSingleGameInstanceArea,
  } as IAuthGateProps
}

export type TGameDataVars = {
  sportTypes?: GameSportTypeEnumType[]
  gameType?: GameGameTypeEnumType
  subsection?: GameSubsectionEnumType
}

export const getGameDataVars = (props: { match: match<IGameRootRouteMatch> }) => {
  const { match } = props
  const params = match?.params || emptyObject

  let gameType: GameGameTypeEnumType | undefined = params.gameType ? (params.gameType.toUpperCase() as GameGameTypeEnumType) : undefined
  const validGameTypes: GameGameTypeEnumType[] = ["BRACKET", "PICKEM", "SURVIVOR", "PARLAY"]
  if (gameType && !validGameTypes.includes(gameType)) {
    gameType = undefined
  }
  let sportTypes: GameSportTypeEnumType[] | undefined
  if (params.sportType && constants.sportTypeCategories[params.sportType]) {
    sportTypes = constants.sportTypeCategories[params.sportType].sportTypes
  }

  let subsection: GameSubsectionEnumType | undefined = params.subsection ? (pascalCase(params.subsection) as GameSubsectionEnumType) : undefined
  const validSubsections: GameSubsectionEnumType[] = ["NcaaTournament", "NcaaTournamentSweetSixteen", "NcaawTournament"]
  if (subsection && !validSubsections.includes(subsection)) {
    subsection = undefined
  }

  return {
    sportTypes,
    gameType,
    subsection,
  }
}

export function entryHasManagerRole(entry?: IEntryWithRoles): boolean {
  const entryRoles = entry?.roles || emptyArray
  return entryRoles.indexOf(ENUM_MANAGER) > -1
}

const filterMarkedFinalAts = ({ markedFinalAt }) => markedFinalAt
const mapToEvent = ({ event }) => event
export function buildPollingProps(props: IPoolDataSub) {
  const { detailedPeriod, poolDetail, detailedEntry, segmentForArea } = props
  const poolSettings = extractPoolSettings(props)
  const isParlay = segmentForArea?.gameType === ENUM_PARLAY
  const isSingleOptionParlay = isParlay && poolSettings && poolSettings.minPicksPerPeriodCount === poolSettings.maxPicksPerPeriodCount
  const nowAt = Date.now()
  const soonToBeLiveAt = nowAt + defaultPollMs
  const pollingPeriodId = detailedPeriod?.id
  const events =
    (detailedPeriod &&
      (((detailedPeriod.events && toNodes(detailedPeriod.events)) ||
        detailedPeriod.matchups?.map(mapToEvent).filter(filterNulls)) as IPickUtilsEvent[])) ||
    emptyArray
  const eventsCacheKey = events.map(cacheKeyFor).join(":")
  const unstartedEvents = events.filter((evt) => evt.startsAt > nowAt)
  const startedEvents = events.filter((evt) => evt.startsAt <= nowAt)
  const startedEventIdsArr = startedEvents.map(mapToId)
  const soonToBeLiveEvents = events.filter((evt) => evt.startsAt <= soonToBeLiveAt)
  const soonToBeLiveUnfinishedEvents = soonToBeLiveEvents.filter((evt) => !evt.markedFinalAt)
  const soonToBeLiveUnfinishedEvent = (soonToBeLiveUnfinishedEvents.length && soonToBeLiveUnfinishedEvents[0]) || null
  const markedFinalAts = (events.map(filterMarkedFinalAts).filter(filterNulls) || emptyArray) as number[]
  const newestMarkedFinalAt = markedFinalAts.length ? Math.max(...markedFinalAts) : 0
  const lastScoredAt = (poolDetail && poolDetail.lastScoredAt) || 0
  const needsLiveEventPolling = !!soonToBeLiveUnfinishedEvent
  const needsPoolScoringPolling = !!poolDetail && !!newestMarkedFinalAt && lastScoredAt < newestMarkedFinalAt
  const hasStartedEvent = !!startedEvents.length
  const useWsLiveUpdates = constants.PUBLIC_FRONTEND_USES_WS
  const liveEventMs = !useWsLiveUpdates && needsLiveEventPolling ? 30 * oneSecond : defaultPollMs
  const isGettingLiveUpdates = needsLiveEventPolling || needsPoolScoringPolling
  const poolScoringMs = needsPoolScoringPolling ? 30 * oneSecond : defaultPollMs
  const hasFinaledGames = newestMarkedFinalAt > 0
  const hasSoonToBeLiveUnfinishedEvent = !!soonToBeLiveUnfinishedEvent
  const startedEventIds = startedEventIdsArr.join("|")
  const soonToBeLiveUnfinishedEventIds = soonToBeLiveUnfinishedEvents.map(mapToId).join("|")
  // const startedSoonEvent = events.find((evt) => evt.startsAt <= soonToBeLiveAt);
  const allParlaysLocked = !!poolSettings && unstartedEvents.length <= poolSettings.minPicksPerPeriodCount
  // TODO qac: make this a value
  // const hasLockedParlay = !!entry && !entry.needsToMakePicks && !!entry.picks && !!entry.picks.find(({slotId, itemId}) => itemId && startedEventIds.includes(slotId));
  const canViewOverallStandings = !isParlay || isSingleOptionParlay || events.length === markedFinalAts.length
  // const liveStandingsBlockText = (isParlay && !canViewParlay && entry && `Live leaderboard will be available once your parlay locks for the current week.`) ||
  //   (isParlay && !canViewParlay && !entry && `Live leaderboard will be available after all parlays lock for the week.`) ||
  //   (!isParlay && !startedSoonEvent && `No games started yet.`) ||
  //   null;
  // const {isParlay, startedEventIds, hasStartedEvent, allParlaysLocked} = pollingData;
  const parlayPicks = (detailedEntry && detailedEntry.picks) || emptyArray
  const hasLockedParlay = isParlay && !!parlayPicks.find(({ slotId, itemId }) => itemId && startedEventIds.includes(slotId))
  const canViewParlay = hasLockedParlay || allParlaysLocked
  const liveStandingsBlockText =
    (isParlay && !canViewParlay && detailedEntry && `Live leaderboard will be available once your parlay locks for the current week.`) ||
    (isParlay && !canViewParlay && !detailedEntry && `Live leaderboard will be available after all parlays lock for the week.`) ||
    (!isParlay && !hasStartedEvent && `Games haven't started and there are no live standings yet.`) ||
    undefined
  return {
    hasStartedEvent,
    hasFinaledGames,
    hasSoonToBeLiveUnfinishedEvent,
    soonToBeLiveUnfinishedEventIds,
    startedEventIds,
    allParlaysLocked,
    newestMarkedFinalAt,
    lastScoredAt,
    eventsCacheKey,
    defaultMs: defaultPollMs,
    liveEventMs,
    useWsLiveUpdates,
    isGettingLiveUpdates,
    poolScoringMs,
    canViewOverallStandings,
    pollingPeriodId,
    canViewParlay,
    liveStandingsBlockText,
  }
}
export function extractPoolOgProps({ productSeason, poolDetail, gameInstancesForArea, poolRoot, gameInstanceUid }: IPoolDataSub): IGameOgProps {
  return {
    gameInstanceUid: gameInstanceUid || "",
    seasonType: productSeason?.season || ENUM_REGULAR,
    year: productSeason?.year || 0,
    productAbbrev: productSeason?.productAbbrev || "",
    multiGameInstances: gameInstancesForArea.length > 1,
    poolName: poolDetail?.name || "",
    poolUrl: poolDetail?.url || poolRoot || "",
  }
}

export function gatherPoolSettings(props: IPickUtilsGameSettingsValues): IFullPoolSettings {
  const {
    gameWeightType,
    roundBonusType,
    roundBonuses,
    spreadType,
    picksDeadlineType,
    mainTiebreaker,
    secondaryTiebreaker,
    thirdTiebreaker,
    fourthTiebreaker,
    pickCountRangeOption,
    minPicksPerPeriodCount,
    maxPicksPerPeriodCount,
    tournamentIds,
    multipleEntriesOption,
    periodLength,
    maxEntriesPerUser,
    gamesPerPeriod,
    roundModifiers,
    includeChampionshipRound,
    includeMessageBoard,
    roundModifiersOption,
    openInvites,
  } = props
  return {
    gameWeightType,
    roundBonusType,
    roundBonuses,
    spreadType,
    picksDeadlineType,
    mainTiebreaker,
    secondaryTiebreaker,
    thirdTiebreaker,
    fourthTiebreaker,
    pickCountRangeOption,
    minPicksPerPeriodCount,
    maxPicksPerPeriodCount,
    tournamentIds,
    multipleEntriesOption,
    periodLength,
    maxEntriesPerUser,
    gamesPerPeriod,
    roundModifiers,
    roundModifiersOption,
    includeChampionshipRound,
    includeMessageBoard,
    openInvites,
    __typename: PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS,
  }
}

export function extractPoolSettings({ detailedPeriod, poolDetail, allSegments, segmentForArea }: IPoolDataSub): IPickUtilsGameSettingsValues | null {
  if (poolDetail) {
    return poolDetail.poolSettings as IPickUtilsGameSettingsValues
  }
  const segment = (detailedPeriod && allSegments.find((seg) => seg.id === detailedPeriod.segment.id)) || segmentForArea
  if (segment) {
    return segment.poolSettings as IPickUtilsGameSettingsValues
  }
  return null
}
export function buildPickUtils(
  poolData: IPoolData,
  currentPickUtils: PickUtils | null,
  picks: IPickUtilsPicks = emptyArray,
  extraCacheKey = "",
  explicitDetailedPeriod?: PoolPeriodQuery_gameInstance_period | null,
): PickUtils | null {
  // const pickUtilsRef = useRef<PickUtils | null>(null)
  const { centralTeamsQueryLoading, isManagerModeActive, gameInstanceUid } = poolData
  const detailedPeriod = explicitDetailedPeriod || poolData.detailedPeriod
  const centralTeams = (poolData.centralTeams as unknown) as IPickUtilsTeam[]
  if (!detailedPeriod || centralTeamsQueryLoading) {
    return null
  }

  const matchups = ((detailedPeriod.matchups as unknown) as IBracketUtilMatchup[]) || emptyArray
  const mergedPoolSettings = extractPoolSettings(poolData)
  if (!mergedPoolSettings) {
    return null
  }
  const events = (detailedPeriod.events?.edges.map(edgeToNode) as IPickUtilsEvent[]) || emptyArray
  const detailedPeriodForPickUtils = detailedPeriod as IPickUtilsPeriod
  return PickUtils.buildFor(
    picks,
    events,
    detailedPeriodForPickUtils,
    matchups,
    centralTeams,
    mergedPoolSettings,
    { extraCacheKey, overriding: isManagerModeActive },
    currentPickUtils,
    gameInstanceUid,
  )
}

// const getGameInstancesForParams = (gameInstanceEdges: T[], params: any): T[] => {
//   const gameInstanceEdges = centralGameInstancesQuery.data.gameInstances.edges.map(edgeToNode);
//   const gameInstances = gameInstanceEdges.filter(
//     (gi) => sportCategory.sportTypes.includes(gi.currentPeriod && gi.currentPeriod.segment.sportType) &&
//     !params.gameType || (gi.currentPeriod && gi.currentPeriod.segment.gameType) === params.gameType.toUpperCase()
//   );
// }

// const matchGameInstancesForParams = (segment: ISegmentParams, sportTypes: string[], params: IUrlParams) => (
//   sportTypes.includes(segment.sportType) &&
//   segment.gameType.toUpperCase() === (params.gameType || segment.gameType).toUpperCase() &&
//   (segment.subsection || "").toUpperCase() === (params.subsection || "").toUpperCase().replace("-", "")
// );

function usePoolData(props: TPoolRouteRouterProps): IPoolData {
  // ******** URL Params ******************************************************************************************************
  const { location, match } = props
  const params = match?.params || emptyObject
  const isPoolCreate = poolCreateRegexp.test(location.pathname)
  const variableParams = {
    entryId: extractFeId(params.entryId || getStringParam("entryId", location.search)),
    periodId: extractFeId(params.periodId || getStringParam("periodId", location.search)),
    poolId: extractFeId(params.poolId || getStringParam("poolId", location.search)),
    order: getParam(orderParam, location.search) as number | null,
    year: getParam("year", location.search) as number | null,
    seasonType: getParam("seasonType", location.search) as number | null,
  }

  const gameDataVariables = getGameDataVars(props)
  const prefetchParams = {
    poolId: null as null | string,
    periodId: getCreatePoolParams(location.pathname, location.search).poolId,
    entryId: null as null | string,
  }

  let { gameType, sportTypes } = gameDataVariables
  const subsection = gameDataVariables.subsection
  let hasGameDataVars = !!gameType && !!sportTypes
  const isInternalHomePage = props.match?.path === "/" || props.match?.path?.startsWith("/admin/")
  if (isInternalHomePage && !hasGameDataVars) {
    gameType = "PICKEM"
    sportTypes = ["NFL"]
    hasGameDataVars = true
  }

  let isManagerModeActiveForRequest = false
  if (canUseDom) {
    const managerModeEnabledCookie = getCookie(managerModeEnabledCookieName)
    isManagerModeActiveForRequest = managerModeEnabledCookie === "true"
  }

  // ********** Base User Data ****************************************************************************************************
  const centralCurrentUsersEntriesQuery = useQuery<CentralCurrentUsersEntriesQuery, CentralCurrentUsersEntriesQueryVariables>(
    CENTRAL_CURRENT_USERS_ENTRIES_QUERY,
    {
      skip: !hasGameDataVars,
      notifyOnNetworkStatusChange: true,
      variables: {
        gameType: gameType!,
        sportTypes: sportTypes!,
        subsection,
      },
      context: {
        headers: {
          [managerModeHeaderName]: isManagerModeActiveForRequest,
        },
      },
    },
  )

  const currentUser = centralCurrentUsersEntriesQuery.data?.currentUser || emptyObject

  const geoInfo = centralCurrentUsersEntriesQuery.data?.geoInfo
  const gamblingPartnerInfo = centralCurrentUsersEntriesQuery.data?.gamblingPartnerInfo
  const isCbsAppWebview = !!centralCurrentUsersEntriesQuery.data?.isCbsAppWebview
  const isIosBrowser = !!centralCurrentUsersEntriesQuery.data?.isIosBrowser
  const isAndroidBrowser = !!centralCurrentUsersEntriesQuery.data?.isAndroidBrowser
  const hasFantasyUser = !!currentUser.hasFantasyUser
  const hasCbsAccount = !!currentUser.hasCbsAccount
  const isCbsAdmin = !!currentUser.isCbsAdmin
  const allCurrentEntries = sortEntries((currentUser.entries?.edges || emptyArray).map(edgeToNode))
  const allCurrentEntryIds = allCurrentEntries.map((e) => e.id)

  // ********** Base Game Data ****************************************************************************************************
  const centralGameInstancesQuery = useQuery<CentralGameInstancesQuery, CentralGameInstancesQueryVariables>(CENTRAL_GAME_INSTANCES_QUERY, {
    skip: !hasGameDataVars,
    notifyOnNetworkStatusChange: true,
    variables: {
      gameType: gameType!,
      sportTypes: sportTypes!,
      subsection,
    },
  })

  const needsUserAndGameDataRoute = location.pathname !== "/" && !/\/auth|\/games\/roadblock|\/pool\//.test(location.pathname)

  const baseIsLoading =
    hasGameDataVars &&
    needsUserAndGameDataRoute &&
    (!(centralGameInstancesQuery.data && centralGameInstancesQuery.data.gameInstances) ||
      !(centralCurrentUsersEntriesQuery.data && centralCurrentUsersEntriesQuery.data.currentUser))
  const gameInstanceEdges = (centralGameInstancesQuery?.data?.gameInstances.edges || emptyArray).map(edgeToNode)
  const allGameInstances = gameInstanceEdges
  const allSegments = centralGameInstancesQuery?.data?.allSegments || emptyArray

  // ********** Segment, Season, and GameInstance for Area ****************************************************************************************************
  const segmentsForArea = allSegments.filter((seg) => {
    const isNCAABRoute = location.pathname.includes("ncaa-tournament")
    if (isNCAABRoute) {
      return location.pathname.replace(/-?sweet-sixteen-?/g, "").includes(withoutDomain(seg.baseUrl)) || seg.baseUrl.includes("-sweet-sixteen")
    }
    if (seg.season.productAbbrev === "opm") {
      return false
    }
    if (location.pathname.includes(`/football/pickem/challenge`) || location.pathname === `/football/pickem`) {
      return seg.season.productAbbrev === "opc"
    } else if (location.pathname.includes(`/football/pickem/playoff-challenge`)) {
      return seg.season.productAbbrev === "popc"
    } else {
      return location.pathname.includes(withoutDomain(seg.baseUrl))
    }
  })
  // NOTE qac: lets sort these so the first season is the most recent (as well as segments!)
  segmentsForArea.sort(sortFrontendSegments)
  const _segmentsForAreaWithCurrentSeason = segmentsForArea.filter((seg) => seg.season.isCurrent)
  const _segmentsForAreaWithCurrentSeasonAndLandingUrl = _segmentsForAreaWithCurrentSeason.filter((seg) =>
    location.pathname.includes(withoutDomain(seg.landingUrl)),
  )
  const _segmentForAreaWithCurrentSeasonAndLandingUrl = _segmentsForAreaWithCurrentSeasonAndLandingUrl.length
    ? _segmentsForAreaWithCurrentSeasonAndLandingUrl[0]
    : undefined
  const gameUidsForArea = segmentsForArea.map((seg) => seg.season.gameInstance.uid)
  const gameInstances = gameInstanceEdges.filter((gi) => gameUidsForArea.includes(gi.uid))
  const gameInstancesForArea = gameInstances
  const currentSportTypes = unique(segmentsForArea.map((segment) => segment.sportType))
  const curentGameTypes = unique(segmentsForArea.map((segment) => segment.gameType))

  // ********** Single Game Instance Area ****************************************************************************************************
  // For `SingleGameInstanceArea` (game with ONLY a challenge game running) we want to preload data and prevent an unnec spinner down the road:
  // NOTE qac: if we have both a reg-2019 and a post-2019 as current, we want to be in a single GI area:
  const _f = _segmentsForAreaWithCurrentSeason.length && _segmentsForAreaWithCurrentSeason[0]
  const _segmentForAreaWithCurrentSeasonVal = _f ? getSeasonNumericValue(_f.season) : undefined
  const _segmentsForAreaWithCurrentSeasonAndInSameSeasonType = _segmentForAreaWithCurrentSeasonVal
    ? _segmentsForAreaWithCurrentSeason.filter((seg) => getSeasonNumericValue(seg.season) === _segmentForAreaWithCurrentSeasonVal)
    : _segmentsForAreaWithCurrentSeason

  // NOTE qac: make sure we are not trying to access an previous season's pool by comparing a possible poolId to the challenge pool Id
  const isSingleGameInstanceAreaSeg =
    (_segmentsForAreaWithCurrentSeasonAndInSameSeasonType.length === 1 &&
      (!variableParams.poolId || variableParams.poolId === _segmentsForAreaWithCurrentSeasonAndInSameSeasonType[0].season.challengePoolId) &&
      _segmentsForAreaWithCurrentSeasonAndInSameSeasonType[0]) ||
    undefined
  const _seasonGis =
    (isSingleGameInstanceAreaSeg && gameInstances.filter((gi) => gi.uid === isSingleGameInstanceAreaSeg.season.gameInstance.uid)) || gameInstances
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const singleAreaGI = (_seasonGis.length === 1 && _seasonGis[0]!.poolType === ENUM_CHALLENGE && _seasonGis[0]!) || undefined
  const isSingleGameInstanceArea = !!singleAreaGI
  // const isSingleGameInstanceAreaSeg = singleAreaGI && (singleAreaGI.currentPeriod.segment || allSegments.find((seg) => seg.season.gameInstance.uid === singleAreaGI.uid));
  prefetchParams.poolId = (isSingleGameInstanceAreaSeg && isSingleGameInstanceAreaSeg.season.challengePoolId) || null
  const onlyAvailableEntryForSport = (prefetchParams.poolId && allCurrentEntries.find((ee) => ee.pool.id === prefetchParams.poolId)) || null
  prefetchParams.periodId = (isSingleGameInstanceAreaSeg && singleAreaGI && singleAreaGI.currentPeriod && singleAreaGI.currentPeriod.id) || null
  prefetchParams.entryId = onlyAvailableEntryForSport?.id || null
  const hasOnlyAvailableEntryForSport = !!onlyAvailableEntryForSport

  // ********** Challenge Entry Lookup ****************************************************************************************************
  // We need to use `entryId` to lookup the `poolId` IF this is an archived challenge pool:
  const centralChallengeEntryLookupQueryVariables = {
    entryId: variableParams.entryId || "",
  }
  const skipCentralChallengeEntryLookupQuery = !(
    centralChallengeEntryLookupQueryVariables.entryId && !allCurrentEntryIds.includes(centralChallengeEntryLookupQueryVariables.entryId)
  )
  const centralChallengeEntryLookupQuery = useQuery<CentralChallengeEntryLookupQuery, CentralChallengeEntryLookupQueryVariables>(
    CENTRAL_CHALLENGE_ENTRY_LOOKUP_QUERY,
    {
      skip: skipCentralChallengeEntryLookupQuery,
      variables: centralChallengeEntryLookupQueryVariables,
      notifyOnNetworkStatusChange: false,
      context: {
        headers: {
          [managerModeHeaderName]: isManagerModeActiveForRequest,
        },
      },
    },
  )
  const centralChallengeEntryLookupPool = centralChallengeEntryLookupQuery.data?.entry.pool

  // ************ poolId **************************************************************************************************
  // at this point we KNOW we have the pool!
  const currentSegmentWithChallPoolSlugInParams = _segmentsForAreaWithCurrentSeasonAndLandingUrl.find(
    // or we could use challengePoolSlug... but the landing URL should work
    (seg) => !!seg.season.challengePoolSlug,
  )
  const poolId =
    // specified in URL:
    variableParams.poolId ||
    // entryId from former challenge pool:
    (centralChallengeEntryLookupPool && centralChallengeEntryLookupPool.id) ||
    // current challenge pool
    (currentSegmentWithChallPoolSlugInParams && currentSegmentWithChallPoolSlugInParams.season.challengePoolId) ||
    // (isSingleGameInstanceArea)
    undefined

  // ************* poolDetail *************************************************************************************************
  // now that we have the poolId (if at all) we can grab the pool and season:
  const _poolId = poolId || prefetchParams.poolId
  const isPrefetching = _poolId !== poolId
  const prefetchPoolId = _poolId || null
  const _entriesBasePoolEntry = _poolId && allCurrentEntries.find((e) => e.pool.id === _poolId)
  const _basePool: CentralPoolDetailsFragment | undefined =
    (_entriesBasePoolEntry && _entriesBasePoolEntry.pool) || centralChallengeEntryLookupPool || undefined
  const poolEntryPointVariables = {
    poolId: _poolId || "",
  }
  const skipCentralPoolDetailQuery = !!_basePool || !poolEntryPointVariables.poolId
  const centralPoolDetailsQuery = useQuery<CentralPoolDetailsQuery, CentralPoolDetailsQueryVariables>(CENTRAL_POOL_DETAILS_QUERY, {
    skip: skipCentralPoolDetailQuery,
    variables: poolEntryPointVariables,
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActiveForRequest,
      },
    },
  })
  const poolDetail: CentralPoolDetailsFragment | undefined = _basePool || centralPoolDetailsQuery.data?.pool
  const poolDetailIsLoading = !skipCentralPoolDetailQuery && !(poolDetail && poolDetail.id) && !isPrefetching

  // ************** poolDetail Gametype ************************************************************************************************
  // misc based on poolDetail
  // const firstSegmentForPoolSeason = (poolDetail && allSegments.find((seg) => seg.season.id === poolDetail.season.id)) || null
  // const gameTypesForArea = unique(segmentsForArea.map((segment) => segment.gameType))
  // const poolSeason: CentralSeasonFragment | undefined = (firstSegmentForPoolSeason && firstSegmentForPoolSeason.season) || undefined
  // const gameType = firstSegmentForPoolSeason?.gameType || gameTypesForArea.length === 1 ? gameTypesForArea[0] : undefined
  const isBracket = gameType === ENUM_BRACKET
  const isPickem = gameType === ENUM_PICKEM
  const isParlay = gameType === ENUM_PARLAY

  // *************** detailedPeraiod ***********************************************************************************************
  // now that we have the poolId (if at all) we can grab the period:
  // to determine this, we need to fetch using order if need be:
  const _periodId = variableParams.order ? "" : variableParams.periodId || prefetchParams.periodId
  let _periodParamsGameInstanceUid =
    // for pool obv first:
    (poolDetail && poolDetail.gameInstanceUid) ||
    // for pool create (mainly)
    (_segmentForAreaWithCurrentSeasonAndLandingUrl && _segmentForAreaWithCurrentSeasonAndLandingUrl.season.gameInstance.uid) ||
    ""

  const _firstSegmentForArea = (segmentsForArea.length && segmentsForArea[0]) || undefined
  const _productSeason: CentralSeasonFragment | undefined = (_firstSegmentForArea && _firstSegmentForArea.season) || undefined
  if (isBracket && !_periodParamsGameInstanceUid && isMarchMadnessProductAbbrevMatcher.test(_productSeason?.productAbbrev || "")) {
    // we need detaildPeriod to be able to have pickUtils on the lobby
    // _periodParamsGameInstanceUid = "cbs-ncaab-tournament-challenge"
    _periodParamsGameInstanceUid =
      getStringParam(`gameInstanceUid`, useLocation().search) || _productSeason?.gameInstance.uid || "cbs-ncaab-tournament-challenge"
  }

  const isArchivedPool = !!poolDetail && poolDetail.season.isArchived
  const poolUsersEntriesVariables = {
    poolId: (poolDetail && poolDetail.id) || "",
    forUserId: (hasFantasyUser && currentUser && currentUser.id) || "",
    skipSpoeStatusCheck: isArchivedPool,
  }
  const skipPoolUsersEntries = !(isArchivedPool && poolUsersEntriesVariables.poolId && poolUsersEntriesVariables.forUserId)
  const poolUsersEntries = useQuery<PoolUsersEntriesQuery, PoolUsersEntriesQueryVariables>(POOL_USERS_ENTRIES_QUERY, {
    skip: skipPoolUsersEntries,
    variables: poolUsersEntriesVariables,
    notifyOnNetworkStatusChange: false,
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActiveForRequest,
      },
    },
  })
  const usersEntryEdgesInPool: PoolUsersEntriesQuery_pool_entries_edges_node[] | undefined = poolUsersEntries.data?.pool.entries.edges?.map(
    edgeToNode,
  )
  const usersFirstEntryEdgeInPool = (usersEntryEdgesInPool && usersEntryEdgesInPool.length && usersEntryEdgesInPool[0]) || emptyObject

  // ****************** usersCentralEntryInPool ********************************************************************************************
  const usersFirstCentralEntryInPool = (poolDetail && allCurrentEntries.find((e) => e.pool.id === poolDetail.id)) || usersFirstEntryEdgeInPool

  const includeEventsForStandings = !isBracket
  const includeEventsForPoolHome = !isBracket
  const includeEventsRegEx =
    poolDetail &&
    new RegExp(
      `${includeEventsForPoolHome ? new URL(poolDetail.url).pathname : "do not include"}|${
        includeEventsForStandings ? `/all-picks` : "do not include"
      }|/set-period-events`,
    )
  const includeEvents = !!includeEventsRegEx?.test(location.pathname)

  // ****************** detailedEntry ********************************************************************************************
  const periodParams = {
    gameInstanceUid: _periodParamsGameInstanceUid,
    isBracket,
    periodId: _periodId,
    // only add year and season type if not current
    // ignore prefetch params though so `order` can trump on pages still
    order: (!variableParams.periodId && variableParams.order) || null,
    year: (!variableParams.periodId && variableParams.year) || null,
    seasonType: (!variableParams.periodId && variableParams.seasonType) || null,
    poolId: prefetchPoolId || null,
    includeEvents,
  }
  const poolPeriodVariables = Object.assign({}, periodParams, poolEntryPointVariables)
  // offseason / create-pool logic:
  if ((poolPeriodVariables.poolId || poolPeriodVariables.gameInstanceUid) && !(poolPeriodVariables.periodId || poolPeriodVariables.order)) {
    poolPeriodVariables.periodId = "current"
  }

  const skipPoolPeriodQuery = !poolPeriodVariables.gameInstanceUid || !(poolPeriodVariables.periodId || poolPeriodVariables.order)
  const poolPeriodQuery = useQuery<PoolPeriodQuery, PoolPeriodQueryVariables>(POOL_PERIOD_QUERY, {
    skip: skipPoolPeriodQuery,
    variables: poolPeriodVariables,
    notifyOnNetworkStatusChange: true,
  })
  // console.debug(`isPrefetching: ${isPrefetching} (${_poolId} !== ${poolId}, skipPoolPeriodQuery: ${skipPoolPeriodQuery})`)
  const detailedPeriod = poolPeriodQuery.data?.gameInstance.period || undefined
  const detailedPeriodIsLoading = !skipPoolPeriodQuery && !detailedPeriod && !isPrefetching
  const detailedPeriodIsLoadingOrChanging = !skipPoolPeriodQuery && (detailedPeriodIsLoading || poolPeriodQuery.networkStatus < 4)
  const periodId = (detailedPeriod && detailedPeriod.id) || undefined

  // Now that we have the poolId and periodId, we can get the entry details for the desired period:
  const _entryId = variableParams.entryId || prefetchParams.entryId || (usersFirstCentralEntryInPool && usersFirstCentralEntryInPool.id)
  const entryDetailsVariables = {
    periodId: poolPeriodVariables.periodId || "",
    entryId: _entryId || "",
  }
  if (variableParams.order && !detailedPeriodIsLoading) {
    const p = detailedPeriod?.segment?.periods?.edges?.map(edgeToNode).find((x) => x.order === variableParams.order)
    if (p && !entryDetailsVariables.periodId) {
      entryDetailsVariables.periodId = p.id
    }
  }

  const skipEntryDetails = !entryDetailsVariables.periodId || !entryDetailsVariables.entryId
  const entryDetailsQuery = useQuery<EntryDetailsQuery, EntryDetailsQueryVariables>(ENTRY_DETAILS_QUERY, {
    skip: skipEntryDetails,
    variables: entryDetailsVariables,
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActiveForRequest,
      },
    },
  })
  const detailedEntry = entryDetailsQuery.data?.entry
  // console.debug(`networkStatus: ${entryDetailsQuery?.networkStatus || -1}`)
  const entryDetailsIsLoading = !detailedPeriodIsLoading && !skipEntryDetails && entryDetailsQuery.networkStatus < 2 && !isPrefetching
  const entryDetailsIsLoadingOrChanging = !skipEntryDetails && entryDetailsQuery.networkStatus < 4 && !isPrefetching
  const entryId = (detailedEntry && detailedEntry.id) || _entryId

  // const memberDetailsQuery = useQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(MEMBER_DETAILS_QUERY, {
  //   skip: !detailedEntry?.memberId,
  //   variables: {
  //     memberId: detailedEntry?.memberId ?? "",
  //   },
  //   notifyOnNetworkStatusChange: true,
  // })

  // const detailedMember = memberDetailsQuery.data?.member

  const memberDetailsQuery = useQuery<CurrentMembershipQuery, CurrentMembershipQueryVariables>(CURRENT_MEMBERSHIP_QUERY, {
    skip: !poolDetail?.id,
    variables: {
      poolId: poolDetail?.id ?? "",
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActiveForRequest,
      },
    },
  })

  const detailedMember = memberDetailsQuery.data?.member

  const memberDetailsIsLoading = memberDetailsQuery.loading

  // ************** Archived Pool UsersEntries ************************************************************************************************
  // if this is an archive pool, we need to figure out if the viewer has an entry in it (may not be allCurrentEntries)
  // we cannot do this with `entryId=xxx` since we need to know if the user is allowed to view the pool / entry
  // *************** centralTeams ***********************************************************************************************
  // final parts: teams
  const skipTeams = !isBracket || !currentSportTypes.length
  const centralTeamsQueryVariables = {
    sportTypes: currentSportTypes,
    subsection: null,
  }
  const centralTeamsQuery = useQuery<CentralTeamsQuery, CentralTeamsQueryVariables>(CENTRAL_TEAMS_QUERY, {
    skip: skipTeams,
    variables: centralTeamsQueryVariables,
    notifyOnNetworkStatusChange: false,
  })
  const centralTeams = centralTeamsQuery.data?.teams || emptyArray
  const centralTeamsQueryLoading = centralTeamsQuery.hasOwnProperty("networkStatus") && centralTeamsQuery.networkStatus < 4

  // ************* Segment, Season, and GameInstance Area *************************************************************************************************
  // pool create area / multi-gameInstance additions
  const gameInstanceForArea =
    // specified by pool
    (poolDetail && gameInstanceEdges.find((gi) => gi.uid === poolDetail.gameInstanceUid)) ||
    // if on pool create (before creating pool)
    (isPoolCreate && gameInstancesForArea.find((gi) => gi.poolType === ENUM_MANAGER)) ||
    // if in single GI area
    singleAreaGI ||
    // if in a single sport / game area (mainly for signup attribution)
    (gameInstancesForArea.length > 0 && curentGameTypes.length === 1 && currentSportTypes.length === 1 && gameInstancesForArea[0]) ||
    undefined

  const _segmentForArea =
    // segment for period
    (detailedPeriod && allSegments.find((seg) => seg.id === detailedPeriod.segment.id)) ||
    // segment for pool create:
    _segmentForAreaWithCurrentSeasonAndLandingUrl ||
    // FIRST segment for area WITH current season! (these are ordered by season + seasonType)
    (_segmentsForAreaWithCurrentSeason.length &&
      _segmentsForAreaWithCurrentSeason.filter((seg) => seg.season.gameInstance.client === ClientsEnum.CBS)[0]) ||
    // FIRST segment for area
    (segmentsForArea.length && segmentsForArea[0]) ||
    undefined
  const segmentForArea: CentralSegmentFragment | undefined = _segmentForArea
  const productSeason: CentralSeasonFragment | undefined = (_segmentForArea && _segmentForArea.season) || undefined
  const isNcaaBracketArea = isMarchMadnessProductAbbrevMatcher.test(productSeason?.productAbbrev || "")

  // ********** Brackets State Data *************************************************************************************************
  const centralBracketStateQuery = useQuery<CentralBracketsState>(CENTRAL_BRACKET_STATE_QUERY, {
    skip: !isNcaaBracketArea,
  })
  let centralBracketState = centralBracketStateQuery.data && { bracketState: centralBracketStateQuery.data.mensBracketState }
  const gameInstanceUidForArea = gameInstanceForArea?.uid
  if (gameInstanceUidForArea) {
    if (isNCAAWTournamentMatcher.test(gameInstanceUidForArea) && centralBracketStateQuery.data) {
      centralBracketState = { bracketState: centralBracketStateQuery.data.womensBracketState }
    }
  }
  const globalBracketState = centralBracketStateQuery.data

  // *************** Nice Flags ***********************************************************************************************
  // nice flags:
  // const currentUsersEntryIds = usersEntryEdgesInPool.concat(allCurrentEntries).map(mapToId);
  const isMine = !detailedEntry || detailedEntry.isMine
  // if (!isMine) {
  //   console.debug(`!isMine: ${detailedEntry?.isMine}`);
  // }
  const hasAutogeneratedName = !detailedEntry || detailedEntry.hasAutogeneratedName
  const gameRequiresAuthGateForPickPage = !!(isMine && productSeason?.requireCbsAccountBeforeEntry)
  const hasManagerRole = !!usersFirstCentralEntryInPool && entryHasManagerRole(usersFirstCentralEntryInPool)
  let isInComingSoon = !!segmentForArea && segmentForArea.isInComingSoon
  if (centralBracketState?.bracketState) {
    isInComingSoon = centralBracketState.bracketState.isPreTournament
  }
  const gameInstanceUid = (gameInstanceForArea && gameInstanceForArea.uid) || ""
  const isChallengePool = !!gameInstanceForArea && gameInstanceForArea.poolType === ENUM_CHALLENGE
  const areaHasMultipleEntriesPerUser = segmentsForArea.some(
    (seg) => seg.poolSettings.__typename === "PoolSettings" && seg.poolSettings?.multipleEntriesOption === ENUM_AVAILABLE,
  )
  const areaHasPublicPools = isChallengePool && segmentsForArea.some((seg) => seg.season.hasPublicPools)
  const periodLocksAt = detailedPeriod?.locksAt || gameInstanceForArea?.currentPeriod?.locksAt || null

  const { upsertEntryMutation } = useUpsertEntryMutation(
    detailedEntry?.id,
    detailedPeriod?.segment.season.season,
    detailedPeriod?.segment.season.year,
  )

  const bracketLobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

  const isLobbyPage = bracketLobbyMatch?.isExact || false

  const isWomens = isNCAAWTournamentMatcher.test(gameInstanceUid)
  const gameInstanceUidForInvites = !isBracket
    ? gameInstanceUid
    : isWomens
    ? constants.NCAAW_MANAGER_GAME_INSTANCE_UID
    : constants.NCAAB_MANAGER_GAME_INSTANCE_UID

  const userPendingInvitesQuery = useQuery<CurrentUserPendingInvites, CurrentUserPendingInvitesVariables>(
    CENTRAL_CURRENT_USERS_PENDING_INVITATIONS_QUERY,
    {
      skip: !isNcaaBracketArea || centralBracketState?.bracketState?.isLocked || (isChallengePool && !isLobbyPage),
      variables: {
        gameInstanceUid: gameInstanceUidForInvites,
      },
    },
  )

  const userPendingInvites: CurrentUserPendingInvites_pendingInvitations[] = userPendingInvitesQuery.data?.pendingInvitations || emptyArray

  // **************** To Remove **********************************************************************************************
  // TODO qac: remove these:
  const poolRoot = (poolDetail && withoutDomain(poolDetail.url)) || ""
  const isManagerModeActive = detailedMember?.isManagerModeActive ?? false

  if (canUseDom && window.hasOwnProperty("QQQ")) {
    console.log(`centralBracketState`)
    console.dir(centralBracketState)
    console.log(`segmentsForArea`)
    console.dir(segmentsForArea)
    console.log("productSeason")
    console.dir(productSeason)
    console.log(`"poolDetail" (${isArchivedPool})`)
    console.dir(poolDetail)
    console.log("detailedEntry")
    console.dir(detailedEntry)
    console.log("usersFirstCentralEntryInPool")
    console.dir(usersFirstCentralEntryInPool)
    console.log("detailedPeriod")
    console.dir(detailedPeriod)
    console.log(`prefetchParams`)
    console.dir(prefetchParams)
    console.log(`centralChallengeEntryLookupQueryVariables`)
    console.dir(centralChallengeEntryLookupQueryVariables)
    console.log(`poolEntryPointVariables`)
    console.dir(poolEntryPointVariables)
    console.log(`poolPeriodVariables`)
    console.dir(poolPeriodVariables)
    console.log(`poolUsersEntriesVariables`)
    console.dir(poolUsersEntriesVariables)
    console.log(`entryDetailsVariables`)
    console.dir(entryDetailsVariables)

    console.log(`centralChallengeEntryLookupPool (poolId: ${poolId})`)
    console.dir(centralChallengeEntryLookupPool)
    console.log(`currentSegmentWithChallPoolSlugInParams`)
    console.dir(currentSegmentWithChallPoolSlugInParams)
    console.log(`userPendingInvites`)
    console.dir(userPendingInvites)
  }

  const poolData = {
    gameInstanceForArea,
    gameInstancesForArea,
    gameInstanceUid,
    detailedPeriod,
    poolDetail,
    productSeason,
    segmentForArea,
    segmentsForArea,
    poolRoot,
    allSegments,
    detailedEntry,
    detailedMember,
    centralTeams,
    centralTeamsQueryLoading,
    isSingleGameInstanceArea,
    isCbsAppWebview,
    centralBracketState,
    globalBracketState,
    isCentralBracketStateLoading: centralBracketStateQuery.loading,
  } as IPoolDataSub & IAuthGateFuncProps

  const { soonToBeLiveUnfinishedEventIds, useWsLiveUpdates } = buildPollingProps(poolData)

  const toggleManagerMode = (value: boolean, callback?: () => void) => {
    if (canUseDom) {
      if (value) {
        // Set the cookie to expire in 1 day, can probably be less, but puttin in just in case
        setCookie(managerModeEnabledCookieName, `${value}`, {
          expires: 1,
        })
      } else {
        removeCookie(managerModeEnabledCookieName)
      }
      if (callback) {
        callback()
      }
      // const [bumpSequenceNumber] = useMutation<>()
    }
  }

  const deviceType = useDeviceType()
  useSubscription<EventUpdatesSubscription, EventUpdatesSubscriptionVariables>(EVENT_UPDATES_SUBSCRIPTION, {
    variables: { eventIds: soonToBeLiveUnfinishedEventIds.split("|") },
    skip: !(useWsLiveUpdates && soonToBeLiveUnfinishedEventIds),
  })

  const myEntriesForArea: () => IMyEntriesForArea = () => {
    let alreadyHasManagerEntry = false
    let alreadyHasChallengeEntry = false
    let alreadyHasSweet16ChallengeEntry = false

    const currentEntriesForArea = allCurrentEntries.filter((e) => {
      let addToCurrentEntriesForArea = false
      for (const gi of gameInstancesForArea) {
        if (gi.uid === e.gameInstanceUid) {
          addToCurrentEntriesForArea = true
          if (gi.poolType === ENUM_MANAGER) {
            alreadyHasManagerEntry = true
          } else if (gi.uid === constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID || gi.uid === constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID) {
            alreadyHasChallengeEntry = true
          } else if (gi.uid === NCAAB_S16_GAME_INSTANCE_UID) {
            alreadyHasSweet16ChallengeEntry = true
          }
          break
        }
      }
      return addToCurrentEntriesForArea
    })

    return {
      currentEntriesForArea,
      alreadyHasManagerEntry,
      alreadyHasChallengeEntry,
      alreadyHasSweet16ChallengeEntry,
    }
  }

  return {
    ...poolData,
    centralGameInstancesQuery,
    centralCurrentUsersEntriesQuery,
    baseIsLoading,
    // global
    allGameInstances,
    // arena
    currentSportTypes,
    isBracket,
    isParlay,
    isPickem,
    areaHasMultipleEntriesPerUser,
    areaHasPublicPools,
    // season / segments
    // gameInstances
    gameInstances,
    // pool related
    // NOTE qac: for this we ALWAYS provide a string since truthy for "" is false
    poolId: poolId || "",
    // entry related
    allCurrentEntries,
    isManagerModeActive,
    // user / device related
    currentUser,
    geoInfo,
    gamblingPartnerInfo,
    isIosBrowser,
    isAndroidBrowser,
    hasCbsAccount,
    hasFantasyUser,
    isCbsAdmin,
    // period related
    // NOTE qac: for this we ALWAYS provide a string since truthy for "" is false
    periodId: periodId || "",
    periodLocksAt,

    // queries
    poolPeriodQuery,
    centralPoolDetailsQuery,
    centralTeamsQuery,
    entryDetailsQuery,
    // loading
    entryDetailsIsLoading,
    entryDetailsIsLoadingOrChanging,
    memberDetailsIsLoading,
    poolDetailIsLoading,
    detailedPeriodIsLoading,
    detailedPeriodIsLoadingOrChanging,
    // data
    usersFirstCentralEntryInPool,
    isArchivedPool,
    // NOTE qac: for this we ALWAYS provide a string since truthy for "" is false
    entryId: entryId || "",
    isMine,
    // entry,
    hasAutogeneratedName,
    hasManagerRole,
    gameRequiresAuthGateForPickPage,
    // helpers
    myEntriesForArea,
    // peices:
    hasOnlyAvailableEntryForSport,
    isInComingSoon,
    isChallengePool,
    isPrefetching,
    isSingleGameInstanceArea,
    isPoolCreate,
    variableParams,
    entryDetailsVariables,
    deviceType,
    currentSegmentWithChallPoolSlugInParams,
    currentUserPendingInvites: userPendingInvites,
    toggleManagerMode,
    // mutations:
    upsertEntryMutation,
    gameDataVariables,
  } //  as IPoolDataSub & IAuthGateFuncProps
}

export default usePoolData
