import React from "react"
import styled from "styled-components/macro"
import { palette } from "../utils/style-utils"
import Link, { IProps } from "./Link"

const StyledA = styled(Link)`
  color: ${palette.uiSubTextLink};
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  will-change: color;
  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${palette.blueActive};
    }
  }
  &:active {
    color: ${palette.blueActive};
  }
`

export class A extends React.PureComponent<IProps> {
  public render() {
    return <StyledA {...this.props} />
  }
}

export default A
