import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { getThemeValues } from "../../../../common/theme-helpers"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

export const NavBarBase = styled(FlexRow)`
  transition: all 0.5s ease;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.siteNav};
  @media (max-width: ${pxToRem(layout.fullscreenHideNavW)}) {
    .hide-mobile-nav--true & {
      display: none;
    }
  }
`

export const NetworkBar = styled(NavBarBase)`
  background-color: ${palette.uiNavNetworkBlue};
  padding: 0 ${pxToRem(10)} 0 ${pxToRem(8)};
  height: ${pxToRem(layout.siteSubNavH)};
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    display: none;
  }
`

export const navBg = ({ theme }) => {
  const themeOObj = getThemeValues(theme, true)
  const color = theme.isInDebugMode ? palette.orange : themeOObj.uiNav
  return color
}

export const StickyNav = styled(NavBarBase)`
  background-color: ${navBg};
  box-shadow: 0 ${pxToRem(3)} ${pxToRem(3)} 0 rgba(16, 24, 37, 0.25);
  height: ${pxToRem(layout.siteNavH)};
  flex: 0 0 ${pxToRem(layout.siteNavH)};
  padding: 0 ${pxToRem(8)};
  overflow: visible;
  position: relative;
  &.is-sticky--true {
    position: sticky;
  }
  &.top-nav-toggled--true {
    pointer-events: all;
  }
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    justify-content: space-between;
    order: 1;
  }
`
export const NetworkBarNetworks = styled(FlexRow)`
  flex: 1 1 0%;
  justify-content: flex-start;
`
export const NetworkBarSocial = styled(FlexRow)`
  flex: 0 1 0%;
  min-width: ${pxToRem(150)};
  justify-content: flex-end;
`
interface IPropsNetworkBarItem {
  highlighted: boolean
}
export const StyledNetworkBarNetworksItem = styled.a<IPropsNetworkBarItem>`
  padding: ${pxToRem(0)} ${pxToRem(5)};
  height: ${pxToRem(30)};
  color: ${({ highlighted }) => (highlighted ? `${palette.uiNavLinkHighlighted} !important` : `${palette.textTertiaryBrand}`)};
  font-size: ${pxToRem(11)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  letter-spacing: ${pxToRem(1.5)};
  line-height: 1;
  text-transform: uppercase;
  margin-right: ${pxToRem(5)};
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  will-change: color;
  @media (hover: hover) {
    :hover:not(:active) {
      color: #ffffff;
    }
  }
  :active {
    color: #ffffff;
  }
`
export const SocialButton = styled.a`
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  transition: background-color 0.2s ease;
  height: ${pxToRem(22)};
  width: ${pxToRem(40)};
  margin-left: ${pxToRem(4)};
  border-radius: ${pxToRem(2)};
  background-color: ${palette.blue80};
  color: ${palette.white};
  @media (hover: hover) {
    :hover:not(:active) {
      background-color: #0a54b3;
      color: ${palette.white};
    }
  }
  :active {
    background-color: #0a54b3;
    color: ${palette.white};
  }
  &.icon-moon-twitter {
    @media (hover: hover) {
      :hover:not(:active) {
        background-color: #000;
      }
    }
    :active {
      background-color: #000;
    }
  }
  &:before {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: inherit;
    font-size: ${pxToRem(12)};
    font-weight: 600;
  }
`

export const Button = styled(FlexRow)`
  border-radius: ${pxToRem(2)};
  font-weight: 400;
  text-transform: uppercase;
  transition: background-color 0.2s ease;
  width: auto;
  font-size: ${pxToRem(13)};
  letter-spacing: ${pxToRem(1)};
  padding: ${pxToRem(10)} ${pxToRem(20)};
  background-color: #15b842;
  color: #fff;
  @media (hover: hover) {
    :hover:not(:active) {
      background-color: #0d7329;
    }
  }
  :active {
    background-color: #0d7329;
  }
`

export const SitNavPromo = styled(Button)`
  margin-right: ${pxToRem(8)};
  flex: 0 1 auto;
`

export const AuthBtn = styled(FlexColGrow)`
  color: rgba(0, 0, 0, 0.8);
  font-family: ${fontFamily.condensed};
  font-size: ${pxToRem(13)};
  letter-spacing: ${pxToRem(2)};
  text-transform: uppercase;
  transition: all 0.2s ease;
  border: ${pxToRem(1)} solid rgba(0, 0, 0, 0.2);
  border-radius: ${pxToRem(2)};
  margin: ${pxToRem(7)} ${pxToRem(7)};
  justify-content: center;
  min-width: ${pxToRem(150)};
  padding: ${pxToRem(10)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(9)};
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 1);
    }
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 1);
  }
`

const hamburgerW = 18
const hamburgerH = 1
export const StyledMobileNavHamburger = styled(FlexCol)`
  height: 100%;
  width: ${pxToRem(50)};
  transition: opacity 0.15s ease-out;
  overflow: visible;
  color: #fff;
  &:active {
    opacity: 0.7;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      opacity: 0.7;
    }
  }
  @media (min-width: ${pxToRem(layout.useMobileNavW)}) {
    display: none;
  }
`

export const MobileNavHamburgerBox = styled.div`
  width: ${pxToRem(hamburgerW)};
  height: ${pxToRem(18)};
  display: inline-block;
  position: relative;
  & *,
  & *::before,
  & *::after {
    content: "";
    display: block;
    width: ${pxToRem(hamburgerW)};
    height: ${pxToRem(hamburgerH)};
    background-color: currentColor;
    border-radius: ${pxToRem(hamburgerH)};
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
`

export const MobileNavHamburgerInner = styled.div`
  margin-top: ${pxToRem(-hamburgerH / 2)};
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  .top-nav-toggled--true & {
    transform: translate3d(0, ${pxToRem(hamburgerW / 4)}, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  &::before {
    top: 6px;
    transition: opacity 0.125s 0.275s ease;
    .top-nav-toggled--true & {
      transition-delay: 0s;
      opacity: 0;
    }
  }
  &::after {
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    .top-nav-toggled--true & {
      top: 10px;
      transform: translate3d(0, ${pxToRem((-hamburgerW / 4) * 2)}, 0) rotate(-270deg);
      transition-delay: 0.075s;
    }
  }
`

export const MobileNavModalInner = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${palette.gray95};
`
export const MobileNavModalAuthBtnContainer = styled(FlexRow)`
  flex-wrap: wrap;
`

export const DebugModeContainer = styled.div<{ closed: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  height: 3rem;
  padding: 0 1rem;
  padding-right: 3rem;
  background-color: ${palette.orange};
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  align-items: center;
  padding-right: 3rem;
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  transition: transform 0.2s ease-out;
  transform: ${({ closed }) => (closed ? `translateX(calc(-100% + 3rem))` : `translateX(0)`)};
  z-index: ${zIndexes.ui3};
  color: ${palette.white};
  & button {
    position: absolute;
    right: 0;
    height: 100%;
    width: 3rem;
    justify-content: center;
  }
`

export const DebugPanelModalBody = styled.div`
  width: 90vw;
  max-width: ${pxToRem(800)};
  & > * {
    margin-bottom: 1em;
  }
  & a {
    display: flex;
    align-items: center;
  }
  & strong {
    font-weight: ${fontWeight.bold};
  }
  & summary {
    cursor: pointer;
  }
  & details > * {
    margin-bottom: 1em;
  }
  & code {
    display: inline-block;
    margin: 0 0.5em;
    padding: 0.2em 0.5em;
    border-radius: 0.2em;
    background-color: #cccccc50;
    color: rgba(0, 0, 0, 0.95);
    font-family: "Courier 10 Pitch", Courier, monospace;
    white-space: nowrap;
    box-shadow: inset 0 0 1em 0.4em rgba(0, 0, 0, 0.05);
  }
`
