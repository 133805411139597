import { oneDay } from "../../common/misc-utils"
import { canUseDom } from "./misc-utils"

class CookieUtils {
  static readonly CookieNames = class {
    static readonly BRACKET_UPSELL_COOKIE_NAME = "picks-platform-bpm-to-bpc"
  }

  static readonly setCookie = (keyName: string, data: string, expires?: Date) => {
    if (canUseDom) {
      const expiration: Date = expires || new Date()
      if (!expires) {
        const expiresIn = 14 * oneDay
        expiration.setTime(expiration.getTime() + expiresIn)
      }
      document.cookie = `${keyName}=${encodeURIComponent(data)}; expires=${expiration.toUTCString()}; path=/; domain=cbssports.com`
    }
  }
  static readonly getCookie = (keyName: string): string => {
    if (canUseDom) {
      const cookieSplit = document.cookie.split(";")
      for (const cookieParts of cookieSplit) {
        const c = cookieParts.trimStart()
        if (c.startsWith(keyName)) {
          const deflated = c.split("=")[1] || ""
          const decodedCookie = decodeURIComponent(deflated)
          // allow error out
          return decodedCookie
        }
      }
    }
    return ""
  }
}

export default CookieUtils
