import * as React from "react"
import styled from "styled-components/macro"
import { CentralSeasonFragment } from "../../../../__generated__/CentralSeasonFragment"
import WilliamHillSportsBookLogo from "../../../components/WilliamHillSportsBookLogo"
import { fontWeight, pxToRem } from "../../../utils/style-utils"
import palette from "../../../../common/palette"
import { getSponsorKeyFor } from "../../../../common/game-text"
import GamblingPartnerAdPlacement from "../../../components/GamblingPartnerAdPlacement"
import { breakpoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import TricoSvg from "../../../components/icons/Trico"

const PoolPageSponsorStyled = styled.div`
  & svg {
    width: auto;
  }
  & img {
    height: ${pxToRem(24)};
    @media (max-width: ${pxToRem(breakpoints.handheld - 1)}) {
      height: ${pxToRem(20)};
    }
  }
  & div {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(8)};
    letter-spacing: 0.01em;
    color: ${palette.gray1};
    height: ${pxToRem(14)};
  }
`
interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  season?: CentralSeasonFragment | null
  sponsorLogoUrl?: string
}

const PoolPageSponsor = React.forwardRef<HTMLElement, IProps>((props, ref) => {
  const { season, sponsorLogoUrl, ...rest } = props
  const sponsorKey = getSponsorKeyFor(season)
  if (sponsorKey === "williamhill") {
    return (
      <GamblingPartnerAdPlacement placement="marquee">
        <PoolPageSponsorStyled ref={ref as any} {...rest}>
          <div>Odds by</div>
          <WilliamHillSportsBookLogo isLogo={true} />
        </PoolPageSponsorStyled>
      </GamblingPartnerAdPlacement>
    )
  }
  if (sponsorKey === "trico") {
    return (
      <PoolPageSponsorStyled ref={ref as any} {...rest}>
        <div>Presented by</div>
        <TricoSvg />
      </PoolPageSponsorStyled>
    )
  }

  return null
})

const MemodComponent = React.memo(PoolPageSponsor)
export default MemodComponent
