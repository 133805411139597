import { QueryResult } from "@apollo/client"
import React, { useRef, useState } from "react"
import {
  PoolInvitationCenterQuery,
  PoolInvitationCenterQueryVariables,
  PoolInvitationCenterQuery_currentUser_groups_edges_node,
  PoolInvitationCenterQuery_fantasyContacts,
} from "../../../../../__generated__/PoolInvitationCenterQuery"
import { edgeToNode, emptyNodes, toNodes } from "../../../../utils/misc-utils"
import { MenuItem, MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { ContactListWrap, TabsMenuWrap } from "./InviteCenterTabs.styles"
import CheckBoxFilledSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBoxFilled"
import CheckBoxSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBox"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import CirclePlusSvg from "../../../../components/icons/CirclePlus"
import { PreviousYearPlayersQuery, PreviousYearPlayersQueryVariables } from "../../../../../__generated__/PreviousYearPlayersQuery"
import LoadingView from "../../../../components/LoadingView"

export interface ContactItem {
  email: string
  name?: string
  avatarUrl?: string
  selected?: boolean
}

export interface ContactData {
  all: ContactItem[]
  favorites: ContactItem[]
  groups: ContactItem[]
}

export const getContacts = (
  fantasyContacts: PoolInvitationCenterQuery_fantasyContacts,
  groups: PoolInvitationCenterQuery_currentUser_groups_edges_node[],
  currentUserId: string,
): ContactData => {
  const result: ContactData = {
    all: [],
    favorites: [],
    groups: [],
  }
  const contacts: any[] = (fantasyContacts.all_contacts || []).filter((x) => x.email)
  // All
  result.all = contacts.map((contact) => {
    return {
      name: [contact.first_name, contact.last_name].join(" "),
      email: contact.email,
    }
  })
  for (const group of groups) {
    for (const user of group?.users || []) {
      if (user.id !== currentUserId && !result.all.find((u) => u.email === user.email)) {
        if (user.email) {
          result.all.unshift({
            name: user.preferredEntryName || undefined,
            email: user.email,
            avatarUrl: user.avatarUrl || "",
          })
        }
      }
    }
  }

  // Favorites
  result.favorites = contacts
    .filter((contact) => contact?.is_favorite)
    .map((contact) => {
      return {
        name: [contact.first_name, contact.last_name].join(" "),
        email: contact.email,
      }
    })
  for (const group of groups) {
    for (const user of group?.users || []) {
      if (user.id !== currentUserId && !result.all.find((u) => u.email === user.email)) {
        if (user.email) {
          result.favorites.unshift({
            name: user.preferredEntryName || undefined,
            email: user.email || "",
          })
        }
      }
    }
  }

  // Groups
  const groupsData = fantasyContacts.by_league_owners || {}
  result.groups = Object.keys(groupsData).map((n) => {
    return {
      name: n,
      email: groupsData[n]?.league_owners.map((c) => c.email),
    }
  })
  for (const group of groups) {
    if (group?.groupPools?.length) {
      result.groups.unshift({
        name: group.name,
        avatarUrl: group.avatarUrl || undefined,
        email:
          group.users
            ?.filter((u) => u.id !== currentUserId && !!u.email)
            .map((u) => u.email || "")
            .join(", ") || "",
      })
    }
  }

  return result
}
export const ContactList = ({
  items,
  onItemClick,
  selectMode,
  showIcon = true,
  showEmail = true,
  loading = false,
}: {
  items: ContactItem[]
  onItemClick: (data: ContactItem) => void
  selectMode?: boolean
  showIcon?: boolean
  showEmail?: boolean
  loading?: boolean
}) => {
  return (
    <ContactListWrap>
      {loading && <LoadingView />}
      {!loading &&
        items.map((item, kI) => {
          const { name, email, avatarUrl, selected } = item
          const emailData = Array.isArray(email) ? email[0] || "" : email
          return (
            <div
              key={`contact-item-${kI}`}
              className={cx({
                "contact-item": true,
              })}
              onClick={() => onItemClick(item)}
            >
              <div className="left">
                <div className="icon">{avatarUrl && <img src={avatarUrl} alt={name} />}</div>
                <div className="contact-info">
                  <span className="name">{name || ""}</span>
                  {showEmail && <span className="email">{emailData || ""}</span>}
                </div>
              </div>
              {showIcon && selectMode && (selected ? <CheckBoxFilledSvg /> : <CheckBoxSvg />)}
              {showIcon && !selectMode && <CirclePlusSvg />}
            </div>
          )
        })}
    </ContactListWrap>
  )
}

export const TabsMenu = ({
  onItemClick,
  poolInvitationCenterQuery,
  currentUserId,
  isModal,
  showPoolMembers = false,
  previousMembersQuery,
}: {
  onItemClick: (any) => void
  poolInvitationCenterQuery: QueryResult<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>
  previousMembersQuery?: QueryResult<PreviousYearPlayersQuery, PreviousYearPlayersQueryVariables>
  currentUserId: string
  isModal?: boolean
  showPoolMembers?: boolean
}) => {
  const fantasyContacts = poolInvitationCenterQuery?.data?.fantasyContacts || ({} as never)
  const previousMembers =
    toNodes(previousMembersQuery?.data?.pool?.players)
      .filter((x) => !x.isMine && !x.isOfflineEntry)
      .map((player) => {
        const item: ContactItem = {
          email: player.email ?? "",
          name: player.userInfo?.preferredEntryName ?? "",
          avatarUrl: player.avatarUrl,
        }
        return item
      }) || []
  const groups = (poolInvitationCenterQuery?.data?.currentUser?.groups?.edges || emptyNodes).map(edgeToNode)
  const tabsRef = useRef<HTMLDivElement | null>(null)

  const [currentSection, setCurrentSection] = useState("all")

  const handleSectionClick = (event: React.SyntheticEvent, sectionName: string) => {
    event.stopPropagation()
    event.preventDefault()
    setCurrentSection(sectionName)
  }

  const list = getContacts(fantasyContacts, groups, currentUserId)
  const itemsToShow = currentSection === "members" ? previousMembers : list[currentSection]
  const showLoading = showPoolMembers && previousMembersQuery?.loading && currentSection === "members"

  return (
    <TabsMenuWrap ref={tabsRef}>
      <div className={`invite-tabs-menu-content is-modal--${isModal}`}>
        <span className="title">My Contacts</span>
        <MenuBase className="menu" inline>
          <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "all")} active={currentSection === "all"}>
            All
          </MenuItem>
          <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "favorites")} active={currentSection === "favorites"}>
            Favorites
          </MenuItem>
          <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "groups")} active={currentSection === "groups"}>
            Groups
          </MenuItem>
          {showPoolMembers && (
            <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "members")} active={currentSection === "members"}>
              Pool Members
            </MenuItem>
          )}
        </MenuBase>
        <div className="contact-list">
          <ContactList items={itemsToShow} onItemClick={onItemClick} showEmail={currentSection !== "groups"} loading={showLoading} />
        </div>
      </div>
    </TabsMenuWrap>
  )
}
