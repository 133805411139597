import { get as getCookie } from "es-cookie"
import constants from "../../common/constants"
// import getCbsoptanonSdk from "./getCbsoptanonSdk"
import { getVGUID, loadScript, requestIdleCallback } from "./misc-utils"

declare global {
  // tslint:disable-next-line
  interface Window {
    utag: any
    utag_data: any
    trackScreen: any
    trackInteraction: any
    utag_cfg_ovrd: any
    debugMessages: any
    dw_anonc: any
    DW_anonc: any
    om: any
    __STI_DEBUG__: any
    $: any
    fbq: (args: string, args2: string) => void
    _fbq: any
  }
}

interface QueueEvent {
  eventType: "interaction" | "screen" | "persist" | "init" | "action"
  // subfeature: string,
  payload: any
}
interface State {
  channel: string
  productLine: string
  serviceLevel: "free"
  feature: string
  subfeature: string
  title: string
  isCbsAppWebview: boolean
}

type TContext = Omit<State, "feature" | "subfeature" | "title">

const debugMessages = [] as any[]
const addMsg = (event: QueueEvent) => {
  debugMessages.push(event)
  window.debugMessages = debugMessages
  // const { eventType, payload } = event;
  // console.groupCollapsed(`Fired '${String(eventType)}'`);
  // console.debug(`payload:`, payload);
  // console.debug(`current utag_data:`);
  // console.dir(window.utag_data);
  // console.groupEnd();
}
const TRACKING_ABBREV_LOOKUP = {
  ffbopm: "cfpm",
  ffbopc: "cfpc",
  opc: "pfpc",
  popc: "plfpc",
}
const queue = [] as QueueEvent[]
let timeoutId: NodeJS.Timer | number | null = null
let backoffMs = 200
// const pageNameBase = "fantasy/spln";
const project = "fantasy games"
const baseState = {
  deviceType: "desktop",
  pageType: "odds and picks",
  pageName: "/[pageNameBase]/[channel]/[productLine]/[serviceLevel]/[feature]/[subfeature]/[subfeature]",
  trackingMethodAction: "/[channel]/[productLine]/[serviceLevel]/[feature]/[subfeature]/[subfeature]",
  siteHier: "[project]|[channel]|[productLine]|[serviceLevel]|[feature]|[subfeature]",
  siteSection: "[channel]|[productLine]|[serviceLevel]|[feature]|[subfeature]",
  siteCode: "cbssports",
  siteType: "desktop web",
  siteEdition: "us",
  userId: undefined,
  userState: undefined,
  userType: undefined,
  sitetype: "",
  siteedition: "",
  isReactPage: true,
  taboolaFlag: false,
}
// {"userId":24585035,"userState":"authenticated","userType":"registered"}
const currentState = {
  channel: "nfl",
  productLine: "opm",
  serviceLevel: "free",
  siteSection: "picks list",
  feature: "picks",
  subfeature: "picks list",
  title: "Picks - Make Picks - List view",
  isCbsAppWebview: false,
} as State

let sdkLoading = false
let sdkReady = false

const init = async () => {
  const windowDefined = typeof window !== "undefined"
  const docDefined = typeof document !== "undefined"

  if (!sdkLoading && docDefined && windowDefined) {
    // NOTE qac: utag.js requires only this function of jquery
    window.$ = window.$ || {
      extend: Object.assign,
    }
    window.utag_cfg_ovrd = { noview: true }
    window.utag_data = buildState({})
    if (window.location.href.indexOf("utagdb=true") > -1 && document.cookie.indexOf("utagdb") < 0) {
      document.cookie = "utagdb=true;" + document.cookie
    }
    sdkLoading = true
    const userDataCookie = getCookie("fly_tracking")
    if (userDataCookie) {
      try {
        const userData = JSON.parse(decodeURIComponent(userDataCookie.replace("undefined", "null")))
        Object.assign(baseState, userData)
      } catch (err) {
        console.error(err)
      }
    }
    const flyDevice = getCookie("fly_device") || "unknown"
    baseState.sitetype = `${flyDevice} web`
    const userGeoCookie = getCookie("fly_geo")
    if (userGeoCookie) {
      try {
        const userGeo = JSON.parse(userGeoCookie)
        baseState.siteedition = userGeo.countryCode || "unknown"
      } catch (err) {
        console.error(err)
      }
    }
    // if (document.cookie.indexOf("XCLGFbrowser=") == -1) {
    //   await loadScript(`//dw.cbsi.com/anonc.js`)
    //   if (window.dw_anonc) {
    //     window.DW_anonc = window.dw_anonc()
    //     if (window.DW_anonc) {
    //       let g = new Date()
    //       const b = 315360000000
    //       g.setTime(g.getTime())
    //       g = new Date(g.getTime() + b)
    //       document.cookie = "XCLGFbrowser=" + window.DW_anonc.id + (b ? ";expires=" + (g as any).toGMTString() : "") + ";path=/"
    //       if (document.cookie.indexOf("XCLGFbrowser=") != -1) {
    //         // console.log('New DW cookie set, exec sitecat')
    //         window.utag_data._dwAnonId = window.DW_anonc.id
    //       }
    //     }
    //   }
    // }
    // NOTE LL: Setting the defer to false because of the iframe tracking per tracking team
    await loadScript(`//tags.tiqcdn.com/utag/cbsi/cbssportssite/${constants.APP_ENV === "prod" ? "prod" : "qa"}/utag.js`, false, true)
    sdkReady = true
    enqueue()
    if (!currentState.isCbsAppWebview) {
      const f = window
      // tslint:disable-next-line
      const n: any = (f.fbq = function () {
        // eslint-disable-next-line prefer-spread, prefer-rest-params
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      })
      if (!f._fbq) {
        f._fbq = n
      }
      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      window.fbq("init", "856629357759542")
      window.fbq("track", "PageView")
      loadScript("https://connect.facebook.net/en_US/fbevents.js").catch(console.error)
    }
  }
}

// "siteedition" : "us",
// "sitetype" : "[devicetype] web",
// "userid" : "[userId]",
// "userstate" : "[userState]",
// "usertype" : "[userType]",
// {"userId":24585035,"userState":"authenticated","userType":"registered"}
// let hasConsent = false
// const needsConsentCheckSchedule = true
const processQueue = () => {
  // if (needsConsentCheckSchedule) {
  //   needsConsentCheckSchedule = false
  //   processQueue()
  // const optanonSdk = getCbsoptanonSdk()
  // optanonSdk.onScriptsReady((cmp) => {
  //   // addMsg({eventType: 'init', payload: {msg: `onScriptsReady onetrust, requesting consent...`}})
  //   // fire off tealium if the banner is not present after
  //   // onetrust has loaded. (cbsoptanon has a promise polyfill)

  //   // This was inside of awaitInitialConsent but bringing this out since we do not need to wait for the initial optanon consent to load utag data.
  //   // As long as the optanon script is getting called, we can load utag data and fire the utag.
  //   processQueue()

  //   // cmp.ot.awaitInitialConsent(() => {
  //   //   processQueue()
  //   //   hasConsent = true
  //   // })
  // })
  // }
  // if (!hasConsent) {
  //   // addMsg({eventType: 'init', payload: {msg: `waiting on consent...`}})
  //   return null
  // }
  const utag_data = window.utag_data
  const utag = window.utag
  const om = window.om
  timeoutId = null

  if (sdkReady && utag && om && utag_data) {
    while (queue.length > 0) {
      const evt = queue.shift()
      if (evt) {
        addMsg(evt)
        const { eventType, payload } = evt
        // reset click-tracking variables
        delete utag_data.moduleAction
        delete utag_data.moduleCampaign
        delete utag_data.moduleLocation
        delete utag_data.moduleName
        delete utag_data.clickText
        delete utag_data._clickText
        delete utag_data.challengeStatus
        delete utag_data.countOfBrackets
        delete utag_data.sponsorName
        delete utag_data.nissanLogoImpression
        delete utag_data.attLogoImpression
        // update user data for tracking that was queued up on page load before baseState got updated from fly_tracking cookie
        if (!payload.userType && baseState.userType) {
          payload.userType = baseState.userType
          payload.userState = baseState.userState
          payload.userId = baseState.userId
        }
        om.getHbParams(Object.assign(utag_data, payload))
        if (eventType === "interaction") {
          delete utag_data.link_id
          om.trackClick({ item: payload.link_id })
          // Analytics.trackInteraction("name entry - success");
          if (payload.createEntry && window.fbq) {
            window.fbq("track", "CompleteRegistration")
          }
        } else if (eventType === "persist") {
          if (typeof window !== "undefined") {
            try {
              window.localStorage.setItem(trackingFlagsLSKey, JSON.stringify(trackingFlags))
            } catch (err) {
              console.error(err)
            }
          }
        } else if (eventType === "screen") {
          om.trackState(payload.title, payload)
          // om.trackView({ item: payload })

          if (window.fbq) {
            window.fbq("track", "PageView")
          }
          // addMsg("Screen View: '" + s.subfeature + "'");
        } else if (eventType === "action") {
          if (payload.clickText === "open the app") {
            om.trackAction(`app downloads`, payload)
          } else {
            om.trackAction(`${payload.pageType} interaction`, payload)
          }
        } else {
          throw new Error(`unknown type: ${eventType}`)
        }
      }
    }
  } else {
    // addMsg({eventType: 'init', payload: {msg: `processQueue.init - backoffMs: ${backoffMs}`}})
    init()
    timeoutId = setTimeout(processQueue, backoffMs)
    backoffMs = backoffMs * 2
  }
  return undefined
}

const enqueue = (event?: QueueEvent) => {
  if (constants.APP_ENV === "local") {
    // return
  }
  if (event) {
    queue.push(event)
  }
  if (!timeoutId) {
    timeoutId = requestIdleCallback(processQueue)
  }
}

const setContext = (state: TContext) => {
  Object.assign(currentState, state)
}

const underscored = (str: string) => str.replace(" ", "_").toLowerCase()

const buildState = (additional: any) => {
  const { channel, productLine, serviceLevel, feature, subfeature, title } = currentState
  const underscoredSubfeature = underscored(subfeature)
  // pageName cannot include productLine and serviceLevel in the path for the bpc create entry modal or picks saved modal
  const pageNameItems = [
    channel,
    ...(["bracket-challenge-entry", "success"].includes(subfeature) ? [] : [productLine, serviceLevel]),
    feature,
    underscoredSubfeature,
  ]
  const isMarchMadness = typeof window !== "undefined" && /ncaa(w?)-tournament/.test(window.location.pathname)
  const pageType = feature === "brackets" || isMarchMadness ? "brackets" : baseState.pageType
  return Object.assign(additional, baseState, {
    pageName: "/" + pageNameItems.join("/"),
    trackingMethodAction: [channel, productLine, serviceLevel, feature, subfeature, title].join("/"),
    siteHier: [project, channel, productLine, serviceLevel, feature, underscoredSubfeature].join("|"),
    siteSection: [channel, productLine, serviceLevel, feature].join("|"),
    pageType,
  })
}

// ex: trackInteraction('make-pick', 'event48'), trackInteraction('make-pick')
const trackInteraction = (linkId: string, extra?: any) => {
  const payload = buildState({
    link_id: linkId,
  })
  if (extra) {
    Object.assign(payload, extra)
  }
  // console.debug(`trackInteraction: ${linkId}`);
  enqueue({
    eventType: "interaction",
    payload,
  })
}

// ex: trackScreen('/authmodal'), trackScreen('/groups/index')
const trackScreen = (
  feature: string,
  subfeature: string,
  title: string,
  productLine: string,
  isModal?: boolean,
  isMobile?: boolean,
  extra: Record<string, any> = {},
) => {
  if (
    currentState.title !== title ||
    currentState.subfeature !== subfeature ||
    currentState.feature !== feature ||
    currentState.productLine !== productLine ||
    feature === "brackets"
  ) {
    // capture here so that interactions (buffered) will make sure to capture the correct screen value
    currentState.title = title
    currentState.feature = feature
    currentState.subfeature = subfeature
    currentState.productLine = productLine
    const deviceType = isMobile ? "mweb" : "desktop"
    const payload = buildState({
      pageViewGuid: getVGUID("", isModal),
      deviceType,
      brandPlatformId: [baseState.siteCode, "site", deviceType].join("_"),
    })
    // console.debug(`trackScreen: ${title}`);
    Object.assign(payload, extra)
    enqueue({
      eventType: "screen",
      payload,
    })
  }
}

const trackAction = async (
  moduleName: string,
  moduleLocation: string,
  clickText: string,
  moduleAction = "click",
  extra: Record<string, any> = {},
) => {
  const isClickTrackingCall = moduleAction === "click"
  const actionFields = {
    moduleName,
    moduleLocation,
    ...(isClickTrackingCall && { clickText }),
    moduleAction,
  }
  const payload = await buildState(actionFields)
  const isMarchMadness = typeof window !== "undefined" && /ncaa(w?)-tournament/.test(window.location.pathname)
  const isBrackets = currentState.feature === "brackets" || isMarchMadness
  const isChallenge = /opc/.test(currentState.productLine)
  const location = isBrackets ? moduleLocation : payload.pageName
  if (isClickTrackingCall) {
    const updatedClickText = isBrackets
      ? clickText
      : [TRACKING_ABBREV_LOOKUP[currentState.productLine] || currentState.productLine, clickText].join("_")
    Object.assign(payload, { clickText: updatedClickText })
  }
  const moduleCampaign = isBrackets ? payload.pageType || currentState.feature : `pickem ${isChallenge ? "challenge" : "manager"}`

  Object.assign(payload, { moduleCampaign: moduleCampaign, moduleLocation: location, ...extra })
  enqueue({
    eventType: "action",
    payload,
  })
}

const trackingFlagsLSKey = "__sh-trackingFlags"
let trackingFlags = null as any | null
const getFlag = (key: string) => {
  if (!trackingFlags && typeof window !== "undefined") {
    try {
      trackingFlags = JSON.parse(window.localStorage.getItem(trackingFlagsLSKey) || "{}")
    } catch (err) {
      console.error(err)
      trackingFlags = {}
    }
  }
  // console.dir(trackingFlags)
  return trackingFlags[key] || null
}
const setFlag = (key: string, value: any) => {
  const currentFlag = getFlag(key)
  if (value !== currentFlag) {
    trackingFlags[key] = value
    enqueue({
      eventType: "persist",
      payload: {
        key,
        value,
      },
    })
  }
  return trackingFlags[key] || null
}
export const trackSharedLib = (type, props) => {
  const { trackingElt } = props
  if (trackingElt) {
    if (type === "trackState") {
      console.log("trackScreen for trackingElt", trackingElt)
      trackScreen(trackingElt.pageName, trackingElt.pageName, trackingElt.pageName, trackingElt.pageName)
    } else if (type === "trackAction") {
      console.log("trackAction for trackingElt", trackingElt)
      const { moduleName, moduleLocation, clickText, moduleAction } = trackingElt.args || {}
      trackAction(moduleName, moduleLocation, clickText, moduleAction)
    }
  }
}

const Analytics = {
  currentState,
  trackInteraction,
  trackScreen,
  trackAction,
  setFlag,
  getFlag,
  setContext,
  trackSharedLib,
}

export default Analytics
