import { useMutation } from "@apollo/client"
import { deepDup, emptyVariable, filterNonFalsy } from "../../common/misc-utils"
import { EntryPeriodPointsGlanceQuery, EntryPeriodPointsGlanceQueryVariables } from "../../__generated__/EntryPeriodPointsGlanceQuery"
import { ENTRY_PERIOD_POINTS_GLANCE_QUERY, UPSERT_ENTRY_MUTATION, ENTRY_DETAILS_QUERY, ENTRY_DETAILS_FRAGMENT } from "../App/queries"
import { UpsertEntryMutation, UpsertEntryMutationVariables } from "../../__generated__/UpsertEntryMutation"
import { SeasonEnumType } from "../../__generated__/globalTypes"
import { managerModeEnabledCookieName, managerModeHeaderName } from "../../common/constants"
import { get as getCookie } from "es-cookie"
import { canUseDom } from "../utils/misc-utils"

function useUpsertEntryMutation(entryId?: string | null, season?: SeasonEnumType, year?: number, tournamentCount = 0, skipPoolData = false) {
  const entryPeriodPointsGlanceQueryVariables = {
    entryId: entryId || emptyVariable,
    season: season || emptyVariable,
    year: year || 0,
    first: tournamentCount,
  }
  let isManagerModeActive = false
  if (canUseDom) {
    const isManagerModeActiveCookie = getCookie(managerModeEnabledCookieName)
    isManagerModeActive = isManagerModeActiveCookie === "true"
  }
  const entryPeriodPointsGlanceQuerySkip = tournamentCount < 2 || !!Object.values(entryPeriodPointsGlanceQueryVariables).filter(filterNonFalsy).length
  const [upsertEntryMutation, upsertEntryMutationResult] = useMutation<UpsertEntryMutation, UpsertEntryMutationVariables>(UPSERT_ENTRY_MUTATION, {
    update(cache, other) {
      // If anon entry, we want to make sure we prevent the extra lookup + flash while looking up
      const resp = (other.data && other.data.upsertEntry) || undefined
      if (resp) {
        const entry = resp.entry
        const picksCount = entry.picks.length
        const periodId = picksCount && entry.picks[0].periodId
        if (!skipPoolData) {
          const entryDetailsVariables = {
            entryId: entry.id,
            periodId,
          }
          // if this is the first submit, we want to NOT load the detailed entry, and use the response instead!:
          cache.writeQuery({
            query: ENTRY_DETAILS_QUERY,
            data: { entry },
            variables: entryDetailsVariables,
          })
          // update central current users to reflect added entry + values:
          // const newCurrentUserFragment = resp.currentUser
          cache.writeFragment({
            id: `Entry:${entry.id}`,
            fragmentName: "EntryDetailsFragment",
            fragment: ENTRY_DETAILS_FRAGMENT,
            data: entry,
            variables: {
              skipPoolData,
            },
          })
        }

        // const frozenCentralUserResult = cache.readQuery({
        //   query: CENTRAL_CURRENT_USERS_ENTRIES_QUERY,
        // })
        // cache.writeQuery({
        //   query: CENTRAL_CURRENT_USERS_ENTRIES_QUERY,
        //   data: Object.assign(deepDup(frozenCentralUserResult), { currentUser: newCurrentUserFragment }),
        // })
        // update ENTRY_PERIOD_POINTS_GLANCE_QUERY
        if (!entryPeriodPointsGlanceQuerySkip) {
          // Read the data from our cache for this query.
          const frozenData = cache.readQuery<EntryPeriodPointsGlanceQuery, EntryPeriodPointsGlanceQueryVariables>({
            query: ENTRY_PERIOD_POINTS_GLANCE_QUERY,
            variables: entryPeriodPointsGlanceQueryVariables,
          })
          // console.log(`ENTRY_PERIOD_POINTS_GLANCE_QUERY:`);
          // console.dir(frozenData)
          if (frozenData && frozenData.entry) {
            const data = deepDup(frozenData) // as EntryPeriodPointsGlanceQuery
            const periodPointEdge = data?.entry.periodPoints.edges.find((edge) => edge.node?.periodId === periodId)
            const knownPicksCount = periodPointEdge?.node?.picksCount || -1
            // console.dir(periodPointEdge)
            // console.log(`knownPicksCount : ${knownPicksCount}, picksCount: ${picksCount}`);
            if (knownPicksCount !== picksCount) {
              if (knownPicksCount > -1) {
                // it exists, just update it
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                periodPointEdge!.node!.picksCount = picksCount
              } else {
                // periodPoint doesnt exist yet for this period! fake it!
                // NOTE qac: if this is the FIRST period we are picking, there will be no node... its ok to fake it until we get one
                const periodPointToCopy =
                  data.entry.periodPoints.edges.find((edge) => !!edge.node) ||
                  ({
                    node: { periodId, __typename: "PeriodPoint" },
                    __typename: "PeriodPointEdge",
                  } as never)
                const fakePeriodPointNode = JSON.parse(JSON.stringify(periodPointToCopy))
                Object.assign(fakePeriodPointNode.node, entry.periodPoint)
                data.entry.periodPoints.edges.push(fakePeriodPointNode)
              }
              cache.writeQuery<EntryPeriodPointsGlanceQuery, EntryPeriodPointsGlanceQueryVariables>({
                query: ENTRY_PERIOD_POINTS_GLANCE_QUERY,
                variables: entryPeriodPointsGlanceQueryVariables,
                data,
              })
              // console.log(`ENTRY_PERIOD_POINTS_GLANCE_QUERY UPDATED:`);
              // console.dir(data)
            }
          }
        }
      }
    },
    refetchQueries: skipPoolData
      ? undefined
      : ["CurrentUserPendingInvites", "PoolSeasonStandingsQuery", "CentralCurrentUsersEntriesQuery", "CurrentMembershipQuery"],
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActive,
      },
    },
  })

  return {
    upsertEntryMutation,
    upsertEntryMutationResult,
    entryPeriodPointsGlanceQueryVariables,
    entryPeriodPointsGlanceQuerySkip,
  }
}

export default useUpsertEntryMutation
