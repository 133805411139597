import React from "react"
import { Route } from "react-router"
import { MobileBackBtnStyled } from "../styles/CommonPoolSetup.styles"

export const MobileBackBtn = () => (
  <Route>
    {({ history }) => (
      <MobileBackBtnStyled as="button" type="button" onClick={() => history.goBack()}>
        <div className="icon-moon-chevron-left" />
        BACK
      </MobileBackBtnStyled>
    )}
  </Route>
)
export default MobileBackBtn
