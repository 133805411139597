import { useMutation } from "@apollo/client"
import { Field, Form, Formik } from "formik"
import * as React from "react"
import { useHistory } from "react-router"
import styled from "styled-components/macro"
import { CentralGameInstancesQuery_allSegments } from "../../../../__generated__/CentralGameInstancesQuery"
import { CentralPoolDetailsFragment } from "../../../../__generated__/CentralPoolDetailsFragment"
import { UpsertPoolMutation, UpsertPoolMutationVariables } from "../../../../__generated__/UpsertPoolMutation"
import buildMappingFor from "../../../../common/game-text"
import { withoutDomain } from "../../../../common/url-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonSmallBase } from "../../../components/Button"
import { extractValidationError, FormButtonSpinnerBase, FormErrors, FormSuccessStatus } from "../../../components/Form"
import Link from "../../../components/Link"
import { Logo } from "../../../components/Logos"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import { fontWeight, pxToRem } from "../../../utils/style-utils"
import { UPSERT_POOL_MUTATION } from "../../queries"
import constants from "../../../../common/constants"
import Modal, { ModalWrapper } from "../../../components/Modal"

const px240 = pxToRem(240)

const NativeAppPromoWrapper = styled.div`
  text-align: center;
  line-height: 1.4em;
  & > * {
    margin-bottom: ${pxToRem(16)};
  }
  & h3 {
    font-weight: ${fontWeight.bold};
    font-size: 1.2em;
  }
  & .game-logo {
    display: block;
    padding: 0 7vw;
    svg {
      max-height: ${px240};
      max-width: ${px240};
    }
  }
  & button,
  a {
    margin: 0 auto;
    display: inline-block;
  }
`

export interface IReactivateProps {
  allSegments: CentralGameInstancesQuery_allSegments[] | null
  outOfSeasonId?: string
  poolDetail: CentralPoolDetailsFragment
  isReinvited: boolean
  hasManagerRole: boolean
}

interface IReactivateModalProps extends IReactivateProps {
  isOpen: boolean
  onClose: () => void
  afterClose?: () => void
}
const ReactivatePoolModal: React.FC<IReactivateModalProps> = (props) => {
  // console.debug(`PoolPageArchivedBanner`);
  // console.dir(props);
  const { allSegments, poolDetail, isReinvited, hasManagerRole, outOfSeasonId, isOpen, onClose, afterClose } = props
  if (!allSegments) {
    return null
  }
  // const poolDetail = detailedEntry
  const formerSegment = allSegments?.find((ses) => poolDetail?.season.id === ses?.season.id)
  if (!formerSegment) {
    return null
  }
  const formerSeason = formerSegment.season
  const currentSegment = outOfSeasonId
    ? allSegments.find((ses) => ses.season.id === outOfSeasonId)
    : allSegments.find((ses) => ses.season.isCurrent && formerSeason.gameInstance.uid === ses.season.gameInstance.uid)
  if (!currentSegment) {
    return null
  }
  const currentSeason = currentSegment.season
  const isReactivated = !!poolDetail.currentSeasonPoolId // !!poolsReactivatedSegment && poolsReactivatedSegment.season.gameInstance.uid === poolDetail.gameInstanceUid;
  const newSeasonIsSameProduct = currentSeason.season === formerSeason.season && currentSeason.productAbbrev === formerSeason.productAbbrev
  const needsToReactivate = !isReactivated && !newSeasonIsSameProduct
  const needsToRollover = !isReactivated && newSeasonIsSameProduct
  const canReactivateOrRollover = needsToReactivate || needsToRollover
  const isAllowed = hasManagerRole || constants.ALLOW_NON_MANAGERS_TO_REACTIVATE_POOLS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory()
  const initValues = {
    poolId: poolDetail.id,
    seasonId: poolDetail.season.id,
    reactivateForSeasonId: currentSegment.season.id,
    // seasonId: string;
    // poolId?: string | null;
    // name?: string | null;
    // password?: string | null;
    // poolSettings?: any | null;
    // constitution?: string | null;
    // reactivateForSeasonId?: string | null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [upsertPoolMutation] = useMutation<UpsertPoolMutation, UpsertPoolMutationVariables>(UPSERT_POOL_MUTATION, {
    refetchQueries: ["CentralCurrentUsersEntriesQuery"],
    awaitRefetchQueries: true,
  })
  const onSubmit = (values, actions) => {
    upsertPoolMutation({ variables: values })
      .then((_res) => {
        // console.log("res");
        // console.dir(_res);
        const pool = _res.data?.upsertPool?.pool
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        setTimeout(() => {
          if (pool) {
            // NOTE qac: drop managers into create (update) flow, non-managers into settings view
            const url = withoutDomain(
              hasManagerRole ? `${currentSegment.landingUrl}/pool/${pool.id}${hasManagerRole ? "/step/recap" : "/settings"}` : `${pool.url}/settings`,
            )
            history.push(url)
          }
        }, 900)
      })
      .catch((err) => {
        // console.log("err");
        // console.dir(err);
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
      })
  }
  const currentSeasonText = buildMappingFor(currentSeason.gameInstance.uid, currentSeason.season, currentSeason.year, currentSeason.productAbbrev)
  const modalHeader = (hasManagerRole && `Reactivate Your Pool Now`) || (isReinvited && `Join Current Season`) || currentSeasonText.seasonText
  const modalH3 =
    // (canReactivateOrRollover && hasManagerRole && `Get Your Pool Ready for ${currentSeasonText.yearSpan}`) ||
    (canReactivateOrRollover && hasManagerRole && `Reactivate your pool for the ${currentSeason.year} season`) ||
    (canReactivateOrRollover && `Contact your manager to reactivate this pool for the ${currentSeason.year} season.`) ||
    "Get back in the action this season!"
  const modalP =
    // (canReactivateOrRollover && hasManagerRole && `You can reactivate this pool by tapping the button below.`) ||
    (canReactivateOrRollover &&
      hasManagerRole &&
      `Bring back, ${poolDetail?.name} and challenge your friends to a brand new season of ${currentSeasonText.title}.`) ||
    // (canReactivateOrRollover && hasManagerRole && `You can reactivate this Pool and challenge your friends to the new season!`) ||
    (canReactivateOrRollover &&
      isAllowed &&
      `Challenge the players in '${poolDetail.name}' to the new season of ${currentSeasonText.title}.  Your managers will be notified so they can join and re-invite the crew!`) ||
    (canReactivateOrRollover ? "" : "Your manager has activated this pool. Tap the button below to get started.")

  const modalCTA =
    (canReactivateOrRollover && isAllowed && `Reactivate Pool`) ||
    (!(!isReactivated && !hasManagerRole) &&
      isReinvited &&
      // is re-invited
      `Join Now`) ||
    // cannot contact manager... yet
    ``
  const modalCtaTo = (isReactivated && isReinvited && poolDetail.url.replace(poolDetail.id, poolDetail.currentSeasonPoolId!)) || undefined
  return (
    <Modal isOpen={isOpen} afterClose={afterClose}>
      <ModalWrapper header={modalHeader} closeBtnAction={onClose}>
        <ToggleNexusTheme isNexusStyles={true}>
          <Formik initialValues={initValues} onSubmit={onSubmit} validateOnChange={false} enableReinitialize={true}>
            {(formikBag) => {
              const { isSubmitting, status } = formikBag
              return (
                <NativeAppPromoWrapper as={Form}>
                  <AnalyticScreen subfeature={`pool rollover modal`} title={`Pool Rollover Modal`} isModal={true} />
                  <Field name="poolId" type="hidden" />
                  <Field name="seasonId" type="hidden" />
                  <Field name="name" type="hidden" />
                  <Field name="password" type="hidden" />
                  <Field name="reactivateForSeasonId" type="hidden" />
                  <Logo gameInstanceUid={poolDetail.gameInstanceUid} segment={currentSegment} className="game-logo" />
                  <h3>{modalH3}</h3>
                  <p>{modalP}</p>
                  {(modalCTA && !modalCtaTo && (
                    <FormButtonSpinnerBase
                      as={ButtonSmallBase}
                      success="Success!"
                      type="submit"
                      inert={modalCTA}
                      status={status}
                      isSubmitting={isSubmitting}
                      isValid={true}
                    />
                  )) ||
                    (modalCTA && (
                      <ButtonSmallBase as={Link} to={modalCtaTo || ""}>
                        {modalCTA}
                      </ButtonSmallBase>
                    )) ||
                    null}
                  <FormErrors {...formikBag} />
                </NativeAppPromoWrapper>
              )
            }}
          </Formik>
        </ToggleNexusTheme>
      </ModalWrapper>
    </Modal>
  )
}
export default ReactivatePoolModal
