import React from "react"

export const Spinner = ({ color = "currentColor", size = 16, style = {}, innerColor = "" }) => (
  <svg
    role="presentation"
    className="spinner"
    style={style}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20 20 60 60"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      strokeLinecap="round"
      r="25"
      strokeWidth="5"
      stroke={color}
      strokeDasharray="39.269908169872416 39.269908169872416"
      transform="rotate(360 0 0)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      cx="50"
      cy="50"
      fill="none"
      strokeLinecap="round"
      r="19"
      strokeWidth="5"
      stroke={innerColor || color}
      strokeDasharray="29.845130209103033 29.845130209103033"
      strokeDashoffset="29.845"
      transform="rotate(-360 0 0)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;-360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)
export default Spinner
