import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { getVGUID, canUseDom } from "../utils/misc-utils"
import { emptyArray, emptyObject } from "../../common/misc-utils"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"

interface ITrackingProps {
  impressionUrl: string
}

const TrackingImage: React.FC<ITrackingProps> = (props: ITrackingProps) => {
  const { impressionUrl } = props
  return <img src={impressionUrl} height="1" width="1" alt="Advertisement" />
}

interface IWilliamHillLinkProps {
  pathname: string
  target?: string
  rel?: string
  Component?: React.ReactNode
  placement: string
}

const LinkA = styled.a``

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    surround: any
  }
}

const selectionIdsRegex = new RegExp("#SELECTIONIDS#", "gi")
const vguuidRegex = new RegExp("#VGUID#", "gi")
const timestampRegex = new RegExp("#TIMESTAMP#", "gi")

const WilliamHillLink: React.FunctionComponent<IWilliamHillLinkProps> = ({ pathname, target, rel, Component, children, placement }) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)

  const [vguid, setVguid] = useState("")
  const [impressionUrlToUse, setImpressionUrlToUse] = useState("")
  const [linkUrlToUse, setLinkUrlToUse] = useState("")
  const [show1x1Tracking, setShow1x1Tracking] = useState(false)
  const gamblingPartnerInfo = poolDataContext?.centralCurrentUsersEntriesQuery.data?.gamblingPartnerInfo || emptyObject
  const placements = gamblingPartnerInfo?.placements || emptyArray
  const placementValues = placements.find((values) => values.name === placement) || emptyObject
  const hasPlacement = !!placementValues.name
  const { linkUrl, impressionUrl } = placementValues

  // GamblingPartnerAdPlacement

  useEffect(() => {
    // NOTE LL: Only want to show a link if we have a link for a region
    if ((impressionUrl || linkUrl) && hasPlacement) {
      const surround = (canUseDom && window.surround) || {}
      setVguid(surround.vguid || getVGUID())
    }
  }, [hasPlacement, impressionUrl, linkUrl, pathname])

  useEffect(() => {
    if (vguid && hasPlacement) {
      const timestamp = Date.now()
      setImpressionUrlToUse(impressionUrl?.replace(vguuidRegex, vguid).replace(timestampRegex, `${timestamp}`).replace(selectionIdsRegex, "") || "")
      setLinkUrlToUse(linkUrl?.replace(vguuidRegex, vguid).replace(selectionIdsRegex, "") || "")
    }
  }, [hasPlacement, linkUrl, impressionUrl, vguid])

  useEffect(() => {
    if (impressionUrlToUse && hasPlacement) {
      setShow1x1Tracking(canUseDom)
    }
  }, [hasPlacement, impressionUrlToUse])

  if (hasPlacement) {
    return (
      <>
        {show1x1Tracking && impressionUrlToUse && <TrackingImage impressionUrl={impressionUrlToUse} />}
        {linkUrlToUse && (
          <LinkA as={Component ? (Component as never) : "a"} href={linkUrlToUse} target={target || "_blank"} rel={rel || "noopener nofollow"}>
            {children}
          </LinkA>
        )}
      </>
    )
  }
  return null
}

export default WilliamHillLink
