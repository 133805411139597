import BaseGlobalStyles from "@cbs-sports/sports-shared-client/build/cjs/components/BaseGlobalStyles"
import { ToastContainer } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import styled from "styled-components"
import * as React from "react"
import constants from "../../../../common/constants"
import BannerAd from "../../../components/BannerAd"
import Helmet from "../../../shared/react-helmet-async/Helmet"
// import BidBarrel from "../../../utils/bid-barrel"
import { fontFaces } from "../../../utils/style-utils"
import FixedBgScreen from "../../NonPoolPages/styles/FixedBgScreen.styles"
import { GlobalStyle, Page, PageContent, RootWrapper, WC2, Wrapper, GlobalCssVariableStyles } from "../styles/Layout.styles"
import { ILayoutPropsWithComponent } from "../typings"
import SiteNav from "./SiteNav"
import SiteFooter from "./SiteFooter"
import { isMarchMadnessProductAbbrevMatcher } from "../../../../common/common-utils-helpers"
import { zIndexes } from "@cbs-sports/sports-shared-client/build/cjs/utils"

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-auto-rows: auto;
  grid-gap: var(--grid-gap);
`

export const MainWrapper = styled.div`
  padding: var(--grid-gap) var(--grid-margin);
`

export const defaultProps = {
  isLoading: true,
  hideMobileNav: false,
  isChallengePool: false,
  isPoolMember: false,
  hasCbsAccount: false,
  hasFantasyUser: false,
  geoInfo: undefined,
  unreadMessageCount: 0,
  hasManagerRole: false,
  adType: "none",
} as ILayoutPropsWithComponent
// const isBrowser = typeof(window) !== 'undefined'

const GlobalElements = React.memo(({ fixedRootFontSize = false }: { fixedRootFontSize?: boolean }) => {
  return (
    <>
      <GlobalCssVariableStyles />
      <GlobalStyle fixedRootFontSize={fixedRootFontSize} />
      <BaseGlobalStyles />
      <FixedBgScreen isRoot={true} />
    </>
  )
})

const PrivacyLinkScript = React.memo(() => {
  return (
    <Helmet>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={`a60e161f-a66d-414e-adaa-217c83fc6ae0${constants.IS_PROD ? "" : "-test"}`}
      />
    </Helmet>
  )
})
const PrivacyOptanonScript = () => {
  return (
    <Helmet>
      <script id="bb-cbsoptanon" src="https://cdn.privacy.paramount.com/dist/optanon-v1.1.0.js" />
    </Helmet>
  )
}
class Layout extends React.PureComponent<ILayoutPropsWithComponent & { isAdminRoute: boolean }> {
  public static defaultProps = defaultProps

  // public componentDidMount() {
  //   const isHandheld = this.props.deviceType === "handheld"
  //   const adSlot = isHandheld ? "mobile_banner_top" : "skybox_leader_sticky"
  //   const domValues = BidBarrel.getDomValues({ adSlot, adLayoutProps: this.props })
  //   BidBarrel.register(domValues)
  // }

  public render() {
    // console.log("Layout");
    // console.dir(this.props);
    // console.dir(themes);
    const { children, sidebarComponent, outsideComponent, isInDebugMode, isAdminRoute, ...layoutProps } = this.props
    if (isAdminRoute) {
      return children
    }
    const isIosBrowser = !!this.props.isIosBrowser
    const isAndroidBrowser = !!this.props.isAndroidBrowser
    const { hideMobileNav, adType, isCbsAppWebview, productAbbrev } = layoutProps
    const layoutId = layoutProps.layoutId || constants.layoutIds.default
    const hasAd = !!adType && adType !== "none"
    const disableMomentumScrolling = false // !!(isIosBrowser && isCbsAppWebview)
    const useBodyScroll = !!layoutProps.useBodyScroll
    // console.log(`theme:`, theme);
    const classes = [
      // `is-admin-layout--false`,
      `layout--${layoutId}`,
      `is-cbs-app-webview--${!!isCbsAppWebview}`,
      `hide-mobile-nav--${!!hideMobileNav}`,
      `is-ios-browser--${isIosBrowser}`,
      `is-android-browser--${isAndroidBrowser}`,
      `has-ad--${hasAd}`,
      `use-body-scroll--${useBodyScroll}`,
      `disable-momentum-scrolling--${disableMomentumScrolling}`,
    ]
    const isHandheld = layoutProps.deviceType === "handheld"
    const adSlot = isHandheld ? "mobile_banner_top" : "skybox_leader_sticky"
    const useFixedRootFontSize = productAbbrev ? isMarchMadnessProductAbbrevMatcher.test(productAbbrev) : false
    const mobileStyles: React.CSSProperties | undefined = isHandheld
      ? { display: "flex", zIndex: zIndexes.toolTip, alignItems: "center", justifyContent: "center", width: "100%" }
      : undefined
    return (
      <RootWrapper className={classes.join(" ")}>
        <GlobalElements fixedRootFontSize={useFixedRootFontSize} />
        <PrivacyLinkScript />
        <PrivacyOptanonScript />
        <Helmet>
          <html lang="en" className={classes.join(" ")} />
          {fontFaces.map((ff) => (
            <link key={ff.url} rel="preload" as="font" crossOrigin="anonymous" href={ff.url} />
          ))}
          <link rel="stylesheet" href="//sportsfly.cbsistatic.com/fly-920/bundles/sportsmediacss/css/icon-moon/icon-moon.css" />
        </Helmet>
        {hasAd && <BannerAd layoutProps={layoutProps} adSlot={adSlot} />}
        {!isCbsAppWebview && <SiteNav {...layoutProps} />}
        <Wrapper className={`has-sidebar--${!!sidebarComponent}`} id="layout-wrapper">
          {outsideComponent || null}
          {sidebarComponent || null}
          {useBodyScroll ? (
            <>
              {children}
              <SiteFooter {...layoutProps} />
              <ToastContainer />
            </>
          ) : (
            <WC2>
              <Page>
                <PageContent id="sh-scroll-container">
                  {children}
                  <div id="picks-count-container"></div>
                  <SiteFooter {...layoutProps} />
                </PageContent>
                <ToastContainer style={mobileStyles} />
              </Page>
            </WC2>
          )}
        </Wrapper>
      </RootWrapper>
    )
  }
}

export default Layout
