import * as React from "react"
import { ThemeContext } from "styled-components"
import { defaultAppStoreLink } from "../../../../common/game-text"
import { CentralSeasonFragment } from "../../../../__generated__/CentralSeasonFragment"
import { ButtonBase } from "../../../components/Button"
import CBSSportsAppSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CBSSportsApp"
import Link from "../../../components/Link"
import OpacityLink from "../../../components/OpacityLink"
import Analytics from "../../../utils/analytics"
import { ComingSoonAppLinks, ComingSoonAppRow } from "../styles/SplashViews.styles"

interface IAppLinksProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  gameInstanceUid?: string
  seasonType?: string
  year?: number
  season?: CentralSeasonFragment
}
export const AppLogo = ({ gameInstanceUid, seasonType, year, season: productSeason, ref, ...props }: IAppLinksProps) => {
  const context = React.useContext(ThemeContext)
  const season = productSeason || context.season
  return (
    <OpacityLink
      {...props}
      to={season?.appPromoUrl || defaultAppStoreLink}
      target="_blank"
      onClick={() => Analytics.trackInteraction("nativepromo - logo")}
    >
      <CBSSportsAppSvg />
    </OpacityLink>
  )
}

export const AppLink: React.FC<IAppLinksProps> = ({ gameInstanceUid, seasonType, year, season: productSeason, ref, ...props }) => {
  const context = React.useContext(ThemeContext)
  const season = productSeason || context.season
  return (
    <ButtonBase
      as={Link}
      target="_blank"
      onClick={() => Analytics.trackInteraction("nativepromo - cta")}
      {...props}
      to={season?.appPromoUrl || defaultAppStoreLink}
    />
  )
}

export const AppLinks = ({ gameInstanceUid, seasonType, year, season: productSeason, ref, ...props }: IAppLinksProps) => {
  const context = React.useContext(ThemeContext)
  const season = productSeason || context.season
  return (
    <ComingSoonAppLinks {...props}>
      <ComingSoonAppRow>
        <OpacityLink to={season?.appPromoUrl || defaultAppStoreLink} target="_blank" onClick={() => Analytics.trackInteraction("nativepromo - ios")}>
          <img src="https://sports.cbsimg.net/images/mobile/app_button_applev2.png" alt="iTunes Store" loading="lazy" />
        </OpacityLink>
        <OpacityLink
          to={season?.appPromoUrl || defaultAppStoreLink}
          target="_blank"
          onClick={() => Analytics.trackInteraction("nativepromo - android")}
        >
          <img src="https://sports.cbsimg.net/images/mobile/app_button_googleplayv2.png" alt="Google Play Store" loading="lazy" />
        </OpacityLink>
      </ComingSoonAppRow>
    </ComingSoonAppLinks>
  )
}
