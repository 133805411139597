import React from "react"
// import { useMutation } from "@apollo/client"
// import {
//   markInviteAllEmailAsSentMutation,
//   markInviteAllEmailAsSentMutationVariables,
// } from "../../../../__generated__/markInviteAllEmailAsSentMutation"
// import { formatPossessiveName } from "../../../utils/data-utils"
// import { MARK_INVITE_ALL_EMAIL_AS_SENT_MUTATION } from "../../queries"
import { MultipleEntriesPoolInviteMailCenterModal } from "../containers/MultipleEntriesPoolInviteMailCenter"
import { TPoolRouteProps } from "../../../../routes.d"
import { getGameText } from "../../../../common/game-text"

interface IProps extends TPoolRouteProps {
  isOpen: boolean
  onClose: () => void
  onInviteSent: () => void
}
const POOL_NAME_REPLACE = "POOL_NAME_REPLACE"
const InviteAllPreviousPoolMembersModal: React.FC<IProps> = (props) => {
  // const [markInviteAllEmailAsSentMutation] = useMutation<markInviteAllEmailAsSentMutation, markInviteAllEmailAsSentMutationVariables>(
  //   MARK_INVITE_ALL_EMAIL_AS_SENT_MUTATION,
  // )
  const { poolData } = props
  const { poolDetail, segmentsForArea, productSeason, gameInstanceForArea, gameInstancesForArea } = poolData
  // const name = currentUser?.preferredEntryName || ""
  // const { id: poolId } = poolDetail || {}
  // const formattedName = formatPossessiveName(name)
  const poolName = poolDetail?.name || ""

  const segmentToUse =
    segmentsForArea &&
    productSeason &&
    segmentsForArea.find((seg) => (productSeason ? seg.season.id === productSeason.id : !!seg.season.challengePoolSlug))
  if (!segmentToUse) {
    return null
  }
  const gameInstance = gameInstanceForArea || gameInstancesForArea.find((gi) => gi.id === segmentToUse.season.gameInstance.id)
  if (!gameInstance) {
    return null
  }

  const gameInstanceUid = gameInstance.uid
  const { inviteBackModalMessage } = getGameText(gameInstanceUid, segmentToUse)
  const defaultMessage = inviteBackModalMessage?.replace(POOL_NAME_REPLACE, poolName ? `, ${poolName},` : "") || ""

  // const handleOnInviteSent = () => {
  //   markInviteAllEmailAsSentMutation({ variables: { poolId: poolId || "" } })
  //     .then((_res) => {
  //       props.onInviteSent()
  //     })
  //     .catch((err) => window.SH_ERROR_NOTIFY(err, "mark invite all as sent"))
  // }
  return (
    <MultipleEntriesPoolInviteMailCenterModal
      {...props}
      defaultMessage={defaultMessage}
      // defaultSubject={`${formattedName} Bracket Pool is Back and You're Invited!`}
      onNotificationSent={props.onInviteSent}
      variant={"welcome-back-invitation"}
    />
  )
}

export default React.memo(InviteAllPreviousPoolMembersModal)
