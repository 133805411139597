import * as React from "react"
import constants, { NCAAB_BRACKET_LOCKS_AT_TIMESTAMP } from "../../../../common/constants"
import { TPoolRouteProps } from "../../../../routes.d"
import Ad from "../../../components/Ad"
import { extractPoolOgProps } from "../../../hooks/usePoolData"
import { IPageLayoutProps } from "../../../shared/molecules/PageLayout/PageLayout"
import BidBarrel from "../../../utils/bid-barrel"
import { getLayoutId, withoutDomain } from "../../../utils/url-utils"
import { defaultProps } from "../../Layout/components/Layout"
import { MultipleEntriesPerUserRightColCont } from "./MultipleEntriesPerUserRightCol.styles"
import WhenToWatch from "./WhenToWatch"
import VideoPlayer from "./VideoPlayer"
import SportsHQLiveCard from "./SportsHQLiveCard"
import { SportTypesEnum } from "../../../../common/enums"

interface IMultipleEntriesPerUserRightCol extends TPoolRouteProps, IPageLayoutProps {}
export const MultipleEntriesPerUserRightCol: React.FC<IMultipleEntriesPerUserRightCol> = (props) => {
  const { location, match, poolData } = props
  const { pathname } = location
  const {
    centralBracketState,
    deviceType,
    hasManagerRole,
    isIosBrowser,
    isAndroidBrowser,
    isCbsAppWebview,
    hasCbsAccount,
    hasFantasyUser,
    poolDetail,
    detailedEntry,
    productSeason,
    isChallengePool,
    segmentForArea,
  } = poolData
  const { params } = match
  const sportType = segmentForArea?.sportType
  const ogProps = extractPoolOgProps(poolData)
  const layoutId = getLayoutId(params, ogProps.seasonType)
  const isPoolMember = !!detailedEntry
  const poolRootPathname = (poolDetail && withoutDomain(poolDetail.url)) || undefined
  const layoutProps = Object.assign({}, defaultProps, {
    layoutId,
    isChallengePool,
    isPoolMember,
    hasManagerRole,
    poolRootPathname,
    pathname,
    hasCbsAccount,
    hasFantasyUser,
    isInComingSoon: centralBracketState?.bracketState?.isPreTournament || true,
    isCbsAppWebview,
    isIosBrowser,
    isAndroidBrowser,
    productAbbrev: (productSeason && productSeason.productAbbrev) || "",
    deviceType,
  })
  const sideAdProps = BidBarrel.getDomValues({
    adLayoutProps: layoutProps,
    adSlot: "mpu_top",
    requestedPos: "sidebar",
  })

  return (
    <MultipleEntriesPerUserRightColCont>
      {sportType === SportTypesEnum.NCAAB && (
        <>
          <SportsHQLiveCard locksAt={centralBracketState?.bracketState?.locksAt || NCAAB_BRACKET_LOCKS_AT_TIMESTAMP} />
          <VideoPlayer />
        </>
      )}
      {<Ad adSlot={"mpu_top"} useShSkybox={false} {...BidBarrel.extractProps(sideAdProps)} /> || null}
      {sportType && constants.sportslineIframes[sportType] && (
        <iframe title="Sportsline Articles" height="400px" width="auto" seamless={true} src={constants.sportslineIframes[sportType]} /> //we are using px units here to match the units being used in the iframe
      )}
      <WhenToWatch season={productSeason} />
    </MultipleEntriesPerUserRightColCont>
  )
}

export default MultipleEntriesPerUserRightCol
