import invariant from "invariant"
import * as React from "react"
import styled, { ThemeContext } from "styled-components/macro"
import {
  CentralGameInstancesQuery_allSegments,
  CentralGameInstancesQuery_gameInstances_edges_node,
} from "../../__generated__/CentralGameInstancesQuery"
import { CentralSeasonFragment } from "../../__generated__/CentralSeasonFragment"
import { CentralSegmentDbAttrsFragment } from "../../__generated__/CentralSegmentDbAttrsFragment"
import { customCopyHtml, customCopyLinkClassname, customCopyTextClassname } from "../../common/game-text"
import { emptyObject } from "../../common/misc-utils"
import { IFullPoolSettings } from "../../common/pool-settings"
import { zIndexes } from "../utils/style-utils"

const CustomCopySc = styled.span`
  position: relative;
  & .${customCopyLinkClassname} {
    font-size: 0.6rem;
    position: absolute;
    top: -25%;
    z-index: ${zIndexes.ui1};
    opacity: 0.9;
    letter-spacing: 0.01rem;
  }
  & small {
    font-size: 0.7em;
  }
`

interface IProps {
  code: string
  defaultHtml?: string
  gameInstance?: CentralGameInstancesQuery_gameInstances_edges_node | null
  fallbackHtml?: string
  season?: CentralSeasonFragment | null
  segment?: CentralSegmentDbAttrsFragment | CentralGameInstancesQuery_allSegments | null
  poolSettings?: IFullPoolSettings | null
  as?: "strong" | "div" | "p" | "header" | "i" | "small" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  id?: string
  className?: string
  bypassCustomCopy?: boolean
  removeEditLink?: boolean
}

const CustomCopy = React.memo((props: IProps) => {
  const themeContext = React.useContext(ThemeContext)
  const { code, defaultHtml, gameInstance, fallbackHtml, season, segment, poolSettings, className, bypassCustomCopy, removeEditLink, ...rest } = props
  const gi = gameInstance || themeContext.gameInstance || emptyObject
  const seg = segment || themeContext.segment || undefined
  const ps = poolSettings || seg?.poolSettings || themeContext.poolSettings || undefined
  const ssn = season || (seg && (seg as CentralGameInstancesQuery_allSegments).season) || themeContext.season || undefined
  invariant(!!gi.uid, `No gameInstance passed`)
  const html = customCopyHtml(
    code,
    gi,
    ssn,
    seg,
    ps as IFullPoolSettings,
    !removeEditLink && themeContext.isCbsAdmin && themeContext.isInDebugMode,
    defaultHtml,
    fallbackHtml,
    bypassCustomCopy,
  )
  const classNames = [customCopyTextClassname, "u-selectable"]
  if (className) {
    classNames.push(className)
  }
  return <CustomCopySc className={classNames.join(" ")} dangerouslySetInnerHTML={{ __html: html }} {...rest} />
})

export default CustomCopy
