import React, { useContext } from "react"
import styled from "styled-components/macro"
import { emptyArray, emptyObject } from "../../common/misc-utils"
import { getVGUID } from "../utils/misc-utils"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"

const Link = styled.a`
  & {
    display: block;
    color: inherit;
  }
`

const selectionIdsRegex = new RegExp("#SELECTIONIDS#", "gi")
const vguuidRegex = new RegExp("#VGUID#", "gi")
// const timestampRegex = new RegExp("#TIMESTAMP#", "gi")

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  placement: string
  selectionIds?: string[]
  asComponent?: React.ReactNode
}

const imgStyle = { position: "absolute", opacity: 0, pointerEvents: "none" } as React.CSSProperties

const GamblingPartnerAdPlacement: React.FC<IProps> = ({ placement, selectionIds, asComponent, ...props }) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const gamblingPartnerInfo = poolDataContext?.centralCurrentUsersEntriesQuery.data?.gamblingPartnerInfo || emptyObject
  const placements = gamblingPartnerInfo?.placements || emptyArray
  const vguid = getVGUID()
  const placementValues = placements.find((values) => values.name === placement) || emptyObject
  const selectionIdsParam = selectionIds?.join(",") || ""
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const otherAttributes = {} as any
  if (placementValues.linkUrl) {
    // https://ad.doubleclick.net/ddm/clk/474601786;276913224;o?https://whus.onelink.me/F35S?ar=f2pf2p&c=f2p7120&utm_campaign=f2p7120&league=nfl&zone=f2p&sport=football&pid=viacomcbs&utm_source=viacomcbs&af_prt=viacomcbs&af_channel=organic&utm_medium=organic&af_c_id=SACK150&utm_offer=SACK150&bc=SACK150&af_ad=54ec4866-6729-4762-8c92-119a6f1241f8&utm_banner=#VGUID#&af_click=30d&af_dp=https%3A%2F%2Fwww.williamhill.com%2Fus%2Fnj%2Fbet%2Fbetslip%3FselectionIds%3D1770d7ee-c5cd-33ba-9e66-1a0a5f76c382,3c837f53-0435-3647-9c5a-dbd0c7c4c9fb&af_web_dp=https%3A%2F%2Fwww.williamhill.com%2Fus%2Fnj%2Fbet%2Fbetslip%3FselectionIds%3D#SELECTIONIDS#
    otherAttributes.href = placementValues.linkUrl.replace(vguuidRegex, vguid).replace(selectionIdsRegex, selectionIdsParam)
    otherAttributes.rel = "nofollow noopener"
    otherAttributes.target = "_blank"
  }
  const impressionUrl = (placementValues.impressionUrl || "").replace(vguuidRegex, vguid).replace(selectionIdsRegex, selectionIdsParam)
  return (
    <>
      {(impressionUrl && <img src={impressionUrl} loading="lazy" width="1" height="1" style={imgStyle} alt="gpimg" />) || null}
      <Link as={asComponent || (placementValues.linkUrl ? "a" : "div")} {...otherAttributes} {...props} />
    </>
  )
}

// NOTE qac: we cannot memoize since we are using vguid
// const MemoizedComponent = React.memo(GamblingPartnerAdPlacement)
export default GamblingPartnerAdPlacement
