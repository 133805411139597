import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.variant--back {
    transform: rotate(180deg);
  }
  &.variant--up {
    transform: rotate(-90deg);
  }
  &.variant--down {
    transform: rotate(90deg);
  }
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  icon?: boolean
  variant?: "up" | "down" | "back" | "forward"
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(
  ({ color = "currentColor", icon = false, variant = "forward", className, ...props }, ref) => {
    const classNames = [`variant--${variant}`] as string[]
    if (className) {
      classNames.push(className)
    }
    if (icon) {
      classNames.push("is-icon--true")
    }
    return (
      <SVG viewBox="0 0 512 512" className={classNames.join(" ")} {...props} ref={ref}>
        <path
          fill={color}
          strokeWidth="4.54"
          d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"
        />
      </SVG>
    )
  },
)

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
