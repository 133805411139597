import { DateTime } from "luxon"
import { getLeagueTypeForTeamLogos, toTruncatedNumber } from "../../common/misc-utils"
import { CentralCurrentUsersEntriesQuery_currentUser } from "../../__generated__/CentralCurrentUsersEntriesQuery"
import { GameSportTypeEnumType } from "../../__generated__/globalTypes"
import { IPoolData } from "../../routes.d"
import constants from "../../common/constants"

interface ITeam {
  id: number | string
  cbsTeamId: number
  sportType: string
  abbrev: string
  location: string
  nickName: string
}

export const placeholderPeriodPoint = {
  id: "",
  poolRank: null,
  fantasyPoints: 0.0,
}
export const placeholderPool = {
  id: "",
  name: "",
  url: "",
  __typename: "Pool",
}
export const placeholderEntry = {
  id: "",
  name: "",
  __typename: "Entry",
  email: "",
  hideEmail: false,
  noMessageBoardNotification: true,
  avatarUrl: null,
  poolRank: null,
  totalPicksCount: 0,
  hidePicksOnboarding: false,
  fantasyPoints: 0.0,
  hasSeenNativeAppPromo: true,
  periodPoint: placeholderPeriodPoint,
  tiebreakerAnswers: [] as never[],
  picks: [] as never[],
  roles: [] as never[],
  pool: placeholderPool,
}

export const toDateTime = (epoch: number) => DateTime.fromMillis(epoch)
export const getDateDiff = (epoch: number) => {
  if (!epoch) {
    return ""
  }
  const now = Date.now()
  const date = DateTime.fromMillis(epoch)
  const dateNow = DateTime.fromMillis(now)
  if (!date) {
    return ""
  }

  const diff = dateNow.diff(date).milliseconds
  const minutesDiff: number = Math.floor(diff / (1000 * 60))
  const hoursDiff: number = Math.floor(minutesDiff / 60)

  if (hoursDiff >= 24) {
    if (hoursDiff > 168) {
      const daysDiff = Math.floor(hoursDiff / 24)
      return `${daysDiff || 1}d ago`
    }
    return date.toFormat("LLL dd, yyyy, h:mma").replace("PM", "pm").replace("AM", "am")
  } else {
    if (hoursDiff > 0) {
      return `${hoursDiff}h ago`
    }
    return `${minutesDiff || 1}m ago`
  }
}

export const placeholderImg = `//placehold.it/150x150`
const mediaS3Images = "https://sports.cbsimg.net/fly/images"

// https://sportsfly.cbsistatic.com/fly-93/bundles/sportsmediacss/images/team-logos/ncaa/MICHST.svg
// https://sportsfly.cbsistatic.com/fly-93/bundles/sportsmediacss/images/team-logos/ncaa/light/MICHST.svg
// https://sportsfly.cbsistatic.com/fly-93/bundles/sportsmediacss/images/team-logos/ncaa/light/WISC.svg
// https://sportsfly.cbsistatic.com/fly-116/bundles/sportsmediacss/images/team-logos/ncaa/SETON.svg
// https://sports.cbsimg.net/images/collegebasketball/logos/90x90/MD.png
export const teamSrc = (team: any, sportType = "NFL") => {
  const cbsTeamId = team.cbsTeamId || team.id
  if (!constants.IS_PROD) {
    return (cbsTeamId && `${mediaS3Images}/team-logos/${cbsTeamId}.svg`) || placeholderImg
  }
  return (cbsTeamId && `${mediaS3Images}/${getLeagueTypeForTeamLogos(sportType)}/logos/team/${cbsTeamId}.svg`) || placeholderImg
}
export function teamRosterUrl(team: ITeam) {
  const path = team.sportType.replace("NCAA", "college").replace("NFL", "nfl").replace("F", "-football").replace("B", "basketball")
  const parts = [team.location]
  if (team.sportType === "NFL") {
    parts.push(teamLongName(team, true))
  } else {
    parts.push(team.nickName)
  }
  const lastPart = parts.join("-").replace(/\s/g, "-").replace("&", "").toLowerCase()
  return `https://www.cbssports.com/${path}/teams/${team.abbrev.toUpperCase()}/${lastPart}/roster/`
}

export const teamImgUrlWithFallback = (team?: ITeam) => (team ? teamImgUrl(team) : placeholderImg)
// old - https://sportsfly.cbsistatic.com/bundles/sportsmediacss/images/team-logos/nba/GS.svg
// new - https://sports.cbsimg.net/fly/images/nba/logos/team/341.svg

// https://sportsfly.cbsistatic.com/fly-250/bundles`/sportsmediacss/images/team-logos/ncaa/light/NALAB.svg
// const ncaaRegex = /ncaa./;
export const teamImgUrl = ({ sportType, cbsTeamId, id }: Partial<ITeam>) => {
  if (!constants.IS_PROD) {
    return `${mediaS3Images}/team-logos/${cbsTeamId || id}.svg`
  }
  return `${mediaS3Images}/${getLeagueTypeForTeamLogos(sportType ?? "")}/logos/team/${cbsTeamId || id}.svg`
}

const badNflLocations = /^(New York|Los Angeles)$/i
const stateRegex = /\sstate/i
export const teamLongName = ({ sportType, location, nickName, abbrev }: ITeam, canUseTeamLogos = false, fallbackToAbbrev = false) => {
  if (!canUseTeamLogos) {
    if (fallbackToAbbrev) {
      return abbrev
    }
    const loc = location || "TBD"
    if (badNflLocations.test(loc)) {
      return `${loc} (${abbrev})`
    }
    return (location || "TBD").replace(stateRegex, " St")
  }
  return ((sportType && sportType.includes("NCAA") && location) || nickName || "TBD").replace(stateRegex, " St")
}
export const teamName = (team: ITeam) => {
  const maxTeamCharLength = 18
  if (constants.CAN_USE_NICKNAMES && !team.sportType.includes("NCAA")) {
    const nickName = team.nickName ?? teamLongName(team) // fallback to what it was in case there is no nickname, but this should never happen
    if (nickName.length > maxTeamCharLength) {
      return team.abbrev
    }
    return nickName
  }
  const longName = teamLongName(team)
  if (longName.length > maxTeamCharLength) {
    return team.abbrev
  }
  return longName
}

export const formatMembersCount = (entriesCount: number, label: "Member" | "Bracket" = "Member"): string => {
  const membersInfoString: string[] = []
  if (entriesCount < 1000) {
    membersInfoString.push(entriesCount.toString())
  } else {
    membersInfoString.push(`${toTruncatedNumber(entriesCount) || ""}+`)
  }
  membersInfoString.push(` ${label}${entriesCount === 1 ? "" : "s"}`)
  return membersInfoString.join("")
}

export const isCurrentUserLoggedIn = (currentUser: CentralCurrentUsersEntriesQuery_currentUser) => {
  const { picksDbId, hasCbsAccount, hasFantasyUser } = currentUser
  return !!picksDbId || hasCbsAccount || hasFantasyUser
}

export const getNewsSearchSlugFromSportType = (sportType: GameSportTypeEnumType) =>
  /ncaaf|college-football/i.test(sportType)
    ? "college-football-picks-advice"
    : /nfl|football/i.test(sportType)
    ? "fantasy-football-picks-advice"
    : /ncaaw/i.test(sportType)
    ? "ncaaw-tournament-latest-news"
    : "ncaab-tournament-latest-news"

export const formatPossessiveName = (name: string) => {
  if (!name) {
    return name
  }
  return name + (name.slice(-1) === "s" ? "'" : "'s")
}

interface ISortablePool {
  id: string
  gameInstanceUid: string
  name: string
}
const sortPools = (a: ISortablePool, b: ISortablePool) => {
  const challengeMatcher = /challenge/gi
  const isAChallenge = challengeMatcher.test(a.gameInstanceUid)
  const isBChallenge = challengeMatcher.test(b.gameInstanceUid)
  if (isAChallenge === isBChallenge) {
    /**
     * Note RH: The idea for appending the id is to still be able to tell the pools apart even
     * when they have the same name
     */
    const namePlusIdA = `${a.name}${a.id}`.toLowerCase()
    const namePlusIdB = `${b.name}${b.id}`.toLowerCase()
    return namePlusIdA.localeCompare(namePlusIdB)
  } else if (isAChallenge) {
    return -1
  } else {
    return 1
  }
}

type ICurrentEntryType = IPoolData["allCurrentEntries"][number]
export const sortEntries = (entries: ICurrentEntryType[]) => {
  const entriesByPoolId: Record<string, ICurrentEntryType[]> = entries.reduce((obj, currentEntry) => {
    const currentValue: ICurrentEntryType[] = obj[currentEntry.pool.id] ?? []
    currentValue.push(currentEntry)
    obj[currentEntry.pool.id] = currentValue
    return obj
  }, {})

  return Object.keys(entriesByPoolId)
    .map((k) => entriesByPoolId[k])
    .sort(([first], [second]) => sortPools(first.pool, second.pool))
    .map((arr) => {
      const newArr = [...arr]
      newArr.sort((a, b) => a.createdAt - b.createdAt)
      return newArr
    })
    .flat()
}
