import * as React from "react"

function AlignTopIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export default AlignTopIcon
