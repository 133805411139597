import { enum_seasonType } from "../../typings/db-models.d"
import { CentralSegmentFragment, CentralSegmentFragment_season } from "../__generated__/CentralSegmentFragment"
import { IPickUtilsEvent, IPickUtilsPick } from "./common-utils-types.d"
import { DbRow } from "./db-typings"
import { getStartsAtForPeriod } from "./extra-columns"
import { dateToEpoch } from "./misc-utils"
import { SeasonTypeOptions } from "./pool-settings"
import { matchupSorter } from "@cbs-sports/sports-shared-client/build/cjs/utils/sorters"
import { ClientsEnum } from "./enums"
export { matchupSorter }

interface IFrontendOrBackendRow {
  id: string | number
}
type IStartsAt = string | Date
export interface IItemWithStartsAt extends DbRow {
  startsAt: IStartsAt
}
interface IFeItemWithStartsAt {
  startsAt: number
}
interface IItemWithOrder extends IFrontendOrBackendRow {
  order: number
}
interface ISeason extends IFrontendOrBackendRow {
  season: enum_seasonType
  year: number
}
interface IPrimpySeason {
  seasonType: string
  seasonYear: number
}
interface IPrimpyObjWithSeason {
  season: IPrimpySeason
}

export function sortFrontendSegments(a: CentralSegmentFragment, b: CentralSegmentFragment) {
  const seasonAClient = a.season.gameInstance.client
  const seasonBClient = b.season.gameInstance.client
  if (seasonAClient === ClientsEnum.CBS && seasonBClient !== ClientsEnum.CBS) {
    return -1
  } else if (seasonAClient !== ClientsEnum.CBS && seasonBClient === ClientsEnum.CBS) {
    return 1
  } else {
    return getSeasonNumericValue(b.season) - getSeasonNumericValue(a.season)
  }
}
export function sortPrimpySeasons(a: IPrimpyObjWithSeason, b: IPrimpyObjWithSeason) {
  return getSeasonNumericValue(b.season) - getSeasonNumericValue(a.season)
}

export function getSeasonNumericValue(season: ISeason | IPrimpySeason | CentralSegmentFragment_season) {
  const seasonType = (season as ISeason).season || ((season as IPrimpySeason).seasonType?.toLowerCase() as enum_seasonType)
  const year = (season as ISeason).year || (season as IPrimpySeason).seasonYear
  const seasonTypeOption = SeasonTypeOptions.find((opt) => opt.value === seasonType)
  const val = seasonTypeOption?.val || 0.0
  // TODO qac: we want to target ClientsEnum.CBS and prioritize that (which will default pools in NCAAB lobbies to the correct challenge)
  // const val2 = ((season as CentralSegmentFragment_season).challengePoolSlug === "challenge" && 0.01) || 0
  return year + val // + val2
}
export const sortBySeason = (a: ISeason, b: ISeason) => getSeasonNumericValue(a) - getSeasonNumericValue(b) || sortByIdBoth(a, b)

export const oldestCreatedAtFirst = (a: DbRow, b: DbRow) => a.createdAt.getTime() - b.createdAt.getTime()

// returns: lowest id first, highest last
export const sortById = (a: DbRow, b: DbRow) => a.id - b.id
function sortByIdBoth(a: IFrontendOrBackendRow, b: IFrontendOrBackendRow) {
  return typeof a.id === "number" && typeof b.id === "number" ? sortById(a as DbRow, b as DbRow) : a.id.toString().localeCompare(b.id.toString())
}

const toMs = (startsAt: IStartsAt) => new Date(startsAt).getTime()

export const sortByStartsAtInt = (a: IFeItemWithStartsAt, b: IFeItemWithStartsAt) => a.startsAt - b.startsAt

export const sortByStartsAt = (a: IItemWithStartsAt, b: IItemWithStartsAt) => toMs(a.startsAt) - toMs(b.startsAt) || a.id - b.id // || means: if thats 0

// returns 1, 2, 3, 4
export const sortByOrder = (a: IItemWithOrder, b: IItemWithOrder) => {
  return a.order - b.order
}

export const sortBySlotId = (a: IPickUtilsPick, b: IPickUtilsPick) => a.slotId.localeCompare(b.slotId)

// TODO qac: fix typings
// NOTE qac: so we realistically want to ALWAYS sort by order, but we may have a mix of segments, so lets do this logic:
// sort by order ALWAYS (important for conf brackets!):
/*
nowAt = 0
a = {startsAt: nowAt - 3, id: 1, endsAt: nowAt - 1, order: 1}
b = {startsAt: nowAt, id: 2, endsAt: nowAt + 2, order: 2}
c = {startsAt: nowAt, id: 3, endsAt: nowAt + 3, order: 3}
d = {startsAt: nowAt, id: 4, endsAt: nowAt + 3, order: 3}
[a,b,c,d].sort((a,b) => a.startsAt - b.startsAt || a.endsAt - b.endsAt).map((f) => f.id)
=> [1, 2, 3, 4]
so earliest to latest (as anticipated)
*/
export function sortForCurrentPeriod(a: DbRow, b: DbRow) {
  if (a.segmentId !== b.segmentId) {
    return (
      getStartsAtForPeriod(a) - getStartsAtForPeriod(b) ||
      dateToEpoch(a.endsAt) - dateToEpoch(b.endsAt) ||
      sortByOrder(a as IItemWithOrder, b as IItemWithOrder) ||
      a.id - b.id
    )
  } else {
    return sortByOrder(a as IItemWithOrder, b as IItemWithOrder) || a.id - b.id
  }
}

export function newEventOfThePeriodSorter(eventOfThePeriodId: string | null, secondEventOfThePeriodId: string | null) {
  return function (a: IPickUtilsEvent, b: IPickUtilsEvent) {
    if (a?.id === eventOfThePeriodId && b?.id === secondEventOfThePeriodId) {
      return -1
    } else if (b?.id === eventOfThePeriodId && a?.id === secondEventOfThePeriodId) {
      return 1
    } else if (a?.id === eventOfThePeriodId || a?.id === secondEventOfThePeriodId) {
      return 1
    } else if (b?.id === eventOfThePeriodId || b?.id === secondEventOfThePeriodId) {
      return -1
    }
    return 0
  }
}
