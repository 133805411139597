import styled, { createGlobalStyle } from "styled-components/macro"
import { colorFor, themeBtnBgColor, themeBtnColor, primaryFor, secondaryFor } from "../../../../common/theme-helpers"
import { ButtonBase } from "../../../components/Button"
import { FillScreen, FlexCols } from "../../../components/FlexComponents"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"
import { bgColorOff, fixedBgBg, lowerSection2Bg, lowerSectionSvgColor, soloBgColor } from "../../../../common/splash-themed-styles"

const mainSplashPromoWBp = 900

export const OnboardingFillScreen = styled(FillScreen)`
  justify-content: flex-start;
  color: ${colorFor};
  &.is-modal--true {
    width: ${pxToRem(700)};
    max-width: 100%;
    min-height: 60vh;
    border-radius: ${pxToRem(7)};
    background-size: cover;
    position: relative;
    background-color: ${palette.white};
  }
  &.is-modal--true.is-auth--true {
    min-height: unset;
    padding-top: ${pxToRem(16)};
    padding-bottom: ${pxToRem(16)};
  }
`
export const LogoStyled = styled.div`
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(350)};
  & svg,
  img {
    max-height: ${pxToRem(200)};
  }
  @media (max-height: ${pxToRem(750)}) {
    padding: ${pxToRem(16)};
    .hide-mobile-nav--false & {
      min-height: 30vh;
    }
  }
  .is-modal--true & {
    padding: ${pxToRem(16)};
    min-height: unset;
  }
`

export const CtaModuleContainer = styled.section`
  width: ${pxToRem(700)};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 20vh;
  justify-content: space-evenly;
  padding-bottom: ${pxToRem(18)};
  & > svg {
    max-width: 30%;
  }
  & > * {
    margin: ${pxToRem(18)} 0 0 !important;
  }
  & > * > a {
    margin: 0 ${pxToRem(8)};
  }
`

export const ComingSoonWrapper = styled.div`
  color: ${palette.black};
  max-width: ${pxToRem(mainSplashPromoWBp)};
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
`
export const ComingSoonH = styled.h2`
  font-size: ${pxToRem(40)};
  font-weight: ${fontWeight.extraBold};
  text-align: center;
  padding: ${pxToRem(16)} ${pxToRem(16)} 0;
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    font-size: ${pxToRem(32)};
  }
  @media (max-width: ${pxToRem(600)}) {
    font-size: ${pxToRem(26)};
  }
`
export const ComingSoonH3 = styled.h2`
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
  text-align: center;
  padding: ${pxToRem(16)};
  max-width: ${pxToRem(520)};
  margin: 0 auto;
  line-height: 1.4em;
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    font-size: ${pxToRem(14)};
  }
`
export const ComingSoonCols = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  & hr {
    flex: 0 1 auto;
    min-width: 2px;
    background-color: ${palette.gray3};
    border-radius: 2px;
    margin: 0 4%;
    height: 45vh;
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    justify-content: center;
    text-align: center;
    & hr {
      margin: 0 2%;
    }
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    padding: 0 5%;
    & hr {
      display: none;
    }
  }
`
export const ComingSoonCol = styled.div`
  flex: 1 1 40%;
  max-width: ${pxToRem(500)};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
  &.gameSection.is-manager--false {
    .logo {
      span {
        width: 255px;
        top: -20px;
        position: relative;
      }
    }
  }
  &.gameSection.is-manager--true {
    .logo {
      span {
        width: 255px;
        position: relative;
      }
    }
    section {
      position: relative;
    }
  }
  & .logo {
    pointer-events: none;
    height: auto;
    max-width: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 0 0 30vh;
  }
  & .promo-img {
    display: block;
  }
  & .logo img {
    border-radius: 20%;
    height: 20vh;
  }
  & .logo svg {
    height: 30vh;
  }
  & .promo-img svg {
    height: 20vh;
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    &.gameSection.is-manager--false {
      .logo {
        span {
          top: 0;
        }
      }
      section {
        top: 0;
      }
    }
    & .logo {
      flex: 0 0 18vh;
    }
    & .logo img {
      height: 14vh;
    }
    & .logo svg {
      height: 18vh;
    }
  }
  & section {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    & > p {
      margin-bottom: ${pxToRem(16)};
      text-align: center;
    }
    & > * > * {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      margin: 0;
      width: 100%;
    }
  }
  & > * {
    margin-bottom: 3%;
  }
  & p {
    font-size: ${pxToRem(16)};
    line-height: 1.4em;
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    min-height: 15vh;
    &:first-child {
      min-width: 70%;
      padding-bottom: 2vh;
    }
    & p {
      font-size: ${pxToRem(14)};
    }
  }
  @media (max-width: ${pxToRem(450)}) {
    & .logo {
      max-width: 100%;
    }
  }
`
export const ComingSoonAppLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  & > picture img {
    height: ${pxToRem(110)};
    border-radius: ${pxToRem(25)};
  }
  @media (max-width: ${pxToRem(950)}) {
    & > picture img {
      height: ${pxToRem(90)};
    }
  }
`
export const ComingSoonAppRow = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 5%;
  & a {
    display: block;
    padding: ${pxToRem(3)} 0;
  }
`
export const AuthGateViewCtas = styled(FlexCols)`
  flex: 1 0 auto;
`

export const AuthGateViewSubText = styled.p`
  text-align: center;
  padding: 0 ${pxToRem(16)};
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(22)};
  line-height: 1.5em;
`
export const AuthGateViewText = styled.p`
  width: 100%;
  text-align: center;
  padding: 0 ${pxToRem(16)};
  font-size: ${pxToRem(20)};
  margin-bottom: ${pxToRem(22)};
  font-weight: ${fontWeight.semiBold};
  line-height: 1.4em;
  & strong {
    color: ${palette.black};
    font-weight: ${fontWeight.bold};
  }
`
export const AuthGateViewHr = styled.hr`
  height: ${pxToRem(3)};
  width: ${pxToRem(32)};
  margin: 0 auto ${pxToRem(22)};
  background-color: currentColor;
`

export const AuthGateViewCta = styled(ButtonBase)`
  margin: ${pxToRem(8)} ${pxToRem(16)};
`

export const bpWR = pxToRem(768)

const vidH = 841
const vidW = 560
const imgH = 1618 * 1.4
const imgW = 1054 * 1.4

export const NflParlayPromo = styled.div`
  width: 100%;
  color: ${colorFor};
  text-align: center;
  line-height: 1.6em;
  max-width: 100%;
  position: relative;
  & .color--primary {
    color: ${primaryFor};
  }
  & .color--secondary {
    color: ${secondaryFor};
  }
  & .fixed-bg {
    height: 120vh;
    background-repeat: no-repeat;
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        height: 95vh;
        top: -5%;
      }
    }
  }
  & > * {
    max-width: 100%;
  }
  & svg {
    pointer-events: none;
    display: block;
  }
  & .pill-btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: ${pxToRem(100)};
    max-width: 80%;
    font-size: ${pxToRem(25)};
    display: -webkit-box;
    background-color: ${colorFor};
    font-weight: ${fontWeight.bold};
    color: ${themeBtnColor};
    padding: ${pxToRem(8 * 2.5)} ${pxToRem(8 * 5)};
    cursor: pointer;
    text-transform: uppercase;
  }
  & a.buttony {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: ${pxToRem(4)};
    max-width: 80%;
    font-size: ${pxToRem(18)};
    transition: all 0.2s ease-out;
    display: -webkit-box;
    background-color: ${themeBtnBgColor};
    font-weight: ${fontWeight.bold};
    color: ${themeBtnColor};
    letter-spacing: ${pxToRem(0.82)};
    height: ${pxToRem(52)};
    width: ${pxToRem(270)};
    cursor: pointer;
    text-transform: uppercase;
  }
  & .flex-row {
    display: flex;
    flex-flow: row wrap;
    & > * {
      max-width: 100%;
      flex: 0 1 50%;
      height: 85vh;
    }
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & > * {
        flex: 1 0 100%;
      }
    }
  }
  &.onboarding-view__is-custom-solo--true .flex-row {
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & > * {
        min-height: 75vh;
      }
    }
  }
  &.onboarding-view__is-custom-solo--false .flex-row {
    & > * {
      height: 80vh;
      max-height: ${pxToRem(1000)};
    }
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & > * {
        height: auto;
        padding-bottom: 0 !important;
      }
    }
  }
  & .gutters {
    margin: 0 auto;
    z-index: 1;
    & > * {
      z-index: 1;
    }
  }
  & .gutters.flex-row.centered {
    justify-content: center;

    & > .right-col {
      display: none;
    }
  }
  &.onboarding-view__is-custom-solo--false .h4 {
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(67)};
    line-height: 1em;
    text-transform: uppercase;
    @media (max-height: 900px) {
      & {
        font-size: 6vh;
      }
    }
    @media (max-width: 1000px) {
      & {
        font-size: 4vw;
      }
    }
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        font-size: ${pxToRem(32)};
      }
    }
  }
  &.onboarding-view__is-custom-solo--true .h4 {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(28)};
    letter-spacing: ${pxToRem(0.2)};
    line-height: ${pxToRem(32)};
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        font-size: ${pxToRem(22)};
        letter-spacing: ${pxToRem(0.16)};
        line-height: ${pxToRem(26)};
      }
    }
  }
  & .mill-svg {
    width: 100%;
    height: auto;
    &.is-parlay--false {
      padding: 0 4vw;
    }
  }

  & .onboarding-view__decorator {
    position: absolute;
    pointer-events: none;
    &.onboarding-view__decorator--mid-left {
      left: 1vh;
      width: 5vh;
      max-width: ${pxToRem(40)};
      bottom: 40%;
    }
    &.onboarding-view__decorator--bottom-right {
      bottom: 3%;
      height: 13%;
      right: 0;
    }
  }

  & .sponsor-lockup {
    display: flex;
    flex-flow: row nowrap;
    font-size: ${pxToRem(11)};
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    height: ${pxToRem(40)};
    width: 100%;
    margin: -2vh auto 2vh !important;
    & p {
      margin-right: ${pxToRem(8)};
    }
    & img {
      display: block;
      flex: 1;
      max-width: ${pxToRem(110)};
      max-height: 100%;
    }
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        height: ${pxToRem(30)};
      }
    }
  }

  &.onboarding-view__is-custom-solo--false .h5 {
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(43)};
    line-height: 1em;
    text-transform: uppercase;
    @media (max-height: 900px) {
      & {
        font-size: 5vh;
      }
    }
    @media (max-width: 1000px) {
      & {
        font-size: 3vw;
      }
    }
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        font-size: ${pxToRem(24)};
      }
    }
  }
  &.onboarding-view__is-custom-solo--true .h5 {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(22)};
    line-height: ${pxToRem(28)};
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
      }
    }
  }

  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    & > * {
      margin-bottom: 4vh;
    }
  }
  & .left-col {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 4vh;
    & > *:not(:last-child) {
      margin: 0 auto 3vh;
    }
    & .logo {
      height: 20vh;
      max-height: 30%;
      max-width: ${pxToRem(450)};
    }
  }
  &.onboarding-view__is-custom-solo--false .left-col {
    padding: 4vh ${pxToRem(45)};
  }
  &.onboarding-view__is-custom-solo--false .right-col {
    overflow: visible;
    position: relative;
    min-height: ${pxToRem(527)};
    padding: 4vh 4vh 0;
  }
  &.onboarding-view__is-custom-solo--true .right-col {
    @media (max-width: ${pxToRem(600)}) {
      & {
        background: linear-gradient(0deg, ${bgColorOff} 0%, #000 8%, #000 92%, ${bgColorOff} 100%);
        display: none;
      }
    }
  }
  &.onboarding-view__is-custom-solo--false .media-el {
    width: auto;
    margin: 0 auto;
    max-height: 100%;
  }
  &.onboarding-view__is-custom-solo--true .media-el {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -${pxToRem(vidH * 0.7 * 0.5)} 0 0 -${pxToRem(vidW * 0.7 * 0.5)};
    height: ${pxToRem(vidH * 0.7)};
    width: ${pxToRem(vidW * 0.7)};
    @media (min-width: ${pxToRem(527)}) {
      &.mobile {
        display: none;
      }
    }
    @media (max-width: ${pxToRem(527)}) {
      &.video {
        width: 100%;
        left: 0;
        margin-left: 0;
      }
      &.desktop {
        display: none;
      }
    }

    &.img {
      margin: -${pxToRem(imgH * 0.4 * 0.5)} 0 0 -${pxToRem(imgW * 0.4 * 0.5)};
      height: ${pxToRem(imgH * 0.4)};
      width: ${pxToRem(imgW * 0.4)};
    }
    @media (max-width: ${pxToRem(527)}) {
      &.img {
        position: static;
        margin: 0 auto;
      }
    }
  }
  & .h3 {
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(36)};
    margin-bottom: 2vh;
    @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
      & {
        margin-top: 8vh;
      }
    }
  }
  & .baseline {
    height: ${pxToRem(5)};
    margin-bottom: 8vh;
  }
  & .lower-section {
    position: relative;
  }
  & .lower-section svg {
    color: ${lowerSectionSvgColor};
  }
  & .lower-section-bg-1 {
    background: ${bgColorOff};
    position: absolute;
    top: 40vh;
    left: 50%;
    margin-left: -${pxToRem(1120 / 2)};
    height: ${pxToRem(1392)};
    width: ${pxToRem(1120)};
    pointer-events: none;
    z-index: -2;
    @media (max-width: ${pxToRem(1100)}) {
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-left: 0;
      position: fixed;
    }
  }
  & .lower-section-bg-2 {
    background: ${lowerSection2Bg};
    position: absolute;
    left: 0;
    right: 0;
    top: 2vh;
    bottom: -2vh;
    max-width: 100vw;
    @media (max-width: ${pxToRem(600)}) {
      display: none;
    }
  }
  & .lower-section-bg-2--mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 50vh;
    height: 75%;
    width: 100%;
    max-width: 100vw;
    @media (min-width: ${pxToRem(600)}) {
      display: none;
    }
  }
  & .footer-h {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(22)};
    margin: 17vh auto 2vh;
  }
  & .parlay-see-more {
    margin-bottom: 5vh;
  }
  & .disclaimer {
    max-width: ${pxToRem(700)};
    margin: 0 auto 5vh;
    line-height: 1.5em;
    padding: 0 ${pxToRem(30)};
    font-size: ${pxToRem(9)};
    display: block;
    @media (max-width: ${pxToRem(600)}) {
      margin-top: 1rem;
    }
  }
`
export const NflParlayPromoPoint = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 4vh 4vh;
  text-align: left;
  &.right {
    justify-content: flex-end;
  }
  & svg {
    height: ${pxToRem(215)};
    flex: 0 1 ${pxToRem(171)};
  }
  & div {
    flex: 0 1 ${pxToRem(366)};
    display: flex;
    flex-flow: column;
    height: ${pxToRem(165)};
    margin-top: auto;
    padding: 4vh;
    justify-content: center;
  }
  & header {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(32)};
    margin-bottom: ${pxToRem(16)};
  }
  & p {
    font-size: ${pxToRem(20)};
  }
  @media (max-width: ${pxToRem(650)}) {
    display: block;
    text-align: center;
    & > * {
      margin: 0 auto;
    }
  }
`

export const ParlayPromoGlobalStyles = createGlobalStyle`
body {
  background-color: ${soloBgColor} !important;
}
body .fixed-bg {
  background-color: ${fixedBgBg} !important;
}
`

export const PromoPage = styled.div`
  max-width: ${pxToRem(1170 + 60)};
  width: 100%;
  text-align: center;
  color: ${colorFor};
  margin: 0 auto;
  padding-bottom: ${pxToRem(32)};

  &.onboarding-view__is-challenge-only--true {
  }

  & .season-over-h3 {
    padding: 0 ${pxToRem(12)} ${pxToRem(12)};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(18)};
  }

  & > small {
    padding: 5vh ${pxToRem(12)} 0;
    display: block;
    max-width: 90rem;
    margin: 0 auto;
    line-height: 1.4;
    font-size: 0.7em;
  }

  & .entries-title {
    padding: 0 ${pxToRem(12)} ${pxToRem(12)};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(18)};
  }
  & .entries {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  & .promo-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 10vh;
  }

  & .promo-left.has-sponsor--true {
    padding: 0 ${pxToRem(16)};
  }
  & .promo-left.has-sponsor--true svg {
    padding-bottom: 4vh;
  }

  & .sponsor-stacked-img {
    width: 100%;
    height: 3vh;
    max-height: ${pxToRem(75)};
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  & .sponsor-stacked-img img {
    width: auto;
    height: 100%;
    display: block;
  }

  & .promo-left {
    pointer-events: none;
    flex: 1 1 ${pxToRem(300)};
    max-width: ${pxToRem(400)};
    height: 30vh;
    margin-bottom: 4vh;
    position: relative;
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .promo-left {
      margin-top: 4vh;
    }
  }
  & .promo-right {
    flex: 1 1 ${pxToRem(300 + 24)};
    max-width: ${pxToRem(300 + 24)};
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .promo-right.promo-right--season-over-false > *:not(a) {
      padding-left: ${pxToRem(24 + 16 + 16)};
    }

    & .promo-right--season-over-true {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }
  & .promo-right > * {
    margin-top: 0;
    margin-bottom: 3.5vh;
  }
  & .radio {
    height: ${pxToRem(24)};
    width: ${pxToRem(24)};
    min-width: ${pxToRem(24)};
    position: absolute;
    left: ${pxToRem(16)};
    top: 0;
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .radio {
      left: ${pxToRem(-(24 + 16))};
    }
  }
  & .radio--css {
    height: ${pxToRem(24)};
    width: ${pxToRem(24)};
    border: ${pxToRem(4)} solid white;
    flex: 0 0 auto;
    min-width: ${pxToRem(24)};
    border-radius: 100%;
    padding: ${pxToRem(1.5)};
    position: relative;
  }
  & .radio--inner {
    border-radius: 100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #f7b010;
  }
  & .radio__inner-c {
    transition: all 0.2s ease-out;
    transform: scale(0);
    transform-origin: center;
  }

  & .option.is-selected--true .radio__inner-c {
    transform: scale(1);
  }

  & .cta {
    position: relative;
    height: ${pxToRem(31)};
  }

  & .text {
    width: auto;
    left: 0;
    top: 0;
  }
  & .desc {
    padding-top: ${pxToRem(8)};
    font-size: ${pxToRem(17)};
    line-height: ${pxToRem(30)};
    display: block;
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .desc {
      text-align: left;
    }
  }
  & .orsvg {
    height: 6vh;
    display: block;
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .orsvg {
      width: auto;
      margin-left: ${pxToRem(-10)};
    }
  }
  & .desc strong {
    font-weight: ${fontWeight.bold};
  }
  & .next-cta {
    max-width: ${pxToRem(130)};
    padding: 0;
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(20)};
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .next-cta {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .promo-container {
      margin-top: 4vh;
      text-align: center;
    }
  }

  & .reactivate-mobile-prompt {
    padding: 0 10%;
    margin-bottom: 4vh;
    width: 100%;
  }
  & .reactivate-mobile-prompt__text {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(19)};
    line-height: ${pxToRem(26)};
    max-width: ${pxToRem(240)};
    margin: 0 auto;
  }
  & .reactivate-mobile-prompt svg {
    height: ${pxToRem(31)};
  }
  @media (min-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .reactivate-mobile-prompt {
      display: none;
    }
  }
`

export const ReactivateEntriesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: center;

  & .reactivate-section {
    flex: 1 1 45%;
    min-width: ${pxToRem(300)};
    margin: 3.5vh 0 0;
  }
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    & .reactivate-section {
      flex: 0 1 100%;
    }
  }
  & .reactivate-section__title {
    padding: 0 ${pxToRem(12)} ${pxToRem(12)};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(18)};
  }
  & .reactivate-section__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-right: ${pxToRem(12)};
  }
  & .reactivate-section__items > * {
    margin-left: ${pxToRem(6)};
    margin-right: ${pxToRem(6)};
    margin-bottom: ${pxToRem(12)};
  }
  & .reactivate-section__item {
    padding: ${pxToRem(4)} ${pxToRem(12)};
    font-size: ${pxToRem(16)};
    flex: 0 1 0%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const TextContainer = styled.div`
  font-size: 1.5rem;
  display: flex;
  height: 2rem;
  @media (max-width: ${pxToRem(mainSplashPromoWBp)}) {
    margin-left: 3.5rem;
  }
`
