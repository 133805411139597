import { QueryResult, useMutation, useQuery } from "@apollo/client"
import React, { FC, useEffect, useRef, useState } from "react"
import { PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables } from "../../../../__generated__/PoolInvitationCenterQuery"
import { SendPoolInvitationMutation, SendPoolInvitationMutationVariables } from "../../../../__generated__/SendPoolInvitationMutation"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import useControls from "../../../hooks/useControls"
import { edgeToNode, emailReg, emptyNodes } from "../../../utils/misc-utils"
import {
  POOL_INVITATION_CENTER_QUERY,
  SEND_PICKS_REMINDER_NOTIFICATION_MUTATION,
  SEND_POOL_BACKUP_MUTATION,
  SEND_POOL_INVITATION_MUTATION,
} from "../../queries"
import { MenuItem, MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import CirclePlusSvg from "../../../components/icons/CirclePlus"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import SendSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Send"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import CloseSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Close"
import Modal, { ModalWrapper } from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import {
  InviteModalHeader,
  MultipleEntriesPoolInviteMailCenterWrap,
  TabsMenuBtn,
  TabsMenuWrap,
} from "../styles/MultipleEntriesPoolInviteMailCenter.styles"
import { sendPoolBackUpEmailsMutation, sendPoolBackUpEmailsMutationVariables } from "../../../../__generated__/sendPoolBackUpEmailsMutation"
import { extractValidationError } from "../../../components/Form"
import { humanizeErrorMsg } from "../../../../common/apiErrors"
import { ContactItem, getContacts, ContactList as MultipleEntriesContactList } from "./InviteCenter/InviteCenterTabs"
import constants from "../../../../common/constants"
import {
  sendPicksReminderNotificationMutation,
  sendPicksReminderNotificationMutationVariables,
} from "../../../../__generated__/sendPicksReminderNotificationMutation"
import { TPoolRouteProps } from "../../../../routes.d"
import Analytics from "../../../utils/analytics"

const TabsMenu = ({
  onItemClick,
  poolInvitationCenterQuery,
  currentUserId,
}: {
  onItemClick: (any) => void
  poolInvitationCenterQuery: QueryResult<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>
  currentUserId: string
}) => {
  const fantasyContacts = poolInvitationCenterQuery?.data?.fantasyContacts || ({} as never)
  const groups = (poolInvitationCenterQuery?.data?.currentUser?.groups?.edges || emptyNodes).map(edgeToNode)
  const tabsRef = useRef<HTMLDivElement | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [currentSection, setCurrentSection] = useState("all")

  const handleSectionClick = (event: React.SyntheticEvent, sectionName: string) => {
    event.stopPropagation()
    event.preventDefault()
    setCurrentSection(sectionName)
  }

  const list = getContacts(fantasyContacts, groups, currentUserId)

  useEffect(() => {
    const onClick = (e: MouseEvent | TouchEvent) => {
      const isClickInside = tabsRef.current?.contains(e.target as Node)
      if (!isClickInside) {
        setIsOpen(false)
      }
    }

    if (typeof document !== "undefined") {
      document.addEventListener("click", onClick, { passive: true })
      document.addEventListener("touchstart", onClick, { passive: true })
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("click", onClick)
        document.removeEventListener("touchstart", onClick)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TabsMenuWrap ref={tabsRef}>
      <TabsMenuBtn className="variant--clear without-text--true" onClick={() => setIsOpen(!isOpen)}>
        <ListItemIcon size="md">
          <CirclePlusSvg />
        </ListItemIcon>
      </TabsMenuBtn>
      {isOpen && (
        <div className="tabs-menu-content">
          <span className="title">My Contacts</span>
          <MenuBase className="menu" inline>
            <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "all")} active={currentSection === "all"}>
              All
            </MenuItem>
            <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "favorites")} active={currentSection === "favorites"}>
              Favorites
            </MenuItem>
            <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "groups")} active={currentSection === "groups"}>
              Groups
            </MenuItem>
            <MenuItem isMainItem onClick={(e) => handleSectionClick(e, "owners")} active={currentSection === "owners"}>
              Managers
            </MenuItem>
          </MenuBase>
          <div className="contact-list">
            <MultipleEntriesContactList items={list[currentSection]} onItemClick={onItemClick} showEmail={currentSection !== "groups"} />
          </div>
        </div>
      )}
    </TabsMenuWrap>
  )
}

type VariantType = "invitation" | "email-pool-notification" | "welcome-back-invitation" | "picks-reminder-notification"
interface IProps extends TPoolRouteProps {
  variant: VariantType
  subPoolId?: string
  onClose: () => void
  defaultMessage?: string
  defaultSubject?: string

  isPoolPage?: boolean

  onNotificationSent?: () => void
}
export const MultipleEntriesPoolInviteMailCenter: FC<IProps> = (props) => {
  const { match, subPoolId, poolData, onClose, defaultMessage, defaultSubject, variant = "invitation", isPoolPage } = props
  const [mailList, setMailList] = useState<ContactItem[]>([])
  const [showFullList, setShowFullList] = useState(true)
  const [sending, setSending] = useState(false)
  const [textArea, setTextArea] = useState(defaultMessage || "")
  const [sendInviteError, setSendInviteError] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const subjectRef = useRef<HTMLInputElement>(null)
  const updateMailList = React.useCallback((items: ContactItem[]) => {
    const uniqueValues = items.reduce((obj, item) => {
      if (!obj[item.email]) {
        obj[item.email] = item
      }
      return obj
    }, {})
    setMailList(Object.values(uniqueValues))
  }, [])

  const { entryId, currentUser, poolId, hasManagerRole } = poolData
  const sport = match.params.sportType
  const controls = useControls(props as any)
  const { first, after } = controls
  const variables = { entryId, first, after, sport, subPoolId, skipContacts: false }
  const skip = !variables.entryId || variant !== "invitation"

  const [sendPoolInvitationMutation] = useMutation<SendPoolInvitationMutation, SendPoolInvitationMutationVariables>(SEND_POOL_INVITATION_MUTATION)
  const poolInvitationCenterQuery = useQuery<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>(POOL_INVITATION_CENTER_QUERY, {
    variables,
    skip,
    ssr: false,
  })
  const [sendPoolBackupEmailsMutation] = useMutation<sendPoolBackUpEmailsMutation, sendPoolBackUpEmailsMutationVariables>(SEND_POOL_BACKUP_MUTATION, {
    refetchQueries: ["CentralPoolDetailsQuery"],
  })
  const [sendPicksReminderNotificationMutation] = useMutation<sendPicksReminderNotificationMutation, sendPicksReminderNotificationMutationVariables>(
    SEND_PICKS_REMINDER_NOTIFICATION_MUTATION,
  )
  // const [sendPicksReminderNotificationMutation] = useMutation
  const handleSubmit = () => {
    const to = mailList.map((x) => x.email).join(", ")
    const message = textArea || ""
    const subject = subjectRef.current?.value || defaultSubject || ""
    setSending(true)
    if (variant === "welcome-back-invitation" || variant === "email-pool-notification") {
      sendPoolBackupEmailsMutation({ variables: { poolId, message, subject, isPoolMail: variant === "email-pool-notification" } })
        .then((_res) => {
          // OK - close Modal
          props.onNotificationSent?.()
          onClose()
        })
        .catch((err) => {
          // ERROR
        })
        .finally(() => {
          setSending(false)
          // STOP LOADING
        })
    } else if (variant === "picks-reminder-notification") {
      // MUTATION HERE
      sendPicksReminderNotificationMutation({ variables: { poolId, message, subject } })
        .then((_res) => {
          // OK - close Modal
          props.onNotificationSent?.()
          onClose()
        })
        .catch((err) => {
          // ERROR
        })
        .finally(() => {
          setSending(false)
          // STOP LOADING
        })
    } else {
      if (isPoolPage) {
        Analytics.trackAction("pool home", `invite${hasManagerRole ? "" : " - participant "}`, "email")
      }

      const values = {
        to,
        subject,
        message,
      }
      const variables = Object.assign(
        {
          entryId,
          first,
          subPoolId,
        },
        values,
      )

      sendPoolInvitationMutation({ variables })
        .then((_res) => {
          // OK - close Modal
          props.onNotificationSent?.()
          onClose()
        })
        .catch((err) => {
          // ERROR
          const apiError = extractValidationError(err)
          const errorMessages = Object.keys(apiError.errors).map((key) => humanizeErrorMsg(key, apiError.errors[key]))
          const errorMessage = errorMessages?.join(". ") || "Something went wrong. Please try again later"
          setSendInviteError(errorMessage)
        })
        .finally(() => {
          setSending(false)
          // STOP LOADING
        })
    }
  }
  const onPaste = (event: React.ClipboardEvent) => {
    const target = event.target as HTMLInputElement
    event.preventDefault()
    event.stopPropagation()
    const pastedValue = event.clipboardData.getData("text")
    if (pastedValue?.includes(",")) {
      const uniqueList = new Set()
      const emailList: ContactItem[] = []
      pastedValue.split(",").forEach((val) => {
        const trimmedValue = val.trim()
        const isValid = emailReg.test(trimmedValue)
        if (isValid && !uniqueList.has(trimmedValue)) {
          emailList.push({ email: trimmedValue })
          uniqueList.add(trimmedValue)
        }
      })
      setMailList([...mailList, ...emailList])
    } else if (emailReg.test(pastedValue)) {
      setMailList([...mailList, { email: pastedValue }])
    } else {
      target.classList?.add("invalid")
    }
  }
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    if (event.key === "Enter" || event.key === "Tab" || event.key === " " || event.key === ",") {
      event.preventDefault()
      event.stopPropagation()
      const value = target.value
      const isValid = emailReg.test(value)
      if (isValid) {
        updateMailList([
          ...mailList,
          {
            email: value,
          },
        ])
        target.value = ""
      } else {
        target.classList.add("invalid")
      }
    } else {
      target.classList.remove("invalid")
    }
  }

  const handleOnItemClick = (item) => {
    const { name, email } = item
    const itemIndex = mailList.findIndex((x) => x.email === email)
    if (itemIndex < 0) {
      updateMailList([
        ...mailList,
        {
          email,
          name,
        },
      ])
    }
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value) {
      const isValid = emailReg.test(value)
      if (isValid) {
        updateMailList([
          ...mailList,
          {
            email: value,
          },
        ])
        event.target.value = ""
      } else {
        event.target.classList.add("invalid")
      }
    }
  }
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.classList.remove("invalid")
    setShowFullList(true)
  }

  const onRemoveSelectedItem = (item: ContactItem) => {
    const newList = mailList.filter((i) => i.email !== item.email)
    updateMailList(newList)
  }

  const showList = () => {
    setShowFullList(true)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 500)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const listToShow = !showFullList && mailList?.length > 2 ? mailList.slice(0, 2) : mailList
  const showMore = !showFullList && mailList?.length > 2

  const showRecipientSection = variant === "invitation"
  const showMessageSectionOnly = variant === "welcome-back-invitation"
  return (
    <MultipleEntriesPoolInviteMailCenterWrap>
      {variant === "invitation" && (
        <AnalyticScreen feature="initial setup" subfeature="invite friends" title="Initial Setup - Invite Friends" isModal />
      )}
      {!showMessageSectionOnly && (
        <>
          {showRecipientSection && (
            <div className="to-section">
              <div className="mail-recipients">
                <span className="text-to">To:</span>
                <div className="recipients-list">
                  {listToShow?.map((value, index) => (
                    <button className="recipient-chip" key={index} type="button" onClick={() => onRemoveSelectedItem(value)}>
                      {value.name || value.email}
                      <CloseSvg />
                    </button>
                  ))}
                  {showMore && (
                    <button className="recipient-chip no-close-btn" key="show-all" type="button" onClick={showList}>
                      {`${mailList?.length - 2} more`}
                      <CloseSvg />
                    </button>
                  )}
                  {!showMore && (
                    <input
                      type="email"
                      className="email-input"
                      id="search_input"
                      autoComplete="off"
                      onKeyDown={onKeyDown}
                      onPaste={onPaste}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      ref={inputRef}
                    />
                  )}
                </div>
              </div>
              {constants.ENABLE_FANTASY_CONTACT_LIST && (
                <TabsMenu
                  onItemClick={handleOnItemClick}
                  currentUserId={currentUser?.id || ""}
                  poolInvitationCenterQuery={poolInvitationCenterQuery}
                />
              )}
            </div>
          )}
          <div className="message-subject">
            <input
              type="text"
              className="email-subject"
              id="subject_input"
              autoComplete="off"
              placeholder="Subject"
              ref={subjectRef}
              onFocus={() => setShowFullList(false)}
              defaultValue={defaultSubject || ""}
            />
          </div>
        </>
      )}
      <div className="message-content">
        <textarea
          className="message-data"
          name="message"
          rows={4}
          placeholder="Message"
          value={textArea}
          onChange={(e) => setTextArea(e.target.value)}
          onFocus={() => setShowFullList(false)}
        />
      </div>
      <div className="actions">
        <Button
          variant="primary"
          onClick={handleSubmit}
          withLoading
          loading={sending}
          disabled={(mailList.length === 0 && showRecipientSection) || (!showRecipientSection && !textArea.length)}
        >
          <ListItemIcon alignment="left">
            <SendSvg />
          </ListItemIcon>
          Send {variant === "email-pool-notification" || variant === "picks-reminder-notification" ? "Message" : "Invitation"}
        </Button>
        {sendInviteError && <div className="invitation-error">{sendInviteError}</div>}
        <Button variant="secondary" onClick={onClose}>
          <ListItemIcon alignment="left">
            <DeleteSvg />
          </ListItemIcon>
          Discard
        </Button>
      </div>
    </MultipleEntriesPoolInviteMailCenterWrap>
  )
}

export const MultipleEntriesPoolInviteMailCenterModal = (props: any) => {
  const { onClose, isOpen } = props

  return (
    <Modal isOpen={isOpen} padded={false} onEscapeKeydown={() => undefined}>
      <ModalWrapper modalType="dialog" variant="white" padded={false}>
        <InviteModalHeader>
          <span className="title">New Message</span>
          <CloseSvg onClick={onClose} />
        </InviteModalHeader>
        <MultipleEntriesPoolInviteMailCenter {...props} />
      </ModalWrapper>
    </Modal>
  )
}

export default MultipleEntriesPoolInviteMailCenterModal
