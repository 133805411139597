import * as React from "react"
import styled from "styled-components/macro"

interface IArrow {
  color?: string
  className?: string
  icon?: boolean
  variant?: "up" | "down" | "back" | "forward"
}

const SVG = styled.svg`
  &.variant--up {
    transform: rotate(180deg);
  }
  &.variant--back {
    transform: rotate(90deg);
  }
  &.variant--forward {
    transform: rotate(-90deg);
  }
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`
const Arrow = ({ color = "currentColor", className = "", icon = false, variant = "down" }: IArrow) => (
  <SVG className={`arrow-svg variant--${variant} is-icon--${!!icon} ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill={color} d="M277.375 85v259.704l119.702-119.702L427 256 256 427 85 256l29.924-29.922 119.701 118.626V85h42.75z" />
  </SVG>
)
export default Arrow
