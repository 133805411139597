import React from "react"
import { ThemeContext } from "styled-components/macro"
import { ENUM_CONFERENCE_TOURNAMENT, ENUM_UNAVAILABLE, GamesPerPeriodEnum, GameTypeEnum, SeasonEnumType } from "../../../../common/enums"
import { PoolSettingAttributes, PoolSettingsLabels, PoolSettingsOptions } from "../../../../common/pool-settings"
import { getSegment, ignoredSettingsFor, tournamentRBLabels } from "../../../../common/pool-settings-setup"
import { sortByOrder } from "../../../../common/sorters"
import { IPoolSetupStepAttributesTableProps } from "../PoolSetupTypes.d"
import { PoolSetupStepFormDlRow, PoolSetupStepFormRecapContainer } from "../styles/CommonPoolSetup.styles"
import PoolSettingsEditBtn from "./PoolSettingsEditBtn"

interface IProps extends IPoolSetupStepAttributesTableProps {
  className?: string
}
interface IDl {
  dt: string
  dd: string
  attribute: string | null
  disableEdit?: boolean
}

const emptyArray = [] as never[]
export const PoolAttributeTable = ({ pool, canEdit, switchStep, className }: IProps) => {
  const segment = getSegment(pool)
  const theme = React.useContext(ThemeContext)
  const isBracket = segment.gameType === GameTypeEnum.BRACKET
  const isConfTourneyBracket = isBracket && segment.subsection === ENUM_CONFERENCE_TOURNAMENT
  const currentPoolSettings = Object.assign({}, segment.poolSettings, pool.poolSettings)
  const ignoredSettings = ignoredSettingsFor(pool.gameInstanceUid, pool.season.season, segment.gameType)
  const dls = [] as IDl[]
  dls.push({
    dt: `Pool Name`,
    dd: pool.name,
    attribute: "name",
  })
  if (canEdit) {
    dls.push({
      dt: `Pool Password`,
      dd: "***********",
      attribute: "name",
    })
  }
  // const keys = Object.keys(currentPoolSettings);
  const acceptedAttributes = PoolSettingAttributes.filter((key) => !ignoredSettings.includes(key) && currentPoolSettings[key] !== ENUM_UNAVAILABLE)
  for (const key of acceptedAttributes) {
    const mapping = PoolSettingsOptions[key]
    const value = currentPoolSettings[key]
    const selected = mapping && mapping.find((opt) => opt.value === value)
    if (mapping && selected) {
      const dl = {
        dt: PoolSettingsLabels[key],
        dd: (selected && selected.label) || "",
        attribute: key,
      } as IDl
      if (pool.season.season === SeasonEnumType.post && value === GamesPerPeriodEnum.CBS) {
        dl.dd = "All Games"
      }
      // pick deadline for bracket...
      if (!!segment.subsection && /deadline/i.test(dl.dt)) {
        dl.disableEdit = true
      }
      dls.push(dl)
    } else if (
      !value &&
      key === "maxPicksPerPeriodCount" &&
      currentPoolSettings.__typename === "PoolSettings" &&
      currentPoolSettings.pickCountRangeOption !== ENUM_UNAVAILABLE
    ) {
      // TODO qac: make this better
      const dl = {
        dt: PoolSettingsLabels[key],
        dd: "All",
        attribute: key,
      } as IDl
      dls.push(dl)
    } else if (key === "includeChampionshipRound") {
      // TODO qac: make this better
      const dl = {
        dt: PoolSettingsLabels[key],
        dd: value ? "Yes" : "No",
        attribute: key,
      } as IDl
      dls.push(dl)
    }
  }
  const tournamentIds = (currentPoolSettings.__typename === "PoolSettings" && currentPoolSettings.tournamentIds) || emptyArray
  if (tournamentIds.length && acceptedAttributes.includes("tournamentIds")) {
    const tourns = segment.tournaments || emptyArray
    const tournaments = tourns.filter(({ cbsId }) => tournamentIds.includes(cbsId))
    if (tournaments.length) {
      dls.push({
        dt: `Tournament${tournaments.length > 1 ? "s" : ""}`,
        dd: tournaments.length === tourns.length ? `All` : tournaments.map(({ abbrev }) => abbrev).join(", "),
        attribute: "conferences",
      })
    }
  }

  // TODO: this wont work for all game types...
  const rightDls = [] as IDl[]
  const roundBonuses = (currentPoolSettings.__typename === "PoolSettings" && currentPoolSettings.roundBonuses) || []
  // console.log(`currentPoolSettings:`);
  // console.dir(segment)
  // console.dir(pool);
  // console.dir(roundBonuses)
  const hasRoundBonusesDl =
    roundBonuses.length && currentPoolSettings.__typename === "PoolSettings" && currentPoolSettings.roundBonusType === "STANDARD"
  if (hasRoundBonusesDl) {
    const periods = segment.periods.edges.map((e) => e.node).sort(sortByOrder)
    if (canEdit) {
      rightDls.push({
        dt: "Round Bonuses",
        dd: "Custom",
        attribute: "roundBonusType",
      } as IDl)
    }
    roundBonuses.forEach((num, i) => {
      const idx = isBracket ? i + 1 : i
      const period = idx < periods.length ? periods[idx] : ({} as never)
      const item = {
        dt: !canEdit && i === 0 ? "Round Bonuses" : "",
        dd: `${isConfTourneyBracket ? tournamentRBLabels[i] : period.description} = x${num}`,
        // NOTE qac: removed this so you can only click the 'custom' pencil
        attribute: null, // "roundBonuses",
      } as IDl
      rightDls.push(item)
      // const position = 1 + i * 2;
      // if (dls.length >= position) {
      //   dls.splice(position, 0, item);
      // } else {
      //   if (dls.length % 2 === 0) {
      //     dls.push({
      //       dt: "",
      //       dd: "",
      //     });
      //   }
      //   dls.push(item);
      // }
    })
  } else if (dls.length > 5) {
    // try to split it 50:50
    const half = Math.ceil(dls.length / 2)
    while (dls.length > half) {
      const dlsData = dls.pop()
      if (dlsData) {
        rightDls.push(dlsData)
      }
    }
  }
  const combinedClassname = `allow-mobile-overflow--${theme.isNexusStyles === false} ${className || ""}`
  // console.log(`PoolAttributeTable: `, segment, pool)
  // console.dir(currentPoolSettings)
  // console.dir(ignoredSettings)
  // console.dir(acceptedAttributes)
  // console.dir(dls)
  // console.dir(rightDls)
  return (
    <PoolSetupStepFormRecapContainer className={combinedClassname}>
      <div>
        {dls.map(({ dt, dd, attribute, disableEdit }, i) => (
          <PoolSetupStepFormDlRow key={i}>
            <div>{dt}</div>
            <strong>{dd}</strong>
            {canEdit && <PoolSettingsEditBtn poolId={pool.id} switchStep={switchStep} attribute={attribute} disabled={disableEdit} />}
          </PoolSetupStepFormDlRow>
        ))}
      </div>
      {rightDls.length ? (
        <div>
          {rightDls.map(({ dt, dd, attribute }, i) => (
            <PoolSetupStepFormDlRow key={i}>
              <div>{dt}</div>
              <strong>{dd}</strong>
              {canEdit && <PoolSettingsEditBtn poolId={pool.id} switchStep={switchStep} attribute={attribute} />}
            </PoolSetupStepFormDlRow>
          ))}
        </div>
      ) : null}
    </PoolSetupStepFormRecapContainer>
  )
}

export default PoolAttributeTable
