import styled from "styled-components"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

const px8 = pxToRem(8)
const px24 = pxToRem(24)

export const StandingsTableWrapper = styled.div`
  .table__title {
    padding-left: ${px24};
    padding-right: ${px24};
    margin-bottom: ${px8};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table__row--hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }
`

export const ColumnLink = styled.button`
  transition: color 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover,
  &:active {
    color: ${theme.colors.overLight.blue2};
  }
`
