import React from "react"
import styled from "styled-components/macro"
import Link, { IProps } from "./Link"

const StyledOpacityLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  will-change: opacity;
  display: block;
  @media (hover: hover) {
    &:hover:not(:active) {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.7;
  }
`

interface IOpacityLink extends IProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

export class OpacityLink extends React.PureComponent<IOpacityLink> {
  public render() {
    return <StyledOpacityLink {...this.props} />
  }
}

export default OpacityLink
