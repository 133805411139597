import { IRoute, TPoolRouteProps, TRoutes } from "../../routes.d"
import React from "react"
import { ENUM_BRACKET, PoolSettingsTypesEnum, SubsectionEnum } from "../../common/enums"
import constants from "../../common/constants"
import { IDropDownMenuProps } from "../components/DropDownMenu/DropDownMenu"
import { findMatchedRoute } from "../utils/async-route-utils"
import { urlMatch, withoutDomain } from "../utils/url-utils"
import EntryLogo from "../App/PoolPages/components/EntryLogo"
import { isCurrentUserLoggedIn } from "../utils/data-utils"
import { getRulesLink } from "../../common/game-text"
import { IDropDownMenuItem } from "../components/DropDownMenu/DropDownItem"
import { emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { isMarchMadnessProductAbbrevMatcher, isNissanMarchMadnessMatcher } from "../../common/common-utils-helpers"
import { BracketsHelpDeskProductAbbrevMaping } from "../../common/pool-settings"
export interface INavMenuProps extends TPoolRouteProps {
  routes: TRoutes
}
const getPaths = (route: IRoute) => (route.path && Array.isArray(route.path) && route.path) || [route.path || ""]

const useNavMenu = (props: INavMenuProps, isMarquee: boolean): IDropDownMenuProps[] => {
  const { poolData, location, match, routes } = props
  const {
    allCurrentEntries,
    gameInstancesForArea,
    productSeason,
    usersFirstCentralEntryInPool,
    isChallengePool,
    areaHasMultipleEntriesPerUser,
    segmentForArea,
    allSegments,
    segmentsForArea,
    detailedPeriod,
    isCbsAdmin,
    hasManagerRole,
    poolDetail,
    hasAutogeneratedName,
    currentUser,
    isInComingSoon,
  } = poolData
  const { pathname } = location
  const currentRoute = findMatchedRoute(routes, pathname)
  const challengePoolSegmentForArea = segmentsForArea && segmentsForArea.find((seg) => !!seg.season.challengePoolSlug)
  const challengePoolForAreaSlug = challengePoolSegmentForArea?.season.challengePoolSlug
  const isPoolMember = !!usersFirstCentralEntryInPool.id && !hasAutogeneratedName
  const isBracket = segmentForArea?.gameType === ENUM_BRACKET
  const needsCustomGameTab = isPoolMember && !!((detailedPeriod?.usesEventGroups && hasManagerRole) || (isChallengePool && isCbsAdmin && !isBracket)) //  && usesSpread
  const poolHasOpenInvites =
    (constants.ENABLE_OPEN_INVITES && poolDetail?.poolSettings.__typename === "PoolSettings" && poolDetail.poolSettings.openInvites) || false
  const needsInviteCenter =
    (!isChallengePool && hasManagerRole && !areaHasMultipleEntriesPerUser) ||
    (areaHasMultipleEntriesPerUser && hasManagerRole && !isChallengePool) ||
    (areaHasMultipleEntriesPerUser && !isChallengePool && poolHasOpenInvites)
  const isLoggedIn = isCurrentUserLoggedIn(currentUser)

  const rulesDropdownItems: IDropDownMenuItem[] = []
  let helpCenterLink = ""
  if (areaHasMultipleEntriesPerUser) {
    const isNcaabBracket = segmentForArea?.subsection === SubsectionEnum.NcaaTournament
    const isNcaawBracket = segmentForArea?.subsection === SubsectionEnum.NcaawTournament
    const { currentEntriesForArea } = poolData.myEntriesForArea()
    const nissanChallengeEntryForArea = currentEntriesForArea.find((entry) => isNissanMarchMadnessMatcher.test(entry?.gameInstanceUid || ""))
    if (nissanChallengeEntryForArea) {
      const { gameInstanceUid, pool } = nissanChallengeEntryForArea
      const nissanRulesLink = getRulesLink(gameInstanceUid, pool.season.season, pool.season.productAbbrev, pool.season.year, "rules")
      // show nissan rules first like we do in the other dropdowns
      rulesDropdownItems.push({ label: pool.name, id: `${pool.season.productAbbrev}-rules`, to: nissanRulesLink })
    }
    if (isNcaabBracket) {
      const bpcRulesLink =
        productSeason &&
        getRulesLink(constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID, productSeason.season, productSeason.productAbbrev, productSeason.year, "rules")
      const bpmRulesLink =
        productSeason &&
        getRulesLink(constants.NCAAB_MANAGER_GAME_INSTANCE_UID, productSeason.season, productSeason.productAbbrev, productSeason.year, "rules")
      const items = [
        { label: "Men Bracket Challenge", id: "bpc-rules", to: bpcRulesLink },
        { label: "Men Bracket Manager", id: "bpm-rules", to: bpmRulesLink },
      ]
      rulesDropdownItems.push(...items)
    } else if (isNcaawBracket) {
      const wbpcRulesLink =
        productSeason &&
        getRulesLink(constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID, productSeason.season, productSeason.productAbbrev, productSeason.year, "rules")
      const wbpmRulesLink =
        productSeason &&
        getRulesLink(constants.NCAAW_MANAGER_GAME_INSTANCE_UID, productSeason.season, productSeason.productAbbrev, productSeason.year, "rules")

      const items = [
        { label: "Women Bracket Challenge", id: "wbpc-rules", to: wbpcRulesLink },
        { label: "Women Bracket Manager", id: "wbpm-rules", to: wbpmRulesLink },
      ]
      rulesDropdownItems.push(...items)
    }

    const challengePoolsForArea = segmentsForArea?.filter((seg) => !!seg.season.challengePoolSlug)
    // if we don't have poolDetail (lobby) and have entries, use last entry. If we only have one either challenge or manager, we want to go to the help center for that specific game, but if we have more, default to manager. If we dont have entries or logged out user, default to challenge.
    const productAbbrev =
      poolDetail?.season?.productAbbrev ||
      (currentEntriesForArea.length && currentEntriesForArea[currentEntriesForArea.length - 1].pool.season.productAbbrev) ||
      (challengePoolsForArea?.length && challengePoolsForArea[challengePoolsForArea.length - 1].season.productAbbrev) ||
      ""
    helpCenterLink = `https://help.${BracketsHelpDeskProductAbbrevMaping[productAbbrev || "mbc"]}.cbssports.com`
  }

  const menuData = React.useMemo(() => {
    const navItems: IDropDownMenuProps[] = []
    const myEntriesId = "myEntries"
    const myPoolsId = "myPools"
    const takenRouteNames = [] as string[]
    const isPublicPool = !!poolDetail?.isPublic
    const gameBaseUrl = (segmentForArea && withoutDomain(segmentForArea.baseUrl)) || ""
    if (segmentForArea && productSeason) {
      let matchGameBasePath = gameBaseUrl
      let poolBaseMatch = ""
      if (poolDetail) {
        poolBaseMatch = withoutDomain(poolDetail.url)
        poolBaseMatch = poolBaseMatch.replace(poolDetail.id, ":poolId")
      }
      // default to challenge pool (unless at game root)
      if (!poolBaseMatch && matchGameBasePath !== pathname && challengePoolForAreaSlug) {
        poolBaseMatch = `${matchGameBasePath}/${challengePoolForAreaSlug}`
      }
      const entryAlias = isBracket ? "Brackets" : "Entries"
      const poolAlias = "Pools"
      const params = Object.keys(match.params)
      for (const param of params) {
        // subsitute /bracket for :gameType... ect
        matchGameBasePath = matchGameBasePath.replace(match.params[param], `:${param}`)
        poolBaseMatch = poolBaseMatch.replace(match.params[param], `:${param}`)
      }
      // console.log(`building for ${poolDetail?.name} (${poolBaseMatch}, ${matchGameBasePath})`, match.params)
      const addRouteIfExists = (label: string, routeName: string) => {
        const filteredRoutes = routes.filter((r) => r.name && r.name === routeName)
        for (const route of filteredRoutes) {
          const match = addRoute(label, route)
          if (match !== null) {
            return match
          }
        }
        return null
      }
      const addRoute = (label: string, route: IRoute) => {
        const paths = getPaths(route)
        const matchedPaths =
          route.name &&
          !takenRouteNames.includes(route.name) &&
          paths.filter((path) => (poolBaseMatch ? path.includes(poolBaseMatch) : path.includes(matchGameBasePath) && path !== matchGameBasePath))
        // ["/:sportType/:subsection/:gameType/challenge"].includes("/:sportType/:subsection/:gameType")
        const matchedPath = matchedPaths && matchedPaths.length && matchedPaths[0]
        if (route.name && matchedPath) {
          takenRouteNames.push(route.name)
          // remove /path(/optional/part)
          let to = matchedPath.split("(")[0]
          for (const param of params) {
            to = to.replace(`:${param}`, match.params[param])
          }
          return navItems.push({
            id: route.name,
            label,
            to,
            isMainItem: true,
            isActive: currentRoute.route?.name === route.name,
          })
        }
        return null
      }
      if (areaHasMultipleEntriesPerUser && isMarquee) {
        const crossProductSegmentForArea =
          segmentForArea &&
          allSegments.find(
            (seg) => seg.sportType !== segmentForArea?.sportType && isMarchMadnessProductAbbrevMatcher.test(seg?.season?.productAbbrev),
          )
        const crossProdGameBaseUrl = (crossProductSegmentForArea && withoutDomain(crossProductSegmentForArea.baseUrl)) || ""
        const lobby = segmentForArea.sportType === "NCAAB" ? "Men's Lobby" : "Women's Lobby"
        const crossLobby = crossProductSegmentForArea?.sportType === "NCAAW" ? "Women's Lobby" : "Men's Lobby"
        if (crossProdGameBaseUrl && lobby !== crossLobby) {
          // const crossProdLabel = "Lobby"
          navItems.push({
            id: "lobby",
            label: lobby,
            dataCy: "lobby-dropdown-menu",
            to: gameBaseUrl,
            isMainItem: true,
            isActive: currentRoute.match.url === gameBaseUrl,
            items: [
              {
                id: "cross-lobby",
                label: crossLobby,
                to: crossProdGameBaseUrl,
              },
            ],
          })
        } else {
          navItems.push({
            id: "lobby",
            label: "Lobby",
            // dataCy: "dropDownMenu2",
            to: gameBaseUrl,
            isMainItem: true,
            isActive: currentRoute.match.url === gameBaseUrl,
          })
        }
        const entriesForArea = allCurrentEntries.filter((x) => {
          return gameInstancesForArea.findIndex((g) => g.uid === x.gameInstanceUid) >= 0
        })
        if (isLoggedIn && entriesForArea?.length) {
          navItems.push({
            id: myEntriesId,
            label: `My ${entryAlias}`,
            isMainItem: true,
            items: [],
          })
          navItems.push({
            id: myPoolsId,
            label: `My ${poolAlias}`,
            items: [],
          })
          const myGroupsMenu = navItems.find((item) => item.id === myPoolsId)
          const myBracketsMenu = navItems.find((item) => item.id === myEntriesId)
          if (myGroupsMenu || myBracketsMenu) {
            const entryItems = myBracketsMenu?.items
            const poolItems = myGroupsMenu?.items
            const addedGroups = new Set<string>()

            for (const entry of entriesForArea) {
              if (!entry.isVisible) {
                continue
              }
              const {
                url: bracketUrl,
                id: bracketId,
                name: bracketName,
                pool: { id: poolId, name: poolName, url: poolUrl },
              } = entry

              // MyGroups
              if (poolItems && myGroupsMenu) {
                const pathnameParts = pathname.split("/") || []
                const pathnameToCheck = pathnameParts.slice(0, pathnameParts.length - 1).join("/")
                const isActive = urlMatch(pathnameToCheck, withoutDomain(poolUrl))
                if (!addedGroups.has(poolId)) {
                  poolItems.push({
                    id: poolId,
                    label: poolName,
                    to: `${poolUrl}/standings`,
                  })
                  addedGroups.add(poolId)
                }
                if (myGroupsMenu) {
                  myGroupsMenu.isActive = myGroupsMenu.isActive || isActive
                }
              }
              if (entryItems && myBracketsMenu) {
                // MyBrackets
                const isActive = urlMatch(pathname, withoutDomain(poolUrl))
                entryItems.push({
                  id: bracketId,
                  label: bracketName,
                  subtitle: poolName,
                  to: bracketUrl,
                  withIcon: true,
                  icon: <EntryLogo entry={entry} showChampionLogo size="rg" />,
                  disabled: isInComingSoon,
                })
                myBracketsMenu.isActive = myBracketsMenu.isActive || isActive
              }
            }
            if (entryItems && !entryItems.length && gameBaseUrl) {
              entryItems.push({
                id: `create-entry`,
                label: `Create one now!`,
                to: gameBaseUrl,
              })
            }
            if (poolItems && !poolItems.length && gameBaseUrl) {
              poolItems.push({
                id: `create-pool`,
                label: `Join one now!`,
                to: gameBaseUrl,
              })
            }
          }
        }
      }
      if (poolDetail && !(areaHasMultipleEntriesPerUser && isMarquee)) {
        if (isMarquee && !areaHasMultipleEntriesPerUser) {
          addRouteIfExists(isBracket ? `Bracket` : `Picks`, `Pool/Picks`)
        }
        if (areaHasMultipleEntriesPerUser) {
          // Note LL: prob need to change this to new standings component
          addRouteIfExists(`Standings`, `Pool/standings`)
        } else {
          const nowAt = Date.now()
          const events: any[] =
            detailedPeriod?.events?.edges?.map((x) => x.node) || detailedPeriod?.matchups?.map(({ event }) => event).filter((i) => !!i) || emptyArray
          const startedEvents = events.filter((evt) => evt.startsAt <= nowAt)
          // Link Overall Standings instead until the first game of the week locks.
          if (!startedEvents.length) {
            addRouteIfExists(`Standings`, `Pool/standings`)
          } else {
            addRouteIfExists(`Standings`, `Pool/AllPicks`)
          }
        }
        if (!areaHasMultipleEntriesPerUser) {
          if (!isChallengePool) {
            addRouteIfExists(`Settings`, `Pool/settings`)
          }
          if (isPoolMember) {
            addRouteIfExists(`My Entry`, `Pool/my-entry`)
          }
        }
        if (!isPublicPool) {
          addRouteIfExists(`${hasManagerRole ? "Manage " : ""}Players`, `Pool/players`)
          if (!areaHasMultipleEntriesPerUser) {
            addRouteIfExists(`Finances`, `Pool/finances`)
          }
        }
        if (needsInviteCenter) {
          addRouteIfExists(`Invite Friends`, `Pool/invite-center`)
        }
        if (isPoolMember && !isPublicPool && constants.PUBLIC_MESSAGE_BOARD_ENABLED) {
          if (poolDetail?.poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolDetail?.poolSettings?.includeMessageBoard) {
            addRouteIfExists(`Message Board`, `Pool/message-board`)
          }
        }
        if (needsCustomGameTab) {
          addRouteIfExists(`Select Games`, `Pool/set-period-events`)
        }
      }
      // for (const route of routes) {
      //   addRoute(`Public ${poolAlias}`, route)
      // }
      if (!(areaHasMultipleEntriesPerUser && !isMarquee)) {
        if (areaHasMultipleEntriesPerUser && helpCenterLink) {
          navItems.push({
            id: "help",
            label: "Help",
            to: helpCenterLink,
            target: "_blank",
            isMainItem: true,
          })
        } else {
          const idx = addRouteIfExists(`Help`, poolDetail ? `Pool/help` : "Game/help")
          const item = idx && navItems[idx - 1]
          if (item) {
            item.target = "_blank"
          }
        }
        if (productSeason.rulesUrl) {
          navItems.push({
            id: "rules",
            label: "Rules",
            to: rulesDropdownItems.length ? "" : productSeason.rulesUrl,
            target: "_blank",
            isMainItem: true,
            items: rulesDropdownItems,
          })
        }
      }
    }
    return navItems

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    poolDetail,
    segmentForArea,
    productSeason,
    challengePoolForAreaSlug,
    isBracket,
    match.params,
    areaHasMultipleEntriesPerUser,
    isMarquee,
    routes,
    currentRoute.route?.name,
    currentRoute.match.url,
    hasManagerRole,
    isPoolMember,
    needsCustomGameTab,
    allCurrentEntries,
    gameInstancesForArea,
    pathname,
  ])
  return menuData
}
export default useNavMenu
