import { isMarchMadnessMatcher } from "./common-utils-helpers"
import constants from "./constants"
import { TSeasonType } from "./db-typings"
import { SeasonEnumType } from "./enums"
import buildMappingFor from "./game-text"
import { pseudoEncrypt, upperFirst } from "./string-utils"
import { setParam } from "./url-utils"
export const invitationIdSearchParamKey = "invitation-id"
export const invitedBySearchParamKey = "invited-by"
export const invitedViaSearchParamKey = "via-medium"
export const invitedJoinKeySearchParamKey = "join-key"
export const encryptedPoolPasswordParamKey = "pool-join-key"

export const senderRoleSearchParamKey = "sender-role"
export type EmailSenderType = "manager" | "participant"
export const getPoolUrl = (poolOrSubPool: any) => poolOrSubPool.url
export const getPoolInvitationUrl = (
  poolOrSubPool: any,
  entryId: string,
  medium: string,
  ttag?: string,
  password?: string,
  invitationId?: string,
  senderRole?: EmailSenderType,
) => {
  const baseUrl = getPoolUrl(poolOrSubPool)
  const search = getPoolInvitationUrlSearch(entryId, medium, poolOrSubPool.joinKey, ttag, password, invitationId, senderRole)
  const hasSearch = `${baseUrl}`.indexOf("?") > 0
  return `${baseUrl}${hasSearch ? "&" : "?"}${search}`
}
export const getPoolInvitationUrlSearch = (
  entryId: string,
  medium: string,
  joinKey?: string,
  ttag?: string,
  password?: string,
  invitationId?: string,
  senderRole?: EmailSenderType,
) => {
  let search = setParam("", invitedBySearchParamKey, entryId)
  search = setParam(search, invitedViaSearchParamKey, medium)
  if (joinKey) {
    search = setParam(search, invitedJoinKeySearchParamKey, joinKey)
  }
  if (ttag) {
    const parts = ttag.split("ttag=")
    search = setParam(search, "ttag", parts[parts.length - 1])
  }
  if (password) {
    search = setParam(search, encryptedPoolPasswordParamKey, pseudoEncrypt(password))
  }
  if (invitationId) {
    search = setParam(search, invitationIdSearchParamKey, invitationId)
  }
  if (senderRole) {
    search = setParam(search, senderRoleSearchParamKey, pseudoEncrypt(senderRole))
  }
  return search
}

export const getPoolEmailInvitationValues = (
  gameInstanceUid: string,
  poolUrl: string,
  entryId: string,
  poolName: string,
  isChallengePool: boolean,
  password?: string,
  defaultSubject?: string,
  message?: string,
  userFirstName?: string,
  subPoolId?: string,
  joinKey?: string,
  invitationId?: string,
  seasonType = SeasonEnumType.regular as TSeasonType | SeasonEnumType,
  year?: number,
  productAbbrev?: string,
  segment?: string,
  usesMagicLink?: boolean,
  senderRole?: EmailSenderType,
) => {
  const mapping = buildMappingFor(gameInstanceUid, seasonType.toLowerCase(), year, productAbbrev, segment)
  const { title: gameTitle, tagSuffix, ttag, titleWithClient } = mapping
  const urlParts = poolUrl.split("?")
  let url = urlParts.join("?") + (urlParts.length > 1 ? "&" : "?")
  url = url + getPoolInvitationUrlSearch(entryId, "email", joinKey, url.includes("ttag") ? undefined : ttag, password, invitationId, senderRole)
  const rawBody = [] as string[]
  const isNflPickem = /nfl-pickem/.test(gameInstanceUid)
  const isNcaabConfBracket = /ncaab-confbracket/.test(gameInstanceUid)
  const isNcaabTournamentBracket = isMarchMadnessMatcher.test(gameInstanceUid)
  const isNcaaFPickem = /ncaaf-pickem/.test(gameInstanceUid)
  const isNflParlay = /nfl-parlay/.test(gameInstanceUid)
  const objectName = subPoolId ? " group" : isChallengePool ? "" : " pool"
  const capitalizedObjectName = upperFirst(objectName)
  if (isNcaabConfBracket) {
    if (isChallengePool) {
      rawBody.push(
        `Conference tournaments are here! Join me to compete for $10,000 and play against the CBS Sports community to see who has the best college basketball knowledge.`,
      )
    } else {
      rawBody.push(
        `Conference tournaments are here and I've started a conference tournament pool, '${poolName}', on CBSSports.com. Join me now by clicking the link below before the games begin:`,
      )
    }
    // } else if (isNflPickem) {
    //   if (isChallengePool) {
    //     rawBody.push(`I joined a playoff picks pool with Will Brinson from the CBS Sports Pick Six podcast and want you to join the action!`);
    //     rawBody.push(`Sign up now to see if you can beat my score.`);
    //   } else {
    //     rawBody.push(`I started a playoff picks${objectName} called '${poolName}' on CBSSports.com and want you to join the action!`);
    //   }
  } else {
    const prizeText =
      ((isNcaaFPickem || isNflPickem) &&
        seasonType === SeasonEnumType.post &&
        `Not only will we compete against each other, but you'll also have the chance to win $5,000!`) ||
      ((isNcaaFPickem || isNflPickem) &&
        `Not only will we compete against each other, but you'll also have the chance to win the $100,000 jackpot and more cash prizes every week!`) ||
      (isNflParlay &&
        `Not only will we compete against each other, but you'll also be playing to win cash, including the $20,000 season prize and weekly paydays!`) ||
      (!constants.IS_PRODISH && `[TODO: prize text for challenge]`) ||
      ``

    if (isChallengePool) {
      rawBody.push(`I'm playing ${gameTitle} on CBS Sports and want you to join the action. ${prizeText}`)
    } else if (!isNcaabTournamentBracket) {
      rawBody.push(`I started a ${gameTitle}${objectName} called '${poolName}' on CBSSports.com and want you to join the action!`)
    }
  }
  if (message) {
    rawBody.push(message)
  }
  if (password && !isChallengePool && !usesMagicLink) {
    rawBody.push(`Here's the pool password: ${password}`)
  } else {
    rawBody.push(`You can join now using the link below:`)
  }
  const body = rawBody.join("  <br /><br />").replace(/\n/g, "<br />")
  rawBody.push(`Join here: ${url}`)
  const text = rawBody.join("  \n\n")
  const subject =
    defaultSubject ||
    (userFirstName &&
      // isNflPickem && (isChallengePool ? `Join the Pick Six Challenge${capitalizedObjectName}` : `Join my CBS Sports Playoff Pick'em${capitalizedObjectName}`) ||
      // isNcaabConfBracket && isChallengePool && `${userFirstName} Invited You to play ${gameTitle}` ||
      // isNcaabConfBracket && `${userFirstName} Invited You to a Conference Tournament Pool!`) ||
      `${userFirstName} has invited you to play ${gameTitle}`) ||
    `Join the ${gameTitle}${capitalizedObjectName}`
  const title =
    (userFirstName && `${userFirstName} has invited you to play ${gameTitle}`) ||
    // isNcaabConfBracket && (isChallengePool ? `Join the Conference Bracket Challenge` : `Join the Madness`) ||
    `${titleWithClient}${capitalizedObjectName}`
  const cta = `Join Now`
  return {
    subject,
    url,
    title,
    cta,
    body,
    text,
    tagSuffix,
    gameTextMapping: mapping,
  }
}
