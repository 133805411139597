// based on - http://svn.cbssports.com/svnweb/media/view/trunk/appslib/apps/Ads.pm

export type TSlotId =
  | "leader_plus2_bottom"
  | "leader_top"
  | "mpu_top"
  | "skybox_top"
  | "skybox_leader_sticky"
  | "mobile_banner_top"
  | "bracket_bottom"
  | "bracket_logo"
  | "gambling_partner_modal"
  | "gambling_partner"

type TAdPosition = "top" | "sticky" | "middle" | "bottom" | "dynamic" | "modal"

export interface IAdType {
  name: TSlotId
  sizes: string
  position: TAdPosition
}

type TAdSizes = { [slotId in TSlotId]: IAdType }

export const AdTypes: TAdSizes = {
  leader_plus2_bottom: {
    name: "leader_plus2_bottom",
    sizes: "[[728,90],[970,66]]",
    position: "bottom",
  },
  leader_top: {
    name: "leader_top",
    sizes: "[[728,90],[970,66],[970,90]]",
    position: "top",
  },
  mpu_top: {
    name: "mpu_top",
    sizes: "[300,250]",
    position: "top",
  },
  skybox_top: {
    name: "skybox_top",
    sizes: "[5,5]",
    position: "top",
  },
  skybox_leader_sticky: {
    name: "skybox_leader_sticky",
    // fantasy is only [5x5], picks has sizes for leader_top too since there is no leader_top ad on picks ([970,90] is getting retired)
    sizes: "[[5,5],[728,90],[970,66]]",
    position: "sticky",
  },
  mobile_banner_top: {
    name: "mobile_banner_top",
    sizes: "[320,50]",
    position: "top",
  },
  bracket_bottom: {
    name: "bracket_bottom",
    sizes: "[[728,90],[970,66]]",
    position: "bottom",
  },
  bracket_logo: {
    name: "bracket_logo",
    sizes: "[196,12]",
    position: "top",
  },
  gambling_partner: {
    name: "gambling_partner",
    sizes: '[[8,8],["fluid"]]',
    position: "dynamic",
  },
  gambling_partner_modal: {
    name: "gambling_partner_modal",
    sizes: '[[8,8],["fluid"]]',
    position: "modal",
  },
}
