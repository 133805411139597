import React, { useState } from "react"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { EmailSvg } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"
import EmailModal from "../../MultipleEntriesPoolInviteMailCenter"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import { TPoolRouteProps } from "../../../../../../routes.d"
const DEFAULT_SUBJECT = "Message From Your Bracket Pool Manager"
const DEFAULT_MESSAGE =
  "I noticed you haven't made picks yet or have an incomplete bracket. There’s still time to make your picks before the tournament starts."
const TOOLTIP_DESCRIPTION = "Send a reminder to all participants who have an incomplete bracket."

const PicksReminderModal = ({ onEmailSent, ...rest }) => {
  return (
    <EmailModal
      {...rest}
      defaultSubject={DEFAULT_SUBJECT}
      defaultMessage={DEFAULT_MESSAGE}
      onNotificationSent={onEmailSent}
      variant={"picks-reminder-notification"}
    />
  )
}

const PicksReminderButton = (props: TPoolRouteProps) => {
  const [showEmailModal, setShowEmailModal] = useState(false)
  const showModal = () => setShowEmailModal(true)
  const hideModal = () => setShowEmailModal(false)
  const onEmailSent = () => toast.snackbar("Reminder sent")

  return (
    <>
      <ClassicTooltip content={TOOLTIP_DESCRIPTION}>
        <Button variant="secondary" onClick={showModal} className="email-pool-cta">
          <ListItemIcon alignment="left">
            <EmailSvg />
          </ListItemIcon>
          Send Pick Reminder
        </Button>
      </ClassicTooltip>
      <PicksReminderModal {...props} isOpen={showEmailModal} onClose={hideModal} onEmailSent={onEmailSent} />
    </>
  )
}

export default PicksReminderButton
