import React, { useContext } from "react"
import { FlexRow } from "../../../components/FlexComponents"
import CarrotSvg from "../../../components/icons/Carrot"
import { ManagerModeTabContainer } from "../styles/Layout.styles"
import Link from "../../../components/Link"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import { canUseDom } from "../../../utils/misc-utils"

interface ManagerModeTabProps {
  poolId: string
  isOnStandings: boolean
  poolRootPathname?: string
}
export const ManagerModeTab = ({ poolRootPathname, isOnStandings, poolId }: ManagerModeTabProps) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { toggleManagerMode, isManagerModeActive } = poolDataContext || {}

  return (
    <ManagerModeTabContainer className={`on-leaderboard--${isOnStandings}`}>
      <FlexRow>
        <div>In Manager Mode</div>
        <button
          type="button"
          onClick={() => {
            if (typeof toggleManagerMode === "function") {
              toggleManagerMode(!isManagerModeActive, () => {
                if (canUseDom) {
                  window.location.reload()
                }
              })
            }
          }}
        >
          Exit
        </button>
      </FlexRow>
      <Link to={`${poolRootPathname}/standings`}>
        <span>Select a member to edit</span>
        <CarrotSvg />
      </Link>
      <p>Edit members' picks by clicking on the pencil. All picks will be editable.</p>
    </ManagerModeTabContainer>
  )
}

export default ManagerModeTab
