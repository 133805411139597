import React, { useContext } from "react"
import styled from "styled-components/macro"
import { palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import { teamImgUrl } from "../../../utils/data-utils"
import BracketPlaceHolder from "../../../components/icons/BracketPlaceHolder"
import { buildPickUtils } from "../../../hooks/usePoolData"
import { getChampionLogo } from "../containers/MultipleEntriesArea/PoolHome/BracketPoolHome"
import { GameSportTypeEnumType } from "../../../../__generated__/globalTypes"

type TSize = "xs" | "sm" | "rg" | "md" | "lg"
const LogoSize = {
  xs: 8,
  sm: 16,
  rg: 24,
  md: 32,
  lg: 64,
}

const ChampionLogo = styled.span<{ size: TSize }>`
  display: inline-block;
  box-sizing: border-box;
  height: ${({ size }) => pxToRem(LogoSize[size])};
  width: ${({ size }) => pxToRem(LogoSize[size])};
`
const ChampionLogoImage = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
  opacity: 1;
`
const Avatar = styled.div<{ size?: number }>`
  box-sizing: border-box;
  border-radius: 100%;
  flex: 0 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }) => pxToRem(size || 60)};
  min-height: ${({ size }) => pxToRem(size || 60)};
  max-width: ${({ size }) => pxToRem(size || 60)};
  max-height: ${({ size }) => pxToRem(size || 60)};
  background-color: ${palette.gray95};
  overflow: hidden;

  & > svg {
    color: ${palette.gray75};
    width: 100%;
    height: 100%;
  }
`

export interface ChampionTeamType {
  id: string
  sportType: GameSportTypeEnumType
  abbrev: string
}

export interface EntryType {
  name: string
  avatarUrl: string
  championTeam: ChampionTeamType | null
}

export interface EntryLogoProps extends React.HTMLAttributes<HTMLElement> {
  entry: EntryType
  size?: TSize
  showChampionLogo?: boolean
}

const EntryLogo = React.memo(({ entry, size = "md", showChampionLogo, ...rest }: EntryLogoProps) => {
  const poolData: PoolDataContextType = useContext(PoolDataContext)

  const { name, championTeam, avatarUrl } = entry || emptyObject

  // const url = showChampionLogo ? (champ ? teamImgUrl(champ) : undefined) : avatarUrl
  let url: string | undefined
  if (showChampionLogo) {
    if (championTeam) {
      if (poolData) {
        const pickUtils = buildPickUtils(poolData, null, [])
        url = getChampionLogo(pickUtils, championTeam)
      } else {
        url = teamImgUrl(championTeam as any)
      }
    }
  } else {
    url = avatarUrl
  }

  return (
    (url && (
      <ChampionLogo {...rest} size={size}>
        {<ChampionLogoImage src={url} alt={name}></ChampionLogoImage>}
      </ChampionLogo>
    )) || (
      <Avatar {...rest} size={LogoSize[size]}>
        <BracketPlaceHolder />
      </Avatar>
    )
  )
})

export default EntryLogo
