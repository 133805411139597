import * as React from "react"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import styled from "styled-components/macro"
import { useLocalKey } from "../../../Base/LocalKey"
import { ButtonSmallBase } from "../../../components/Button"
import Link from "../../../components/Link"
import { palette, pxToRem } from "../../../utils/style-utils"
import ReactivatePoolModal, { IReactivateProps } from "./ReactivatePoolModal"
import useUpsertEntryMutation from "../../../hooks/useUpsertEntryMutation"
import buildMappingFor from "../../../../common/game-text"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import { isMarchMadnessMatcher } from "../../../../common/common-utils-helpers"
import Status from "../../../Base/Status"
import { Redirect } from "react-router-dom"
import { withoutDomain } from "../../../utils/url-utils"

const StyledPoolPageArchivedBanner = styled.div`
  padding: ${pxToRem(8)} ${pxToRem(16)};
  display: flex;
  flex-flow: row nowrap;
  background-color: ${palette.bannerTopInfoTeal};
  color: ${palette.white};
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.2em;
  user-select: auto;
  & button,
  a {
    font-size: 0.8em;
    white-space: nowrap;
  }
  & > *:not(:first-child) {
    margin-left: ${pxToRem(16)};
  }
  &.is-callout--true {
    background-color: ${palette.orange};
  }

  & > span.content-message {
    min-height: ${pxToRem(32)};
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
`

const modalKey = "PoolPageArchivedBanner"

interface IProps extends IReactivateProps {
  hasReactivatedEntry: boolean
  periodId: string | null
  entryId: string | null
  lastDismissedReactivateSeasonId: string | null
}

export const PoolPageNotificationBanner: React.FC<{ html: string; backgroundColor?: string | null; color?: string | null }> = ({
  html,
  backgroundColor,
  color,
}) => {
  const styles = ((backgroundColor || color) && ({ backgroundColor, color } as React.CSSProperties)) || undefined
  return (
    <StyledPoolPageArchivedBanner style={styles}>
      <span className="content-message" dangerouslySetInnerHTML={{ __html: html }} />
    </StyledPoolPageArchivedBanner>
  )
}

interface IButtonSmallBaseWrapper {
  ctaOnClick: (() => Promise<any> | Promise<void>) | undefined
  ctaTo: string | undefined
  cta: "Start Reactivation" | "Continue Playing" | "Current Season" | "Join Now" | "Get Started" | ""
}

const ButtonSmallBaseWrapper: React.FC<IButtonSmallBaseWrapper> = ({ cta, ctaOnClick, ctaTo }) => {
  if (ctaTo) {
    return (
      <ButtonSmallBase as={Link} to={ctaTo ? ctaTo : ""}>
        {cta}
      </ButtonSmallBase>
    )
  } else {
    return (
      <ButtonSmallBase as="button" type="button" onClick={ctaOnClick}>
        {cta}
      </ButtonSmallBase>
    )
  }
}

const PoolPageArchivedBanner: React.FC<IProps> = (props) => {
  // console.debug(`PoolPageArchivedBanner`);
  // console.dir(props);
  const poolDataContext: PoolDataContextType = React.useContext(PoolDataContext)

  const { detailedEntry, deviceType } = poolDataContext || emptyObject
  const { allSegments, poolDetail, isReinvited, hasReactivatedEntry, hasManagerRole, entryId, periodId, lastDismissedReactivateSeasonId } = props
  if (!allSegments) {
    return null
  }
  const formerSegment = allSegments.find((ses) => poolDetail.season.id === ses.season.id)
  if (!formerSegment) {
    return null
  }
  const formerSeason = formerSegment.season
  const currentSegment = allSegments.find((ses) => ses.season.isCurrent && formerSeason.gameInstance.uid === ses.season.gameInstance.uid)
  if (!currentSegment) {
    return null
  }
  const isReactivated = !!poolDetail.currentSeasonPoolId

  const currentSeason = currentSegment.season
  const currentSeasonId = currentSeason && currentSeason.id
  const newSeasonIsSameProduct = currentSeason.season === formerSeason.season && currentSeason.productAbbrev === formerSeason.productAbbrev
  const needsToReactivate = !isReactivated && !newSeasonIsSameProduct
  const needsToRollover = !isReactivated && newSeasonIsSameProduct
  const canReactivateOrRollover = needsToReactivate || needsToRollover
  const isManagerAndNeedsToRollover = needsToRollover && hasManagerRole
  const isAutoPromptScenario = !!currentSeasonId && canReactivateOrRollover
  // const poolSegment = allSegments.find((ses) => ses.season.id === poolDetail.season.id);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const modalProps = useLocalKey("modal")
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hasDismissedPrompt, updateState] = React.useState(false)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasFiredRef = React.useRef<boolean>()
  // const cookieProps = useLocalKey(`_lastDismissedRolloverSeasonIdFor${entryId}`)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { upsertEntryMutation, upsertEntryMutationResult } = useUpsertEntryMutation(entryId, currentSeason.season, currentSeason.year)
  const markAsDismissed = () => {
    const needsToMarkOnDismiss =
      !hasDismissedPrompt && isAutoPromptScenario && !lastDismissedReactivateSeasonId && !upsertEntryMutationResult.loading && !hasFiredRef.current
    // console.log(`needsToMarkOnDismiss ${needsToMarkOnDismiss}: ${hasDismissedPrompt} ${isAutoPromptScenario} && ${lastDismissedReactivateSeasonId} && ${upsertEntryMutationResult.loading} && ${hasFiredRef.current} (${isReactivated})`)
    // console.dir(props);
    if (needsToMarkOnDismiss) {
      hasFiredRef.current = true
      const variables = {
        entryId,
        lastDismissedReactivateSeasonId: currentSeasonId,
        periodId,
      }
      updateState(true)
      setTimeout(() => {
        // NOTE qac: dont need to wait for mutation to finish to close the modal...
        upsertEntryMutation({ variables })
      }, 0)
    }
    return Promise.resolve()
  }
  const onCloseBtnAction = () => {
    markAsDismissed()
    modalProps.update(null)
  }
  // const [isSubmitting, setIsSubmitting] = React.useState(false)
  const poolGameText = buildMappingFor(formerSeason.gameInstance.uid, formerSeason.season, formerSeason.year, formerSeason.productAbbrev)
  const txt =
    (canReactivateOrRollover && hasManagerRole && deviceType === "handheld" && `Reactivate your pool for the ${currentSeason.year} season.`) ||
    (canReactivateOrRollover && hasManagerRole && `Reactivate your pool to invite friends and get ready for the ${currentSeason.year} season.`) ||
    (!isReactivated && `You're viewing the ${formerSeason.year}'s results. Contact your manager to reactivate for the new season.`) ||
    (hasReactivatedEntry && `Viewing ${poolGameText.seasonText} season`) ||
    (isReinvited && `Get back in the action this season by clicking here.`) ||
    (hasManagerRole && `Join the new seasons pool and reinvite your members!`) ||
    // is reactivated and not invited again
    `Viewing ${formerSeason.year} Results`
  const cta =
    (canReactivateOrRollover && hasManagerRole && `Start Reactivation`) ||
    (!isReactivated
      ? ""
      : (hasReactivatedEntry && `Current Season`) ||
        (isReinvited && `Join Now`) ||
        (hasManagerRole && `Get Started`) ||
        // is reactivated and not invited again
        "")
  const ctaTo =
    (isReactivated && (hasReactivatedEntry || hasManagerRole) && poolDetail.url.replace(poolDetail.id, poolDetail.currentSeasonPoolId!)) || undefined
  const ctaOnClick = ctaTo ? undefined : () => modalProps.update(modalKey)

  const hasSeenCookieSet = !!currentSeasonId && (currentSeasonId === lastDismissedReactivateSeasonId || hasDismissedPrompt)
  const openModal = modalProps.value === modalKey || (isAutoPromptScenario && modalProps.value === null && !hasSeenCookieSet)
  const showModal = !!detailedEntry && openModal

  if (isMarchMadnessMatcher.test(poolDetail?.gameInstanceUid ?? "")) {
    if (poolDetail?.currentSeasonPoolUrl) {
      return (
        <Status code={302}>
          <Redirect to={withoutDomain(poolDetail.currentSeasonPoolUrl)} />
        </Status>
      )
    }
  }

  return (
    <StyledPoolPageArchivedBanner className={`is-callout--${isManagerAndNeedsToRollover}`}>
      <span>{txt}</span>
      {cta && <ButtonSmallBaseWrapper ctaTo={ctaTo} ctaOnClick={ctaOnClick} cta={cta} />}
      <ReactivatePoolModal {...props} isOpen={showModal} onClose={onCloseBtnAction} />
    </StyledPoolPageArchivedBanner>
  )
}
export default PoolPageArchivedBanner
