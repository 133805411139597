import * as React from "react"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonBase } from "../../../components/Button"
import CustomCopy from "../../../components/CustomCopy"
import Link from "../../../components/Link"
import { Logo } from "../../../components/Logos"
import { IComingSoonProps } from "../../PoolSetupPages/PoolSetupTypes.d"
import { ComingSoonCol, ComingSoonCols, ComingSoonH, ComingSoonH3, ComingSoonWrapper } from "../styles/SplashViews.styles"
import { AppLinks, AppLogo } from "./NativeAppPromos"
import { entryHasManagerRole } from "../../../hooks/usePoolData"

const btnStyles = {
  maxWidth: "270px",
}

export const ComingSoonSplash = ({ gameInstanceUid, isChallengePool, poolRoot, isCbsAppWebview, season, detailedEntry }: IComingSoonProps) => {
  const isManager = !!detailedEntry && entryHasManagerRole(detailedEntry)
  return (
    <ComingSoonWrapper>
      <AnalyticScreen feature={`splash opm`} subfeature={`coming soon`} title={`Splash Page - Coming Soon`} />
      <ComingSoonH>
        <CustomCopy code="comingSoonText" />
      </ComingSoonH>
      <ComingSoonH3>
        <CustomCopy code="comingSoonSubtext" />
      </ComingSoonH3>
      <ComingSoonCols>
        <ComingSoonCol className={`gameSection is-manager--${isManager}`}>
          <div className="logo">
            <Logo season={season} gameInstanceUid={gameInstanceUid} />
          </div>
          <section>
            <p>{(isManager && `Raise the stakes and invite your friends now!`) || `Games will be available soon!`}</p>
            {isManager && (
              <ButtonBase as={Link} to={`${poolRoot}/invite-center`} style={btnStyles}>
                Invite Your Friends
              </ButtonBase>
            )}
          </section>
        </ComingSoonCol>
        {!isCbsAppWebview && (
          <>
            <hr />
            <ComingSoonCol>
              <div className="logo">
                <AppLogo className="promo-img" />
              </div>

              <section>
                <p>For access on the go, download the CBS Sports App.</p>
                <AppLinks gameInstanceUid={gameInstanceUid} seasonType={season.season} year={season.year} />
              </section>
            </ComingSoonCol>
          </>
        )}
      </ComingSoonCols>
    </ComingSoonWrapper>
  )
}

export default ComingSoonSplash
